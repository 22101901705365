import React, { Fragment } from 'react';
import text from '../language/text';
import { useParams } from 'react-router';
import { connect } from 'react-redux';

function LocaleSet() {
  let { locale } = useParams<any>();
  text.setLanguage(locale);

  return <Fragment></Fragment>;
}

export default connect(() => {
  return {};
})(LocaleSet);
