const OPEN_MODAL = 'startSubscriptionModal/ducks/OPEN_MODAL';
const CLOSE_MODAL = 'startSubscriptionModal/ducks/CLOSE_MODAL';
const REQUEST_START_SUBSCRIPTION =
  'startSubscriptionModal/ducks/REQUEST_START_SUBSCRIPTION';
const RECEIVE_START_SUBSCRIPTION =
  'startSubscriptionModal/ducks/RECEIVE_START_SUBSCRIPTION';
const START_SUBSCRIPTION_ERROR =
  'startSubscriptionModal/ducks/START_SUBSCRIPTION_ERROR';
const RECEIVE_PAID = 'startSubscriptionModal/ducks/RECEIVE_PAID';
const RECEIVE_CANCELED = 'startSubscriptionModal/ducks/RECEIVE_CANCELED';

export {
  OPEN_MODAL,
  CLOSE_MODAL,
  REQUEST_START_SUBSCRIPTION,
  RECEIVE_START_SUBSCRIPTION,
  START_SUBSCRIPTION_ERROR,
  RECEIVE_PAID,
  RECEIVE_CANCELED
};
