import React, { Fragment } from 'react';

import clsx from 'clsx';

import { Paper } from '@material-ui/core';

import { connect } from 'react-redux';

const Footer = ({ footerFixed }) => {
  return (
    <Fragment>
      <Paper
        square
        className={clsx('app-footer text-black-50', {
          'app-footer--fixed': footerFixed
        })}>
        <div className="app-footer--inner">
          <div className="app-footer--first"></div>
          <div className="app-footer--second"></div>
        </div>
      </Paper>
    </Fragment>
  );
};

const mapStateToProps = ({ themeOptions }) => ({
  footerFixed: themeOptions.footerFixed
});
export default connect(mapStateToProps)(Footer);
