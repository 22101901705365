import {
  REQUEST_DELETE_ACCOUNT,
  RECEIVE_DELETE_ACCOUNT,
  DELETE_ACCOUNT_ERROR
} from './DeleteAccountActions';

function DeleteAccount(
  state = {
    isFetching: false,
    error: null,
    success: false
  },
  action: any
) {
  switch (action.type) {
    case REQUEST_DELETE_ACCOUNT:
      return {
        ...state,
        isFetching: true,
        error: null,
        success: false
      };
    case RECEIVE_DELETE_ACCOUNT:
      return {
        ...state,
        isFetching: false,
        error: null,
        success: true
      };
    case DELETE_ACCOUNT_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        success: false
      };

    default:
      return { ...state };
  }
}

export default DeleteAccount;
