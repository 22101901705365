import React from 'react';
import { Box, Typography } from '@material-ui/core';
import styles from '../../assets/cssModules/changePages.module.scss';
import SubmitButton from './buttons/SubmitButton';
import { makeStyles } from '@material-ui/core/styles';

interface IProps {
  handleSubmit: (something: any) => void;
  title: string;
  subTitle: string;
  errorMessage: string;
  successMessage: string;
  dynamicForm: any;
  submitText: string;
}

const useStyles = makeStyles(() => ({
  title: {
    fontSize: '20px',
    fontWeight: 500,
    color: '#242d3c'
  }
}));

const FormLayout = (props: IProps) => {
  const {
    handleSubmit,
    title,
    subTitle,
    errorMessage,
    successMessage,
    submitText
  } = props;

  const classes = useStyles();

  return (
    <Box padding={4}>
      <form onSubmit={handleSubmit}>
        <Box textAlign={'center'}>
          <Typography className={classes.title}>{title}</Typography>
        </Box>

        <Typography className={styles.Text}>{subTitle}</Typography>
        <Box display={'flex'} justifyContent={'center'}>
          <Typography color={'error'}>{errorMessage}</Typography>
          <Typography color={'secondary'}>{successMessage}</Typography>
        </Box>
        <Box display={'flex'} justifyContent={'center'} paddingTop={2}>
          {props.dynamicForm}
        </Box>
        <Box display={'flex'} justifyContent={'center'} paddingTop={2}>
          <SubmitButton
            fullWidth={false}
            text={submitText}
            onClick={handleSubmit}
          />
        </Box>
      </form>
    </Box>
  );
};

export default FormLayout;
