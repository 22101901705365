import {
  RECEIVE_USER_DETAILS,
  REQUEST_USER_DETAILS,
  USER_DETAILS_ERROR
} from './types';
import { combineReducers } from 'redux';

const isFetchingReducer = (state: any = false, action: any) => {
  switch (action.type) {
    case REQUEST_USER_DETAILS:
      return true;
    case RECEIVE_USER_DETAILS:
    case USER_DETAILS_ERROR:
      return false;
    default:
      return state;
  }
};

const userReducer = (state: any = null, action: any) => {
  switch (action.type) {
    case RECEIVE_USER_DETAILS:
      return action.user;
    default:
      return state;
  }
};

const errorReducer = (state: any = null, action: any) => {
  switch (action.type) {
    case USER_DETAILS_ERROR:
      return action.error;
    case REQUEST_USER_DETAILS:
      return null;
    default:
      return state;
  }
};

const reducer = combineReducers({
  isFetching: isFetchingReducer,
  user: userReducer,
  error: errorReducer
});

export default reducer;
