import {
  ADD_CREDIT_ERROR,
  CLOSE_MODAL,
  OPEN_MODAL,
  RECEIVE_ADD_CREDIT,
  RECEIVE_CANCELED,
  RECEIVE_PAID,
  REQUEST_ADD_CREDIT
} from './types';

const openModal = () => ({
  type: OPEN_MODAL
});

const closeModal = () => ({
  type: CLOSE_MODAL
});

const requestAddCredit = () => ({
  type: REQUEST_ADD_CREDIT
});

const receiveAddCredit = () => ({
  type: RECEIVE_ADD_CREDIT
});

const addCreditError = (error: string) => ({
  type: ADD_CREDIT_ERROR,
  error
});

const receivePaid = () => ({
  type: RECEIVE_PAID
});

const receiveCanceled = () => ({
  type: RECEIVE_CANCELED
});

export {
  openModal,
  closeModal,
  requestAddCredit,
  receiveAddCredit,
  addCreditError,
  receivePaid,
  receiveCanceled
};
