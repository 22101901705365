import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import text from '../../../language/text';
import { Button, Typography } from '@material-ui/core';
import Loader from '../../utils/Loader';
import ModalDialog from '../../utils/ModalDialog';
import ProgressModal from '../basicModals/ProgressModal';
import SuccessModal from '../basicModals/SuccessModal';
import { closeModal, openModal } from './ducks/actions';
import { cancelSubscription } from './ducks/operations';
import ConfirmButton from '../../utils/buttons/ConfirmButton';

interface IProps {
  dispatch: any;
  isFetching: boolean;
  errorMessage: string;
  success: boolean;
  isChecking: boolean;
  open: boolean;
}

function CancelSubscriptionModal({
  dispatch,
  open,
  isFetching,
  isChecking,
  success,
  errorMessage
}: IProps) {
  const strings = text.modals.cancelSubscriptionModal;

  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleOpen = () => {
    dispatch(openModal());
  };

  const handleSubmit = () => {
    dispatch(cancelSubscription());
  };

  return (
    <Fragment>
      <ConfirmButton
        buttonStyle={'addCreditBtn'}
        onClick={handleOpen}
        text={text.forms.changeSubscriptionForm.cancelSubscription}
      />
      {isChecking ? (
        <ProgressModal title={text.modals.cancelSubscriptionModal.title} />
      ) : success ? (
        <SuccessModal
          title={text.modals.cancelSubscriptionModal.title}
          success={text.modals.cancelSubscriptionModal.success}
          handleClose={handleClose}
        />
      ) : (
        <ModalDialog
          open={open}
          onClose={handleClose}
          title={text.modals.cancelSubscriptionModal.title}
          actions={[
            <Button onClick={handleClose} color="secondary" key={1}>
              {text.modals.cancelSubscriptionModal.cancel}
            </Button>,
            <Button
              onClick={handleSubmit}
              color="primary"
              variant="contained"
              key={2}>
              {text.modals.cancelSubscriptionModal.cancelSubscription}
            </Button>
          ]}>
          <Typography color="error">{errorMessage}</Typography>
          <Loader open={isFetching} inElement />
          <Typography className="mb-3">
            {text.modals.cancelSubscriptionModal.modalText}
          </Typography>
          <div className="mb-3"></div>
        </ModalDialog>
      )}
    </Fragment>
  );
}

function mapStateToProps(state: any) {
  const { cancelSubscriptionModal } = state.components.modals;
  const { isFetching, error, success, open, isChecking } =
    cancelSubscriptionModal || {
      isFetching: false,
      error: null,
      success: false,
      open: false,
      isChecking: false
    };
  return {
    isFetching,
    errorMessage: error,
    success,
    isChecking,
    open
  };
}

export default connect(mapStateToProps)(CancelSubscriptionModal);
