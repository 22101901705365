const REQUEST_SAVE_SEPA_DEFAULTS =
  'changeAccountForm/ducks/REQUEST_SAVE_SEPA_DEFAULTS';
const RECEIVE_SAVE_SEPA_DEFAULTS =
  'changeAccountForm/ducks/RECEIVE_SAVE_SEPA_DEFAULTS';
const SAVE_SEPA_DEFAULTS_ERROR =
  'changeAccountForm/ducks/SAVE_SEPA_DEFAULTS_ERROR';

export {
  REQUEST_SAVE_SEPA_DEFAULTS,
  RECEIVE_SAVE_SEPA_DEFAULTS,
  SAVE_SEPA_DEFAULTS_ERROR
};
