import {
  RECEIVE_USER_DETAILS,
  REQUEST_USER_DETAILS,
  USER_DETAILS_ERROR
} from './types';

const requestUserDetails = () => ({
  type: REQUEST_USER_DETAILS
});

const receiveUserDetails = (user: any) => ({
  type: RECEIVE_USER_DETAILS,
  user
});

const userDetailsError = (error: string) => ({
  type: USER_DETAILS_ERROR,
  error
});

export { requestUserDetails, receiveUserDetails, userDetailsError };
