import { combineReducers } from 'redux';
import {
  RECEIVE_SAVE_SEPA_DEFAULTS,
  REQUEST_SAVE_SEPA_DEFAULTS,
  SAVE_SEPA_DEFAULTS_ERROR
} from './types';

const isFetchingReducer = (state: any = false, action: any) => {
  switch (action.type) {
    case REQUEST_SAVE_SEPA_DEFAULTS:
      return true;
    case RECEIVE_SAVE_SEPA_DEFAULTS:
      return false;
    default:
      return state;
  }
};

const errorReducer = (state: any = null, action: any) => {
  switch (action.type) {
    case SAVE_SEPA_DEFAULTS_ERROR:
      return action.error;
    case REQUEST_SAVE_SEPA_DEFAULTS:
      return null;
    default:
      return state;
  }
};

const successReducer = (state: any = false, action: any) => {
  switch (action.type) {
    case RECEIVE_SAVE_SEPA_DEFAULTS:
      return true;
    case REQUEST_SAVE_SEPA_DEFAULTS:
    case SAVE_SEPA_DEFAULTS_ERROR:
      return false;
    default:
      return state;
  }
};

const reducer = combineReducers({
  isFetching: isFetchingReducer,
  error: errorReducer,
  success: successReducer
});

export default reducer;
