import {
  RECEIVE_USER_PAYMENTS,
  REQUEST_USER_PAYMENTS,
  USER_PAYMENTS_ERROR
} from './types';

const requestUserPayments = () => ({
  type: REQUEST_USER_PAYMENTS
});

const receiveUserPayments = (payments: any) => ({
  type: RECEIVE_USER_PAYMENTS,
  payments
});

const userPaymentsError = (error: string) => ({
  type: USER_PAYMENTS_ERROR,
  error
});

export { requestUserPayments, receiveUserPayments, userPaymentsError };
