import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import Routes from './Routes';
import ScrollToTop from './utils/ScrollToTop';
import './template/assets/base.scss';
import CssBaseline from '@material-ui/core/CssBaseline';
import ReactGA from 'react-ga';
import history from './utils/history';
import { library } from '@fortawesome/fontawesome-svg-core';
import { loadCurrentUser, receiveToken, signOut } from './reducers/authActions';
import {
  fab,
  faDiscord,
  faDribbble,
  faFacebook,
  faGithub,
  faGoogle,
  faHtml5,
  faInstagram,
  faPinterest,
  faReact,
  faSlack,
  faTwitter,
  faVuejs,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';
import {
  faAddressCard,
  faBell,
  faBuilding,
  faCalendarAlt,
  faChartBar,
  faCheckCircle,
  faClock,
  faCommentDots,
  faComments,
  faDotCircle,
  faEnvelope,
  faEye,
  faFileAlt,
  faFileArchive,
  faFileAudio,
  faFileCode,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFileVideo,
  faFileWord,
  faFolder,
  faFolderOpen,
  faGem,
  faImages,
  faKeyboard,
  faLifeRing,
  faLightbulb,
  faMap,
  faObjectGroup,
  faQuestionCircle,
  far,
  faSquare,
  faThumbsUp,
  faTimesCircle,
  faTrashAlt,
  faUser,
  faUserCircle
} from '@fortawesome/free-regular-svg-icons';
import {
  faAlignCenter,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowsAltH,
  faArrowUp,
  faAward,
  faBars,
  faBatteryEmpty,
  faBatteryFull,
  faBirthdayCake,
  faBomb,
  faBusAlt,
  faCameraRetro,
  faCarBattery,
  faCaretRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCog,
  faCubes,
  faDownload,
  faEllipsisH,
  faEllipsisV,
  faExternalLinkAlt,
  faEyeDropper,
  faFilm,
  faHeart,
  faInfoCircle,
  faLemon,
  faLink,
  faMapMarkedAlt,
  faNetworkWired,
  faPager,
  faPlayCircle,
  faPlus,
  faPlusCircle,
  faPrint,
  faQuoteRight,
  faReply,
  fas,
  faSave,
  faSearch,
  faShapes,
  faShareSquare,
  faSignOutAlt,
  faSitemap,
  faSlidersH,
  faSmile,
  faStar,
  faStarHalfAlt,
  faSync,
  faTable,
  faTachometerAlt,
  faTimes,
  faUnlockAlt,
  faUpload
} from '@fortawesome/free-solid-svg-icons';
import configureStore from './reducers/configureStore';
import { PersistGate } from 'redux-persist/integration/react';
import { apiPost } from './utils/api';

library.add(
  far,
  faSquare,
  faLifeRing,
  faCheckCircle,
  faTimesCircle,
  faDotCircle,
  faThumbsUp,
  faComments,
  faFolderOpen,
  faTrashAlt,
  faFileImage,
  faFileArchive,
  faCommentDots,
  faFolder,
  faKeyboard,
  faCalendarAlt,
  faEnvelope,
  faAddressCard,
  faMap,
  faObjectGroup,
  faImages,
  faUser,
  faLightbulb,
  faGem,
  faClock,
  faUserCircle,
  faQuestionCircle,
  faBuilding,
  faBell,
  faFileExcel,
  faFileAudio,
  faFileVideo,
  faFileWord,
  faFilePdf,
  faFileCode,
  faFileAlt,
  faEye,
  faChartBar
);
library.add(
  fab,
  faFacebook,
  faTwitter,
  faVuejs,
  faReact,
  faHtml5,
  faGoogle,
  faInstagram,
  faPinterest,
  faYoutube,
  faDiscord,
  faSlack,
  faDribbble,
  faGithub
);
library.add(
  fas,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faSmile,
  faHeart,
  faBatteryEmpty,
  faBatteryFull,
  faChevronRight,
  faSitemap,
  faPrint,
  faMapMarkedAlt,
  faTachometerAlt,
  faAlignCenter,
  faExternalLinkAlt,
  faShareSquare,
  faInfoCircle,
  faSync,
  faQuoteRight,
  faStarHalfAlt,
  faShapes,
  faCarBattery,
  faTable,
  faCubes,
  faPager,
  faCameraRetro,
  faBomb,
  faNetworkWired,
  faBusAlt,
  faBirthdayCake,
  faEyeDropper,
  faUnlockAlt,
  faDownload,
  faAward,
  faPlayCircle,
  faReply,
  faUpload,
  faBars,
  faEllipsisV,
  faSave,
  faSlidersH,
  faCaretRight,
  faChevronUp,
  faPlus,
  faLemon,
  faChevronLeft,
  faTimes,
  faChevronDown,
  faFilm,
  faSearch,
  faEllipsisH,
  faCog,
  faArrowsAltH,
  faPlusCircle,
  faAngleRight,
  faAngleUp,
  faAngleLeft,
  faAngleDown,
  faArrowUp,
  faArrowDown,
  faArrowRight,
  faArrowLeft,
  faStar,
  faSignOutAlt,
  faLink
);
const { store, persistor } = configureStore({});

const trackingId = 'UA-170126555-2';
ReactGA.initialize(trackingId);

const App = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);

  useEffect(() => {
    const fetchData = async () => {
      const token = await localStorage.getItem('@yoursepa/refresh-token');
      if (token) {
        console.log('FETCH HERE?');
        apiPost(`/users/refresh_token`, {
          refreshToken: token
        })
          .then(async (textResponse: any) => {
            console.log(' A');
            if (!textResponse.ok) {
              store.dispatch(signOut(true));
              return;
            }
            const response = await textResponse.json();

            if (response?.accessToken) {
              store.dispatch(receiveToken(response.accessToken));
              store.dispatch(loadCurrentUser(response.accessToken));
              localStorage.setItem(
                '@yoursepa/refresh-token',
                response.refreshToken
              );
              localStorage.setItem('@yoursepa/token', response.accessToken);
            }
          })
          .catch((e) => {
            store.dispatch(signOut(true));
          });
      } else {
        store.dispatch(signOut(true));
      }
    };
    fetchData();
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router history={history}>
          <CssBaseline />
          <ScrollToTop>
            <Routes />
          </ScrollToTop>
        </Router>
      </PersistGate>
    </Provider>
  );
};

export default App;
export { store };
