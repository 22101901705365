import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Loader from '../../utils/Loader';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography
} from '@material-ui/core';
import { fetchUser } from './ducks/operations';
import UserPayments from './UserPayments/UserPayments';
import { Redirect, useParams } from 'react-router-dom';
import TextField from '../../utils/TextField';
import { apiPost } from '../../../utils/api';
import { sepaCountries } from '../../../utils/sepaCountries';
import ConfirmButton from '../../utils/buttons/ConfirmButton';
import UserActions from './UserActions';
import UserLogins from './UserLogins';

interface IUser {
  id: number;
  username: string;
  firstName: string;
  lastName: string;
  lastNamePrefix: string | null;
  role: string;
  iban: string;
  bic: string;
  creditorId: string;
  company: string;
  companyName: string;
  address: string;
  country: string;
  postalCode: string;
  credit: number;
  confirmedEmail: boolean;
}

interface IProps {
  dispatch: any;
  isFetching: boolean;
  errorMessage: string;
  success: boolean;
  userId: number;
  user: IUser;
  token: any;
}

function UserFields(props: { user: IUser }) {
  return (
    <Box paddingTop={2} display={'flex'} flexDirection={'row'}>
      <Box width={'50%'}>
        <Box>
          {props.user.username} (ID: {props.user.id})
        </Box>
        <Box>{`${props.user.firstName} ${
          props.user.lastNamePrefix
            ? props.user.lastNamePrefix + ' ' + props.user.lastName
            : props.user.lastName
        }`}</Box>
        <Box>{props.user.role}</Box>
        <Box>{props.user.companyName || 'No company found'}</Box>
        <Box>
          {props.user.address}, {props.user.postalCode}
        </Box>
        <Box>
          {
            sepaCountries.find(
              (e: { label: string; value: string }) =>
                e.value === props.user.country
            )?.label
          }
          , {props.user.country}
        </Box>
      </Box>
      <Box width={'50%'}>
        <Box fontWeight={'bold'}>Defaults</Box>
        <Box>IBAN: {props.user.iban}</Box>
        <Box>BIC: {props.user.bic}</Box>
        <Box>Company: {props.user.company}</Box>
        <Box>Creditor ID: {props.user.creditorId}</Box>
      </Box>
    </Box>
  );
}

function UserDetails(props: IProps) {
  const { dispatch, isFetching, user, userId, token } = props;

  const [redirect, setRedirect] = useState<any>(null);
  const [savedMessage, setSavedMessage] = useState<any>('');
  const [newCredit, setNewCredit] = useState<number>(0);
  const [tempPassword, setTempPassword] = useState<any>('');
  const [tempPasswordMessage, setTempPasswordMessage] = useState<any>('');

  useEffect(() => {
    dispatch(fetchUser(userId));
  }, [dispatch, userId]);

  const { locale } = useParams<any>();

  const handleBack = () => {
    setRedirect(<Redirect to={`/${locale}/Admin`} />);
  };

  const handleCreditChange = (event: any) => {
    if (!isNaN(Number(event.target.value))) {
      setNewCredit(Number(event.target.value));
    }
  };

  const submitCreditChange = () => {
    apiPost(
      `/users/${user.id}/setCredit`,
      {
        value: newCredit
      },
      token
    )
      .then((response: any) => {
        if (response.ok) {
          setSavedMessage('New credit has been saved');
          dispatch(fetchUser(user.id));
        } else {
          setSavedMessage('Something went wrong');
        }
      })
      .catch(() => {
        setSavedMessage('Something went wrong');
      });
  };

  const handleTempPassword = (event: any) => {
    setTempPasswordMessage('');
    setTempPassword(event.target.value);
  };

  const submitTempPassword = () => {
    if (tempPassword.length < 6) {
      setTempPasswordMessage('Password must be longer than 5 characters');
      return;
    }
    apiPost(
      `/users/${user.id}/tempPassword`,
      {
        password: tempPassword
      },
      token
    )
      .then((response: any) => {
        if (response.ok) {
          setTempPasswordMessage('Password saved');
          setTempPassword('');
        } else {
          setTempPasswordMessage('Something went wrong');
        }
      })
      .catch(() => {
        setTempPasswordMessage('Something went wrong');
      });
  };

  return (
    <Fragment>
      <Button onClick={handleBack}>Back</Button>
      {redirect}
      <Card className="p-4 mb-4">
        <Loader open={isFetching} inElement />
        <CardContent className="p-0" style={{ marginTop: 15 }}>
          {user && (
            <>
              <UserActions user={user} />
              <Box paddingTop={2}>
                <Divider />
              </Box>
              <UserFields user={user} />
              <Box paddingTop={2} paddingBottom={2}>
                <Divider />
              </Box>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <Typography variant={'h4'}>One time password</Typography>
                  <Typography>{tempPasswordMessage}</Typography>
                  <TextField
                    label={'One time password'}
                    type={'password'}
                    onChange={handleTempPassword}
                    value={tempPassword}
                    required
                  />
                  <ConfirmButton
                    text={'Set password'}
                    onClick={submitTempPassword}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant={'h4'}>Change credits</Typography>
                  <Typography>{savedMessage}</Typography>
                  <TextField
                    label={'New credit'}
                    type={'number'}
                    onChange={handleCreditChange}
                    value={newCredit || 0}
                  />
                  <ConfirmButton
                    text={'Change credit'}
                    onClick={submitCreditChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <UserPayments userId={userId} />
                </Grid>
                <Grid item xs={12}>
                  <UserLogins userId={userId} />
                </Grid>
              </Grid>
            </>
          )}
        </CardContent>
      </Card>
    </Fragment>
  );
}

function mapStateToProps(state: any) {
  const {
    components: { admin }
  } = state;
  const { token } = state.auth;
  const { isFetching, error, success, user } = admin.details || {
    isFetching: false,
    error: null,
    success: false,
    user: null,
    token: null
  };
  return {
    isFetching,
    errorMessage: error,
    success,
    user,
    token
  };
}

export default connect(mapStateToProps)(UserDetails);
