import React, { Fragment } from 'react';

import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import styles from '../assets/cssModules/changePages.module.scss';
import ChangeAccountForm from '../components/forms/changeAccountForm/ChangeAccountForm';
import { Link, useParams } from 'react-router-dom';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import text from '../language/text';
import { saveUser } from '../components/forms/changeAccountForm/ducks';
import { getFormValues } from 'redux-form';
import Loader from '../components/utils/Loader';

interface IProps {
  dispatch: any;
  isFetching: boolean;
  errorMessage: string;
  success: boolean;
  user: any;
  extraValues: any;
}

function ChangeAccountPage(props: IProps) {
  const { locale } = useParams<any>();
  const { dispatch, isFetching } = props;

  const handleSubmit = (values: any) => {
    console.log('extraValues', values);
    dispatch(
      saveUser({
        ...values
      })
    );
  };

  return (
    <Fragment>
      <Grid container spacing={2} className={styles.WhiteBoxContainer}>
        <Grid item xs={11} className={styles.WhiteBox}>
          <div>
            <Loader open={isFetching} inElement />

            <Link to={`/${locale}/Dashboard`} className={styles.ToDashboard}>
              <ChevronLeftIcon style={{ color: '#0055ff' }} fontSize="large" />{' '}
              {text.basic.backToDashboard}
            </Link>
          </div>
          <Grid container spacing={4} style={{ justifyContent: 'center' }}>
            <Grid item lg={4}>
              <ChangeAccountForm onSubmit={handleSubmit} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
}

function mapStateToProps(state: any) {
  const { components } = state || {
    components: {
      modals: {
        editUserModal: {
          isFetching: false,
          error: null
        }
      }
    }
  };
  const { modals } = components;
  const { editUserModal } = modals;
  const { isFetching, error, open, success } = editUserModal;
  const v = getFormValues('ChangeSepaDefaultsForm')(state);
  return {
    isFetching,
    errorMessage: error,
    success,
    extraValues: v
  };
}

export default connect(mapStateToProps)(ChangeAccountPage);
