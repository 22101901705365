import text from '../../../language/text';

function ResetPasswordValidator(values: any) {
  const errors: any = {};

  if (!values.password1) {
    errors.password1 = true;
  }
  if (!values.password2) {
    errors.password2 = true;
  }
  if (values.password1 !== values.password2) {
    errors.password1 = text.forms.registerForm.passwordsDontMatchError;
    errors.password2 = true;
  }
  const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  if (!values.password1 || values.password1.length <= 6) {
    errors.password1 = text.forms.registerForm.passwordShortError;
  } else if (!passwordRegex.exec(values.password1)) {
    errors.password1 = text.forms.registerForm.passwordFormatError;
  }

  return errors;
}

export default ResetPasswordValidator;
