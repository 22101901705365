export function getSystemInfo() {
  const { detect } = require('detect-browser');
  const browser = detect();

  if (browser) {
    let browserName = browser.name.substring(0, 2).toUpperCase();
    let osName = browser.os.substring(0, 2).toUpperCase();
    // eslint-disable-next-line no-restricted-globals
    let resolution = screen.height.toString().substring(0, 2);
    return osName + '-' + browserName + '-' + resolution;
  }
}
