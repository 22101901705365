import React, { Fragment, useEffect } from 'react';
import DynamicForm from '../utils/DynamicForm';
import text from '../../language/text';
import { reduxForm } from 'redux-form';
import { Box, Button, Grid, makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { fetchPreviousColumns } from '../../reducers/templateActions';

interface IProps {
  columns: any[];
  dispatch: any;
  handleBack: () => void;
}

const useStyles = makeStyles(() => ({
  center: {
    textAlign: 'center'
  }
}));

function ColumnsForm(props: IProps & any) {
  const { columns, handleSubmit, handleBack, dispatch } = props;
  const classes = useStyles();

  const columnChoices = columns.map((s: any, i: any) => ({
    label: s,
    value: i
  }));

  useEffect(() => {
    dispatch(fetchPreviousColumns());
  }, [dispatch]);

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <DynamicForm
          handleSubmit={handleSubmit}
          fields={[
            {
              name: 'name',
              label: text.fields.sepa.companyName,
              type: 'select',
              choices: columnChoices,
              size: 4
            },
            {
              name: 'iban',
              label: text.fields.sepa.iban,
              type: 'select',
              choices: columnChoices,
              size: 4
            },
            {
              name: 'bic',
              label: text.fields.sepa.bic,
              type: 'select',
              choices: columnChoices,
              size: 4
            },
            {
              name: 'mandateId',
              label: text.fields.sepa.paymentInfo.mandateId,
              type: 'select',
              choices: columnChoices,
              size: 4
            },
            {
              name: 'mandateDate',
              label: text.fields.sepa.paymentInfo.mandateDate,
              type: 'select',
              choices: columnChoices,
              size: 4
            },
            {
              name: 'amount',
              label: text.fields.sepa.paymentInfo.amount,
              type: 'select',
              choices: columnChoices,
              size: 4
            },
            {
              name: 'endToEnd',
              label: text.fields.sepa.endToEndId,
              type: 'select',
              choices: columnChoices,
              size: 4
            },
            {
              name: 'description',
              label: text.fields.sepa.paymentInfo.description,
              type: 'select',
              choices: columnChoices,
              size: 4
            }
          ]}
        />
        <Grid item xs={12} className={classes.center}>
          <Box marginTop={2}>
            <div>
              <Button variant="outlined" color="primary" type="submit">
                {text.pages.dashboard.components.templateButtons.importCsv}
              </Button>
            </div>
            <div>
              {' '}
              <Button style={{ color: '#0079f4' }} onClick={handleBack}>
                {text.basic.back}
              </Button>
            </div>
          </Box>
        </Grid>
      </form>
    </Fragment>
  );
}

function mapStateToProps(state: any) {
  const { template } = state;

  console.log('template init vals', template.initialValues);

  return {
    initialValues: template.initialValues || {
      name: 0,
      iban: 1,
      bic: 2,
      mandateId: 3,
      mandateDate: 4,
      amount: 5,
      endToEnd: 6,
      description: 7
    }
  };
}

export default connect(mapStateToProps)(
  reduxForm<{}, IProps>({
    form: 'sepaColumns',
    enableReinitialize: true
  })(ColumnsForm)
);
