const formatMoney = (number: number) => {
  return number
    .toLocaleString('nl-NL', {
      style: 'currency',
      currency: 'EUR'
    })
    .replace(',00', ',-');
};

export { formatMoney };
