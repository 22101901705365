import { Button, Typography } from '@material-ui/core';
import React, { Fragment, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { fetchExcelColumns } from '../../../reducers/sepaActions';
import text from '../../../language/text';
import BigButton from '../../utils/buttons/BigButton';
import Loader from '../../utils/Loader';
import ModalDialog from '../../utils/ModalDialog';
// import GetAppIcon from '@material-ui/icons/GetApp';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';

interface IProps {
  dispatch: any;
  isFetching: boolean;
  errorMessage: string;
  success: boolean;
  modal: any;
}

function ImportExcelModal(props: IProps) {
  const { dispatch, modal } = props;

  const [importOpen, setImportOpen] = useState(false);
  const [fileName, setFileName] = useState('');

  const fileInput = useRef<HTMLInputElement>(null);

  const handleImportExcelClose = () => {
    setImportOpen(false);
    setFileName('');
  };
  const handleFileChange = () => {
    if (
      fileInput !== null &&
      fileInput.current !== null &&
      fileInput.current.files !== null
    ) {
      setFileName(fileInput.current.files[0].name);
    }
  };

  const handleUpload = () => {
    if (
      fileInput !== null &&
      fileInput.current !== null &&
      fileInput.current.files !== null
    ) {
      console.log(fileInput.current.files[0].name);
      dispatch(fetchExcelColumns(fileInput.current.files[0]));
    }
  };

  const handleImportExcel = () => {
    setImportOpen(true);
  };

  if (modal.success && importOpen) {
    setImportOpen(false);
  }

  return (
    <Fragment>
      <BigButton
        onClick={handleImportExcel}
        text={text.pages.dashboard.components.templateButtons.importCsv}
        icon={<OpenInBrowserIcon style={{ color: '#7a7a7a' }} />}
      />

      <ModalDialog
        open={importOpen}
        onClose={handleImportExcelClose}
        title={text.pages.dashboard.components.templateButtons.importCsv}
        actions={
          <Button onClick={handleUpload} color="primary">
            {text.pages.dashboard.components.templateButtons.importCsv}
          </Button>
        }>
        <Loader open={modal.isFetching || false} inElement />
        <Typography
          color="error"
          className={'font-weight-bold'}
          style={{ paddingBottom: '1rem' }}>
          {modal && modal.error ? modal.error : ''}
        </Typography>
        <div>{text.modals.importExcel.mainText}</div>
        <div className="mt-3">
          <input
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            style={{ display: 'none' }}
            id="raised-button-file"
            type="file"
            ref={fileInput}
            onChange={handleFileChange}
          />
          <label htmlFor="raised-button-file">
            <Button component="span" variant="outlined" color="primary">
              Upload
            </Button>
          </label>
          <Typography
            component="span"
            className={'font-weight-bold mt-3'}
            style={{ paddingLeft: '1rem' }}>
            {fileName}
          </Typography>
        </div>
      </ModalDialog>
    </Fragment>
  );
}

function mapStateToProps(state: any) {
  const { template } = state;
  const { isFetching, error, success, modal } = template || {
    isFetching: false,
    error: null,
    success: false,
    modal: {
      isFetching: false,
      error: null,
      success: false
    }
  };
  return {
    isFetching,
    errorMessage: error,
    success,
    modal
  };
}

export default connect(mapStateToProps)(ImportExcelModal);
