import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatMoney } from '../../../../utils/formatters';
import moment from 'moment';

interface IProps {
  dispatch: any;
  payments: any[];
  showSubscription?: boolean;
}

interface IRowProps {
  payment: any;
  showSubscription?: boolean;
}

function CreditPaymentRow(props: IRowProps) {
  const { payment, showSubscription } = props;
  return (
    <tr>
      <td>{payment.id}</td>
      <td>{moment(payment.createdAt).format('HH:mm:ss DD/MM/YYYY')}</td>
      <td>{payment.mollieId}</td>
      <td>{formatMoney(payment.amount)}</td>
      <td>{payment.status}</td>
      <td>{payment.processed ? 'Yes' : 'No'}</td>
      {showSubscription && <td>{payment.subscriptionId}</td>}
    </tr>
  );
}

function CreditPaymentTable(props: IProps) {
  const { showSubscription } = props;
  return (
    <Fragment>
      <div className="table-responsive">
        <table className="table table-hover text-nowrap mb-0 ">
          <thead className="thead-light">
            <tr>
              <th>Id</th>
              <th>created at</th>
              <th>Mollie ID</th>
              <th>amount</th>
              <th>status</th>
              <th>processed</th>
              {showSubscription && <th>Subscription ID</th>}
            </tr>
          </thead>
          <tbody>
            {props.payments
              ? props.payments.map((payment: any, index: any) => (
                  <CreditPaymentRow
                    key={index}
                    payment={payment}
                    showSubscription={showSubscription}
                  />
                ))
              : null}
          </tbody>
        </table>
        {!props.payments || props.payments.length === 0 ? (
          <div className="p-4 font-size-md text-black-50 d-block text-center">
            <FontAwesomeIcon icon={['fas', 'download']} />
            <div>No subscription payments</div>
          </div>
        ) : null}
      </div>
    </Fragment>
  );
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps)(CreditPaymentTable);
