import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchPayments } from './ducks/operations';
import { Typography, Grid, Divider } from '@material-ui/core';
import CreditPaymentTable from './CreditPaymentTable';
interface IProps {
  dispatch: any;
  isFetching: boolean;
  errorMessage: string;
  success: boolean;
  userId: number;
  payments: {
    creditPayments: any[];
    subscriptionPayments: any[];
  };
}

function UserPayments(props: IProps) {
  const { dispatch, payments, userId } = props;

  useEffect(() => {
    dispatch(fetchPayments(userId));
  }, [dispatch, userId]);

  return (
    <Fragment>
      <Typography variant="h3" className={'my-2'}>
        Payments
      </Typography>
      <Divider />
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <Typography variant="h4" className={'my-2'}>
            Credit payments
          </Typography>
          <CreditPaymentTable payments={payments.creditPayments} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Typography variant="h4" className={'my-2'}>
            Subscription payments
          </Typography>
          <CreditPaymentTable
            payments={payments.subscriptionPayments}
            showSubscription
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}

function mapStateToProps(state: any) {
  const {
    components: { admin }
  } = state;
  const { isFetching, error, success, payments } = admin.UserPayments || {
    isFetching: false,
    error: null,
    success: false,
    payments: null
  };
  return {
    isFetching,
    errorMessage: error,
    success,
    payments
  };
}

export default connect(mapStateToProps)(UserPayments);
