import React from 'react';
import { Breadcrumbs as MUIBreadcrumbs, Typography } from '@material-ui/core';
import { withRouter, useParams } from 'react-router-dom';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import styles from './BreadcrumbsStyling.module.css';

const Breadcrumbs = (props: any) => {
  const {
    history,
    location: { pathname }
  } = props;
  const pathnames = pathname.split('/').filter((x: any) => x);
  console.log(pathnames);
  const { locale } = useParams<any>();

  return (
    <MUIBreadcrumbs
      className={styles.BreadcrumbsStyling}
      aria-label="breadcrumb"
      separator={
        <NavigateNextIcon
          style={{ color: '#5C5C5C', fontSize: '20px', fontWeight: 'bold' }}
        />
      }>
      {pathnames.length > 0 ? (
        <Typography
          variant={'h5'}
          onClick={() => history.push(`/${locale}/Settings`)}>
          Settings
        </Typography>
      ) : (
        <Typography variant={'h5'}> Settings </Typography>
      )}
    </MUIBreadcrumbs>
  );
};

export default withRouter(Breadcrumbs);
