import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import DynamicForm from '../../utils/DynamicForm';
import ChangeAccountValidator from './ChangeAccountValidator';
import text from '../../../language/text';
import { Box, Typography } from '@material-ui/core';
import Loader from '../../utils/Loader';
import { sepaCountries } from '../../../utils/sepaCountries';
import styles from '../../../assets/cssModules/login.module.scss';
import headingStyles from '../../../assets/cssModules/changePages.module.scss';
import ConfirmButton from '../../utils/buttons/ConfirmButton';

interface IProps {
  dispatch: any;
  isFetching: boolean;
  errorMessage: string;
  success: boolean;
  hideSubmit?: boolean;
  initialValues?: any;
}

function ChangeAccountForm(props: IProps & any) {
  const { handleSubmit, errorMessage, isFetching, success } = props;

  return (
    <Fragment>
      <div className={styles.CenterLoginContainer}>
        <div className={styles.ChangePwdContainer}>
          <h1 className={headingStyles.AccountTitle}>
            {text.forms.changeAccount.title}
          </h1>
          <p className={headingStyles.Text}>
            {text.forms.changeAccount.subtitle}
          </p>
          <form onSubmit={handleSubmit}>
            <Typography color="error">{errorMessage}</Typography>
            <Typography color="primary">
              {success && text.forms.changeAccount.success}
            </Typography>
            <Loader open={isFetching} inElement />
            <DynamicForm
              handleSubmit={handleSubmit}
              fields={[
                {
                  name: 'firstName',
                  label: text.fields.firstName,
                  required: true
                },
                {
                  name: 'lastNamePrefix',
                  label: text.fields.lastNamePrefix,
                  size: 3
                },
                {
                  name: 'lastName',
                  label: text.fields.lastName,
                  required: true,
                  size: 9
                },
                {
                  name: 'username',
                  label: text.fields.email,
                  type: 'email'
                },
                {
                  name: 'company',
                  label: text.fields.company
                },
                {
                  name: 'address',
                  label: text.fields.address
                },
                {
                  name: 'postalCode',
                  label: text.fields.postalCode,
                  size: 4
                },
                {
                  name: 'country',
                  label: text.fields.country,
                  type: 'select',
                  choices: sepaCountries,
                  size: 8
                }
              ]}
              outlined
            />
          </form>
          <Box
            className={styles.FormBtns}
            style={{
              margin: '5% 0%',
              display: 'flex',
              justifyContent: 'center'
            }}>
            <ConfirmButton
              buttonStyle={'changePasswordBtn'}
              onClick={handleSubmit}
              text={text.forms.changeAccount.save}
            />
          </Box>
        </div>
      </div>
    </Fragment>
  );
}

function mapStateToProps(state: any, props: any) {
  const { changeAccount } = state.components.forms;
  const { isFetching, error, success } = changeAccount || {
    isFetching: false,
    error: null,
    success: false
  };
  return {
    isFetching,
    errorMessage: error,
    success,
    initialValues: props.initialValues ? props.initialValues : state.auth.user
  };
}

export default connect(mapStateToProps)(
  reduxForm<{}, IProps>({
    form: 'ChangeAccountForm',
    validate: ChangeAccountValidator
  })(ChangeAccountForm)
);
