import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Card, Grid, CardContent } from '@material-ui/core';
import text from '../../language/text';
import Loader from '../utils/Loader';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ErrorOutlineSharpIcon from '@material-ui/icons/ErrorOutlineSharp';
import { fetchDownloads } from '../../reducers/dashboardActions';
import RecentDownloadRow from './RecentDownloadRow';
import styles from '../../assets/cssModules/dashboardPage.module.css';

interface IProps {
  dispatch: any;
  isFetching: boolean;
  error: string;
  downloads: any;
}

function RecentDownloadList(props: IProps) {
  const { dispatch } = props;

  useEffect(() => {
    dispatch(fetchDownloads());
  }, [dispatch]);

  return (
    <Fragment>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card className="p-4 mb-4">
            <Loader open={props.isFetching} inElement />
            <div className={styles.DownloadsTitleText}>
              {text.pages.dashboard.components.recentDownloads}
            </div>
            <CardContent className="p-0" style={{ marginTop: '7%' }}>
              <div className="table-responsive">
                {props.downloads &&
                props.downloads.length === 0 &&
                !props.isFetching ? (
                  <div className="p-4 font-size-md text-black-50 d-block text-center">
                    <ErrorOutlineSharpIcon
                      style={{ color: '#0055ff' }}
                      fontSize="large"
                    />
                    {/*<FontAwesomeIcon icon={['fas', 'download']} />*/}
                    <div className={styles.DownloadsNfText}>
                      {text.pages.dashboard.components.recentDownloadsNotFound}
                    </div>
                  </div>
                ) : (
                  <table>
                    <tr>
                      <th className={styles.DateDownloadText}>
                        {text.fields.date}
                      </th>
                    </tr>
                    <tbody>
                      {props.downloads
                        ? props.downloads.map((download: any, index: any) => (
                            <RecentDownloadRow
                              key={index}
                              download={download}
                            />
                          ))
                        : null}
                    </tbody>
                  </table>
                )}
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Fragment>
  );
}

function mapStateToProps(state: any) {
  const {
    dashboard: { recentDownloads }
  } = state;
  const { isFetching, error, downloads } = recentDownloads || {
    isFetching: false,
    error: null,
    downloads: []
  };

  return {
    isFetching,
    error,
    downloads
  };
}

export default connect(mapStateToProps)(RecentDownloadList);
