import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Button, Typography } from '@material-ui/core';
import DynamicForm from '../../utils/DynamicForm';
import text from '../../../language/text';
import ResetPasswordValidator from './ResetPasswordValidator';

interface IProps {
  dispatch: any;
  isFetching: boolean;
  errorMessage: string;
  success: boolean;
}

function ResetPasswordForm(props: IProps & any) {
  const { handleSubmit, errorMessage, success } = props;
  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        {success && (
          <Typography color="primary" className="my-2">
            {text.forms.changePasswordForm.success}
          </Typography>
        )}
        {errorMessage && (
          <Typography color="error" className="my-2">
            {text.forms.changePasswordForm.error}
          </Typography>
        )}
        <DynamicForm
          handleSubmit={handleSubmit}
          fields={[
            {
              name: 'password1',
              label: text.fields.newPassword,
              required: true,
              type: 'password',
              errorText: true
            },
            {
              name: 'password2',
              label: text.fields.repeatPassword,
              required: true,
              type: 'password'
            }
          ]}
          outlined
        />
        <div className="text-center mt-2">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className="btn m-2 right">
            {text.forms.changePasswordForm.savePassword}
          </Button>
        </div>
      </form>
    </Fragment>
  );
}

function mapStateToProps(state: any) {
  const { resetPassword } = state;
  const { isFetching, error, success } = resetPassword || {
    isFetching: false,
    error: null,
    success: false
  };
  return {
    isFetching,
    errorMessage: error,
    success
  };
}

export default connect(mapStateToProps)(
  reduxForm<{}, IProps>({
    form: 'ResetPasswordForm',
    validate: ResetPasswordValidator
  })(ResetPasswordForm)
);
