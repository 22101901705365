import {
  CLOSE_MODAL,
  OPEN_MODAL,
  RECEIVE_SAVE_USER,
  REQUEST_SAVE_USER,
  SAVE_USER_ERROR
} from './types';

const openModal = () => ({
  type: OPEN_MODAL
});

const closeModal = () => ({
  type: CLOSE_MODAL
});

const requestSaveUSer = () => ({
  type: REQUEST_SAVE_USER
});

const receiveSaveUSer = () => ({
  type: RECEIVE_SAVE_USER
});

const saveUserError = (error: string) => ({
  type: SAVE_USER_ERROR,
  error
});

export {
  openModal,
  closeModal,
  requestSaveUSer,
  receiveSaveUSer,
  saveUserError
};
