import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Grid, Card, CardContent } from '@material-ui/core';
import Loader from '../utils/Loader';

import { createSepa, switchInForm } from '../../reducers/sepaActions';
import TemplatesComponent from './TemplatesComponent';
import SepaForm from '../forms/SepaForm';
import CreditActionModal from '../modals/basicModals/CreditActionModal';
import { fetchUser } from '../admin/users/ducks';

interface IProps {
  dispatch: any;
  isFetching: boolean;
  error: string;
  role: any;
  showForm: boolean;
}

function SepaComponent(props: IProps) {
  const { dispatch, role } = props;

  const [creditActionOpen, setCreditActionOpen] = useState(false);
  const [bInfo, setBasicInfo] = useState(Object);
  const [rInfo, setReceiverInfo] = useState(Object);
  const [tInfo, setTransactionInfo] = useState(Object);

  const pay = () => {
    dispatch(createSepa(bInfo, rInfo, tInfo));
    setCreditActionOpen(false);
  };

  const handleSubmit = (values: any) => {
    const basicInfo = {
      isDebit: values.debit === 'true',
      creditorId: values.creditorId,
      isBatchBooking: values.isBatchBooking === 'as_batch',
      collectionDate: values.collectionDate,
      method: values.method,
      frequency: values.frequency,
      paymentType: values.paymentType
    };
    setBasicInfo(basicInfo);

    const receiver = {
      iban: values.iban,
      bic: values.bic,
      name: values.company
    };
    setReceiverInfo(receiver);
    console.log(values.debit);
    console.log('transacts', values.transactions);
    const transactions = values.transactions.map((transaction: any) => {
      console.log(
        'VALID???',
        transaction.mandateReferenceDate == 'Invalid date'
      );
      return {
        bankAccount: {
          iban: transaction.iban,
          bic: transaction.bic,
          name: transaction.name
        },
        ...transaction,
        mandateReferenceDate:
          transaction.mandateReferenceDate == 'Invalid date'
            ? null
            : transaction.mandateReferenceDate
      };
    });
    setTransactionInfo(transactions);

    if (role === 'FREE') {
      setCreditActionOpen(true);
    } else {
      dispatch(createSepa(basicInfo, receiver, transactions));
    }
  };

  return (
    <Fragment>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card className="p-4 mb-4">
            <Loader open={props.isFetching} inElement />
            <CardContent className="pt-1">
              {props.showForm ? (
                <SepaForm
                  onSubmit={handleSubmit}
                  handleBack={() => dispatch(switchInForm(false))}
                />
              ) : (
                <TemplatesComponent
                  switchToForm={() => dispatch(switchInForm(true))}
                />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <CreditActionModal
        open={creditActionOpen}
        handleCancel={() => setCreditActionOpen(false)}
        handleClose={pay}
      />
    </Fragment>
  );
}

function mapStateToProps(state: any) {
  const { sepa } = state;
  const { isFetching, error, showForm } = sepa || {
    isFetching: false,
    error: null,
    showForm: false
  };
  const { auth } = state;
  const { role } = auth.user || {
    role: 'UNKNOWN'
  };
  return {
    isFetching,
    error,
    role,
    showForm
  };
}

export default connect(mapStateToProps)(SepaComponent);
