import React from 'react';

const DownloadsListArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14"
      viewBox="0 0 12 10">
      <g fill="none" fillRule="evenodd">
        <g fill="#05F" fillRule="nonzero">
          <g>
            <path
              d="M11.279 4.182c-.05-.008-.1-.012-.149-.011H2.664l.185-.084c.18-.083.345-.195.485-.333l2.374-2.307c.313-.29.365-.757.125-1.106-.28-.371-.818-.452-1.2-.18-.031.022-.06.046-.088.072L.252 4.404c-.336.326-.336.854 0 1.18v.001l4.293 4.171c.336.326.88.325 1.214-.001.026-.026.051-.053.074-.082.24-.349.188-.815-.125-1.105l-2.37-2.311c-.126-.123-.27-.226-.429-.305l-.257-.113h8.431c.439.016.823-.281.906-.7.076-.455-.242-.884-.71-.957z"
              transform="translate(-1351 -208) rotate(-180 681.5 109)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default DownloadsListArrow;
