import { RECEIVE_SAVE_USER, REQUEST_SAVE_USER, SAVE_USER_ERROR } from './types';

const requestSaveUser = () => ({
  type: REQUEST_SAVE_USER
});

const receiveSaveUser = () => ({
  type: RECEIVE_SAVE_USER
});

const saveUserError = (error: string) => ({
  type: SAVE_USER_ERROR,
  error
});

export { requestSaveUser, receiveSaveUser, saveUserError };
