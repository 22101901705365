import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import {
  Typography,
  ButtonGroup,
  Button,
  InputAdornment
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import text from '../../../language/text';
import DynamicForm from '../../utils/DynamicForm';
import AddCreditValidator from './AddCreditValidator';
import { reduxForm, change } from 'redux-form';

interface IProps {
  dispatch: any;
  isFetching: boolean;
  errorMessage: string;
  success: boolean;
}

function AddCreditForm(props: IProps & any) {
  const { dispatch, handleSubmit, errorMessage, success } = props;

  const [button1, setButton1] = useState(false);
  const [button2, setButton2] = useState(false);
  const [button3, setButton3] = useState(false);

  const handleValueChange = (value: any) => {
    dispatch(change('AddCreditForm', 'value', value));
    if (isNaN(Number(value))) return;
    const v = Number(value);
    if (v === 10) {
      setButton1(true);
      setButton2(false);
      setButton3(false);
    } else if (v === 25) {
      setButton1(false);
      setButton2(true);
      setButton3(false);
    } else if (v === 50) {
      setButton1(false);
      setButton2(false);
      setButton3(true);
    } else {
      setButton1(false);
      setButton2(false);
      setButton3(false);
    }
  };

  return (
    <Fragment>
      <form>
        <Typography color="error"> {errorMessage} </Typography>
        <Typography color="primary"> {success && 'Done'} </Typography>
        <ButtonGroup className="mb-2" style={{ width: '100%' }}>
          <Button
            variant="contained"
            color={button1 ? 'primary' : 'default'}
            onClick={() => handleValueChange(10)}
            style={{ width: '100%' }}>
            €10
          </Button>
          <Button
            variant="contained"
            color={button2 ? 'primary' : 'default'}
            onClick={() => handleValueChange(25)}
            style={{ width: '100%' }}>
            €25
          </Button>
          <Button
            variant="contained"
            color={button3 ? 'primary' : 'default'}
            onClick={() => handleValueChange(50)}
            style={{ width: '100%' }}>
            €50
          </Button>
        </ButtonGroup>
        <DynamicForm
          handleSubmit={handleSubmit}
          fields={[
            {
              name: 'value',
              label: text.modals.addCredit.value,
              required: true,
              type: 'number',
              extraProps: {
                InputProps: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon icon={['fas', 'euro-sign']} />
                    </InputAdornment>
                  )
                },
                onChange: (event: any) => handleValueChange(event.target.value)
              }
            }
          ]}
          outlined
        />
      </form>
    </Fragment>
  );
}

function mapStateToProps(state: any) {
  const { components } = state || {
    components: {
      modals: {
        addCreditModal: {
          isFetching: false,
          open: false,
          error: null
        }
      }
    }
  };
  const { modals } = components;
  const { addCreditModal } = modals;
  const { isFetching, error, success } = addCreditModal;
  return {
    isFetching,
    errorMessage: error,
    success
  };
}

export default connect(mapStateToProps)(
  reduxForm<{}, IProps>({
    form: 'AddCreditForm',
    validate: AddCreditValidator
  })(AddCreditForm)
);
