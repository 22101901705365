import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import RegisterValidator from './RegisterValidator';
import { reduxForm } from 'redux-form';
import DynamicForm from '../../utils/DynamicForm';
import text from '../../../language/text';
import { Typography } from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import Loader from '../../utils/Loader';
import { sepaCountries } from '../../../utils/sepaCountries';
import styles from '../../../assets/cssModules/login.module.scss';
import ConfirmButton from '../../utils/buttons/ConfirmButton';

interface IProps {
  dispatch: any;
  isFetching: boolean;
  errorMessage: string;
  success: boolean;
}

function RegisterForm(props: IProps & any) {
  const { handleSubmit, errorMessage, success, isFetching } = props;

  const { locale } = useParams<any>();

  const PressedEnter = (event: any) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };

  return (
    <Fragment>
      <div className={styles.CenterLoginContainer}>
        <div className={styles.RegisterContainer}>
          <h1 className={styles.LoginHeader}>
            {text.forms.registerForm.title}
          </h1>
          <form onSubmit={handleSubmit} onKeyPress={PressedEnter}>
            <Loader open={isFetching} />
            <Typography color="error">{errorMessage}</Typography>
            <Typography color="primary">
              {success && text.forms.registerForm.success}
            </Typography>
            <DynamicForm
              handleSubmit={handleSubmit}
              fields={[
                {
                  name: 'firstName',
                  label: text.fields.firstName,
                  required: true
                },
                {
                  name: 'lastNamePrefix',
                  label: text.fields.lastNamePrefix,
                  size: 3
                },
                {
                  name: 'lastName',
                  label: text.fields.lastName,
                  required: true,
                  size: 9
                },
                {
                  name: 'company',
                  label: text.fields.company
                },
                {
                  name: 'address',
                  label: text.fields.address
                },
                {
                  name: 'postalCode',
                  label: text.fields.postalCode,
                  size: 4
                },
                {
                  name: 'country',
                  label: text.fields.country,
                  type: 'select',
                  choices: sepaCountries,
                  size: 8
                },
                {
                  name: 'email',
                  label: text.fields.email,
                  required: true,
                  type: 'email'
                },
                {
                  name: 'password',
                  label: text.fields.password,
                  required: true,
                  type: 'password',
                  errorText: true
                },
                {
                  name: 'passwordRepeat',
                  label: text.fields.repeatPassword,
                  required: true,
                  type: 'password',
                  errorText: true
                }
              ]}
              outlined
            />
            <div className="text-center">
              <ConfirmButton
                buttonStyle={'SignInBtn'}
                onClick={handleSubmit}
                text={text.forms.registerForm.title}
              />
              <Link to={`/${locale}/login`} className={styles.OtherOptionText}>
                {text.forms.registerForm.goLogin}
              </Link>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
}

function mapStateToProps(state: any) {
  const { register } = state;
  const { isFetching, error, success } = register || {
    isFetching: false,
    error: null,
    success: false
  };
  return {
    isFetching,
    errorMessage: error,
    success
  };
}

export default connect(mapStateToProps)(
  reduxForm<{}, IProps>({
    form: 'RegisterForm',
    validate: RegisterValidator
  })(RegisterForm)
);
