import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Grid, Card } from '@material-ui/core';
import Loader from '../../utils/Loader';
import StartSubscription from './StartSubscription';
import CancelSubscription from './CancelSubscription';
interface IProps {
  dispatch: any;
  isFetching: boolean;
  errorMessage: string;
  success: boolean;
  user: any;
}

function Subscription(props: IProps) {
  const { user, isFetching } = props;
  return (
    <Fragment>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card className="p-4 mb-4">
            <Loader inElement open={isFetching} />
            {user !== undefined ? (
              user.role === 'FREE' ? (
                <StartSubscription />
              ) : (
                <CancelSubscription />
              )
            ) : null}
          </Card>
        </Grid>
      </Grid>
    </Fragment>
  );
}

function mapStateToProps(state: any) {
  const { auth, components } = state;
  const { isFetching, error, success } = components.settings || {
    isFetching: false,
    error: null,
    success: false
  };
  return {
    isFetching,
    errorMessage: error,
    success,
    user: auth.user
  };
}

export default connect(mapStateToProps)(Subscription);
