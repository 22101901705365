import {
  REQUEST_SEPA_TEMPLATE,
  RECEIVE_SEPA_TEMPLATE,
  SEPA_TEMPLATE_ERROR,
  CLEAR_MODAL,
  RECEIVE_PREVIOUS_COLUMNS
} from './templateActions';
import {
  REQUEST_UPLOAD_EXCEL,
  RECEIVE_UPLOAD_EXCEL,
  SEPA_UPLOAD_ERROR,
  RECEIVE_COLUMNS,
  SWITCH_FORM
} from './sepaActions';

function template(
  state = {
    isFetching: false,
    error: null,
    success: false,
    modal: {
      isFetching: false,
      error: null,
      success: false
    }
  },
  action: any
) {
  switch (action.type) {
    case REQUEST_SEPA_TEMPLATE:
      return {
        ...state,
        isFetching: true,
        error: null,
        success: false
      };
    case RECEIVE_SEPA_TEMPLATE:
      return {
        ...state,
        isFetching: false,
        error: null,
        success: true
      };
    case SEPA_TEMPLATE_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        success: false
      };

    case REQUEST_UPLOAD_EXCEL:
      return {
        ...state,
        modal: {
          error: null,
          success: null,
          isFetching: true
        }
      };
    case RECEIVE_UPLOAD_EXCEL:
      return {
        ...state,
        modal: {
          error: null,
          success: true,
          isFetching: false
        }
      };
    case SEPA_UPLOAD_ERROR:
      return {
        ...state,
        modal: {
          error: action.error,
          success: false,
          isFetching: false
        }
      };
    case CLEAR_MODAL:
      return {
        ...state,
        isFetching: false,
        file: null,
        columns: null,
        modal: {
          error: null,
          success: false,
          isFetching: false
        }
      };
    case RECEIVE_PREVIOUS_COLUMNS:
      return {
        ...state,
        initialValues: action.columns,
        isFetching: false
      };
    case RECEIVE_COLUMNS:
      return {
        ...state,
        columns: action.columns,
        file: action.file
      };
    default:
      return { ...state };
  }
}

export default template;
