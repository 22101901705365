const OPEN_MODAL = 'addCreditModal/ducks/OPEN_MODAL';
const CLOSE_MODAL = 'addCreditModal/ducks/CLOSE_MODAL';
const REQUEST_SAVE_USER = 'addCreditModal/ducks/REQUEST_SAVE_USER';
const RECEIVE_SAVE_USER = 'addCreditModal/ducks/RECEIVE_SAVE_USER';
const SAVE_USER_ERROR = 'addCreditModal/ducks/SAVE_USER_ERROR';
const RECEIVE_PAID = 'addCreditModal/ducks/RECEIVE_PAID';
const RECEIVE_CANCELED = 'addCreditModal/ducks/RECEIVE_CANCELED';

export {
  OPEN_MODAL,
  CLOSE_MODAL,
  REQUEST_SAVE_USER,
  RECEIVE_SAVE_USER,
  SAVE_USER_ERROR,
  RECEIVE_PAID,
  RECEIVE_CANCELED
};
