import React from 'react';
import ModalDialog from '../../utils/ModalDialog';
import { Typography } from '@material-ui/core';
import text from '../../../language/text';
import ConfirmButton from '../../utils/buttons/ConfirmButton';

interface IProps {
  title: string;
  success: string | any;
  handleClose: () => void;
}

function SuccessModal(props: IProps) {
  return (
    <ModalDialog
      open={true}
      onClose={props.handleClose}
      title={props.title}
      actions={[
        <ConfirmButton
          onClick={props.handleClose}
          text={text.modals.startSubscription.successClose}
        />
      ]}>
      <Typography>{props.success}</Typography>
    </ModalDialog>
  );
}

export default SuccessModal;
