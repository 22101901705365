import React, { Component } from 'react';
import { Field } from 'redux-form';
import { Box, Grid, withStyles } from '@material-ui/core';
import { renderSelectField, renderTextField } from './materialUI';
import ConfirmButton from './buttons/ConfirmButton';

interface IField {
  id?: string;
  name: string;
  label: string;
  type?: string;
  choices?: any[];
  required?: boolean;
  defaultValue?: any;
  errorText?: boolean;
  extraProps?: any;
  size?: any;
  onChange?: (value: any) => any;
  validate?: (value: any) => any;
}

interface IProps {
  handleSubmit: any;
  fields: IField[];
  classes: any;
  twoColumns?: boolean;
  submitButton?: boolean;
  submitString?: string;
  outlined?: boolean;
}
interface IState {}

class DynamicForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  renderField = (field: IField, index: number) => {
    let extraProps: any = {};
    if (this.props.outlined) {
      extraProps.variant = 'outlined';
    }
    if (field.type === 'date') {
      extraProps.InputLabelProps = {
        shrink: true
      };
    }
    let renderer: any = renderTextField;
    if (field.type === 'select') {
      renderer = renderSelectField;
      extraProps.choices = field.choices;
    } else {
      extraProps.className = this.props.classes.textField;
    }

    if (field.errorText === true) {
      extraProps.errortext = true;
    }
    if (field.extraProps !== undefined) {
      extraProps = { ...extraProps, ...field.extraProps };
    }
    if (field.onChange !== undefined) {
      extraProps.onChange = field.onChange;
    }

    return (
      <Field
        id={field.id}
        key={index}
        name={field.name}
        component={renderer}
        label={field.label}
        fullWidth
        required={field.required}
        type={field.type || 'text'}
        validate={field.validate}
        {...extraProps}
      />
    );
  };

  render() {
    const { handleSubmit, fields, twoColumns, submitButton, submitString } =
      this.props;

    let leftFields: IField[] = fields;
    let rightFields: IField[] = fields;
    if (twoColumns) {
      const half = Math.ceil(fields.length / 2);
      leftFields = fields.splice(0, half);
      rightFields = fields.splice(-half);
    }

    return (
      <Grid container spacing={3}>
        <Grid item xs={twoColumns ? 6 : 12}>
          <Grid container spacing={1}>
            {leftFields.map((field: IField, i: number) => {
              return (
                <Grid item xs={field.size ? field.size : 12} key={i}>
                  {this.renderField(field, i)}
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        {twoColumns ? (
          <Grid item xs={6}>
            <Grid container spacing={1}>
              {rightFields.map((field: IField, i: number) => {
                return (
                  <Grid item xs={field.size ? field.size : 12} key={i}>
                    {this.renderField(field, i)}
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        ) : (
          ''
        )}
        {submitButton ? (
          <Box display={'flex'} justifyContent={'center'} width={'100%'}>
            <ConfirmButton
              type="submit"
              onClick={handleSubmit}
              fullWidth={false}
              text={submitString || 'Save'}
            />
          </Box>
        ) : (
          ''
        )}
      </Grid>
    );
  }
}

const useStyles = (theme: any) => ({
  root: {},
  textField: {
    marginTop: theme.spacing(1)
  },
  submitButton: {
    margin: theme.spacing(1)
  }
});

export default withStyles(useStyles)(DynamicForm);
