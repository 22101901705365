import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import Loader from '../utils/Loader';
import text from '../../language/text';
import {
  clearModal,
  downloadSepaTemplate
} from '../../reducers/templateActions';
import ImportXmlModal from '../modals/ImportXmlModal/ImportXmlModal';
import ImportExcelModal from '../modals/ImportExcelModal/ImportExcelModal';
import BigButton from '../utils/buttons/BigButton';
// import GetAppIcon from '@material-ui/icons/GetApp';
// import uploadIcon from '../../assets/images/uploadIcon.png';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import ColumnsForm from '../forms/ColumnsForm';
import { uploadExcel } from '../../reducers/sepaActions';
import styles from '../../assets/cssModules/dashboardPage.module.css';
import ConfirmButton from '../utils/buttons/ConfirmButton';

interface IProps {
  dispatch: any;
  isFetching: boolean;
  error: string;
  switchToForm: () => void;
  columns: any[];
  file: any;
}

const useStyles = makeStyles(() => ({
  titleWrapper: {
    width: '100%',
    textAlign: 'center'
  },
  center: {
    textAlign: 'center'
  }
}));

function TemplatesComponent(props: IProps) {
  const classes = useStyles();
  const { dispatch, error, isFetching, switchToForm, columns, file } = props;

  const handleDownloadTemplate = () => {
    dispatch(downloadSepaTemplate());
  };

  const handleSubmit = (values: any) => {
    dispatch(uploadExcel(file, values));
    switchToForm();
  };

  const handleBack = () => {
    dispatch(clearModal());
  };

  const headerAndError = (
    titleText: String,
    subText: String,
    error: String
  ) => (
    <>
      <Loader open={isFetching || false} inElement />
      <div className={classes.titleWrapper}>
        <h1 id={'DashboardHeader'} className={styles.TitleText}>
          {titleText}
        </h1>
        <p className={styles.SubtitleText}>{subText}</p>
      </div>

      <Typography color="error" className={'font-weight-bold'}>
        {error}
      </Typography>
    </>
  );

  if (columns != null && file != null) {
    return (
      <>
        {headerAndError(
          text.pages.dashboard.components.templates,
          text.pages.dashboard.components.templatesSubtext,
          error
        )}
        <Grid container>
          <ColumnsForm
            columns={columns}
            onSubmit={handleSubmit}
            handleBack={handleBack}
          />
        </Grid>
      </>
    );
  }

  return (
    <Fragment>
      {headerAndError(
        text.pages.dashboard.components.templates,
        text.pages.dashboard.components.templatesSubtext,
        error
      )}

      <Grid container spacing={3}>
        <Grid item xs={4} className={classes.center}>
          <BigButton
            onClick={handleDownloadTemplate}
            text={
              text.pages.dashboard.components.templateButtons.downloadTemplate
            }
            icon={<OpenInBrowserIcon style={{ color: '#7a7a7a' }} />}
          />
        </Grid>
        <Grid item xs={4} className={classes.center}>
          <ImportXmlModal />
        </Grid>
        <Grid item xs={4} className={classes.center}>
          <ImportExcelModal />
        </Grid>
        <Grid item xs={12} className={classes.center}>
          <p className={styles.SubtitleText}>
            {text.pages.dashboard.components.or}
          </p>
        </Grid>
        <Grid item xs={12} className={styles.CenterNewDocumentBtn}>
          <ConfirmButton
            fullWidth={false}
            onClick={switchToForm}
            text={
              text.pages.dashboard.components.templateButtons.startNewDocument
            }
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}

function mapStateToProps(state: any) {
  const { template } = state;
  const { isFetching, error, columns, file } = template || {
    isFetching: false,
    error: null,
    columns: null,
    file: null
  };
  return {
    isFetching: isFetching,
    error: error,
    columns,
    file
  };
}

export default connect(mapStateToProps)(TemplatesComponent);
