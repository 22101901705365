import {
  CLOSE_MODAL,
  OPEN_MODAL,
  RECEIVE_UPLOAD_XML,
  REQUEST_UPLOAD_XML,
  UPLOAD_XML_ERROR
} from './types';

const openModal = () => ({
  type: OPEN_MODAL
});

const closeModal = () => ({
  type: CLOSE_MODAL
});

const requestUploadXml = () => ({
  type: REQUEST_UPLOAD_XML
});

const receiveUploadXml = () => ({
  type: RECEIVE_UPLOAD_XML
});

const uploadXmlError = (error: string) => ({
  type: UPLOAD_XML_ERROR,
  error
});

export {
  openModal,
  closeModal,
  requestUploadXml,
  receiveUploadXml,
  uploadXmlError
};
