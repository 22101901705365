const OPEN_MODAL = 'importXmlModal/ducks/OPEN_MODAL';
const CLOSE_MODAL = 'importXmlModal/ducks/CLOSE_MODAL';
const REQUEST_UPLOAD_XML = 'importXmlModal/ducks/REQUEST_UPLOAD_XML';
const RECEIVE_UPLOAD_XML = 'importXmlModal/ducks/RECEIVE_UPLOAD_XML';
const UPLOAD_XML_ERROR = 'importXmlModal/ducks/UPLOAD_XML_ERROR';

export {
  OPEN_MODAL,
  CLOSE_MODAL,
  REQUEST_UPLOAD_XML,
  RECEIVE_UPLOAD_XML,
  UPLOAD_XML_ERROR
};
