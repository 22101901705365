import { apiGet } from '../utils/api';

export const REQUEST_TOKEN = 'REQUEST_TOKEN';
export const RECEIVE_TOKEN = 'RECEIVE_TOKEN';
export const LOAD_TOKEN = 'LOAD_TOKEN';
export const TOKEN_ERROR = 'TOKEN_ERROR';
export const REDIRECT_TWO_FACTOR = 'REDIRECT_TWO_FACTOR';
export const REQUEST_SIGN_UP = 'REQUEST_SIGN_UP';
export const RECEIVE_SIGN_UP = 'RECEIVE_SIGN_UP';
export const SIGN_OUT = 'SIGN_OUT';
export const RECEIVE_USER = 'RECEIVE_USER';

export function requestToken() {
  return {
    type: REQUEST_TOKEN
  };
}

export function signOut(refreshFailed: boolean = false) {
  return async (dispatch: any, getState: any) => {
    await fetch(`${process.env.REACT_APP_API_URL}/users/logout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().auth.token
      },
      credentials: 'omit'
    });

    dispatch(signOutDone(refreshFailed));
  };
}

export function signOutDone(refreshFailed: boolean) {
  return {
    type: SIGN_OUT,
    refreshFailed
  };
}

export function receiveToken(token: string) {
  return {
    type: RECEIVE_TOKEN,
    token,
    refreshFailed: undefined
  };
}

function receiveUser(user: any) {
  return {
    type: RECEIVE_USER,
    user
  };
}

function tokenError() {
  return {
    type: TOKEN_ERROR
  };
}

function redirectTwoFactor() {
  return {
    type: REDIRECT_TWO_FACTOR
  };
}

export function fetchToken(
  username: string,
  password: string,
  twoFaCode: string = ''
) {
  return (dispatch: any) => {
    dispatch(requestToken());
    return fetch(`${process.env.REACT_APP_API_URL}/login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'omit',
      body: JSON.stringify({
        username,
        password,
        twoFaCode
      })
    })
      .then(async (response: Response) => {
        if (
          response.status === 403 &&
          (await response.text()).includes('2fa;')
        ) {
          throw new Error('2fa');
        }
        const data = await response.json();
        try {
          fetch(`${process.env.REACT_APP_API_URL}/users/refresh_token`, {
            headers: {
              method: 'GET',
              Authorization: data.Authorization,
              'Content-Type': 'application/json',
              Accepts: 'application/json'
            }
          })
            .then((r: Response) => r.json())
            .catch(() => {})
            .then((refreshResponse: any) => {
              if (!refreshResponse) {
                return dispatch(tokenError());
              }
              localStorage.setItem('@yoursepa/token', data.Authorization);
              localStorage.setItem(
                '@yoursepa/refresh-token',
                refreshResponse.refreshToken
              );
              dispatch(receiveToken(data.Authorization));
              dispatch(loadCurrentUser(data.Authorization));
            });
        } catch (error) {
          dispatch(tokenError());
        }
      })
      .catch((reason: any) => {
        console.log(reason);
        if (reason.message === '2fa') {
          dispatch(redirectTwoFactor());
        } else {
          dispatch(tokenError());
        }
      });
  };
}

export function fetchWithRefreshToken() {
  return (dispatch: any) => {
    console.log(' !@# ');
    return fetch(`${process.env.REACT_APP_API_URL}/users/refresh_token`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'omit',
      body: JSON.stringify({
        refreshToken: sessionStorage.getItem('@yoursepa/refresh-token')
      })
    })
      .then((response) => {
        console.log('123', response);
        if (!response.ok) throw new Error('Not authorized');
        return response.json();
      })
      .then((data: any) => {
        try {
          localStorage.setItem('Authorization', data.Authorization);
          dispatch(receiveToken(data.Authorization));
          dispatch(loadCurrentUser(data.Authorization));
        } catch (error) {
          dispatch(signOut(true));
        }
      })
      .catch(() => {
        dispatch(signOut(true));
      });
  };
}

export function loadCurrentUser(token: string) {
  return (dispatch: any) => {
    return apiGet('/users/current', token)
      .then((r: any) => r.json())
      .then((response: any) => {
        dispatch(receiveUser(response));
      });
  };
}

function syncLogout(event: any) {
  if (event.key === 'logout') {
    console.log('logged out from storage!');
  }
}

window.addEventListener('storage', syncLogout);
