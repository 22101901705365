import React from 'react';
import ModalDialog from '../../utils/ModalDialog';
import { Box, Typography } from '@material-ui/core';
import text from '../../../language/text';

import ConfirmButton from '../../utils/buttons/ConfirmButton';

import styles from '../../../assets/cssModules/creditActionModal.module.css';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';

// import {downloadXml} from "../../../reducers/dashboardActions";

interface IProps {
  open: boolean;
  handleClose: () => void;
  handleCancel: () => void;
  sepaPrice: number;
}

function CreditActionModal(props: IProps) {
  const { handleClose, handleCancel } = props;

  return (
    <ModalDialog
      open={props.open}
      onClose={props.handleClose}
      title={text.modals.creditAction.title}
      actions={[
        <List>
          <ListItem>
            <ConfirmButton
              buttonStyle={'addCreditBtn'}
              onClick={handleClose}
              text={text.modals.creditAction.agree}
            />
          </ListItem>
          <ListItem style={{ display: 'flex', justifyContent: 'center' }}>
            <Box>
              <Button className={styles.CancelBtn} onClick={handleCancel}>
                {text.modals.creditAction.disagree}
              </Button>
            </Box>
          </ListItem>
          <ListItem></ListItem>
        </List>
      ]}>
      <Typography>
        {text.modals.creditAction.subTextPart1}
        {props.sepaPrice}
        {text.modals.creditAction.subTextPart2}
      </Typography>
    </ModalDialog>
  );
}

function mapStateToProps(state: any) {
  console.log('state', state);
  const { auth } = state;
  const { sepaPrice } = auth.user || {
    sepaPrice: 0
  };
  return {
    sepaPrice
  };
}

export default connect(mapStateToProps)(CreditActionModal);
