import React, { Fragment } from 'react';
import { PageTitle } from '../template/layout-components';
import text from '../language/text';
import { connect } from 'react-redux';
import UserTable from '../components/admin/users/UserTable';
import { Grid } from '@material-ui/core';
import DownloadCustomerData from '../components/admin/users/DownloadCustomerData';

function AdminPage() {
  return (
    <Fragment>
      <PageTitle titleHeading={text.pages.admin.title} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <UserTable />
        </Grid>
        <Grid item xs={12}>
          <DownloadCustomerData />
        </Grid>
      </Grid>
    </Fragment>
  );
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps)(AdminPage);
