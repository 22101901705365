import React, { Fragment } from 'react';
import { Container, Grid } from '@material-ui/core';
import LoginForm from '../components/forms/LoginForm';
import styles from '../assets/cssModules/login.module.scss';

export default function LoginPage() {
  return (
    <Fragment>
      <div
        className={
          styles.Background +
          ' ' +
          'flex-grow-1 w-100 h-100 d-flex align-items-center'
        }>
        <div className="bg-composed-wrapper--content pt-5 pb-2 py-lg-5">
          <Container maxWidth="md" className="pd-5">
            <Grid container spacing={4} style={{ justifyContent: 'center' }}>
              <Grid item lg={6}>
                <LoginForm />
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </Fragment>
  );
}
