import React, { Fragment } from 'react';

import { connect } from 'react-redux';
import { Box, Grid } from '@material-ui/core';
import styles from '../assets/cssModules/changePages.module.scss';
import InviteFriendForm from '../components/forms/InviteFriendForm';
import { Link, useParams } from 'react-router-dom';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import text from '../language/text';

function InviteFriendPage() {
  const { locale } = useParams<any>();

  //functie moet nog worden aangevuld..
  const handleSubmit = (values: any) => {
    console.log('values', values);
  };

  return (
    <Fragment>
      <Grid container spacing={2} className={styles.WhiteBoxContainer}>
        <Grid item xs={11} className={styles.WhiteBox}>
          <Box>
            <Link to={`/${locale}/Dashboard`} className={styles.ToDashboard}>
              <ChevronLeftIcon style={{ color: '#0055ff' }} fontSize="large" />{' '}
              {text.basic.backToDashboard}
            </Link>
          </Box>
          <Grid container justify={'center'}>
            <Grid item lg={6}>
              <InviteFriendForm onSubmit={handleSubmit} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps)(InviteFriendPage);
