import React, { Component } from 'react';
import { Backdrop, CircularProgress, withStyles } from '@material-ui/core';

interface IProps {
  classes: any;
  open: boolean;
  inElement?: boolean;
}
interface IState {}

class Loader extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, open, inElement } = this.props;
    const style = inElement ? { position: 'absolute' as 'absolute' } : {};
    return (
      <Backdrop className={classes.backdrop} open={open} style={style}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
}

const useStyles = (theme: any) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
});

export default withStyles(useStyles)(Loader);
