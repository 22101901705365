import React from 'react';
import {
  TextField,
  FormControlLabel,
  Checkbox,
  FormControl,
  RadioGroup,
  Radio
} from '@material-ui/core';
import SelectField from './SelectField';

export const renderTextField = ({
  label,
  input,
  meta: { touched, error },
  ...custom
}) => (
  <TextField
    label={label}
    placeholder={label}
    error={touched && error !== undefined}
    helperText={
      custom.errortext && touched && error !== undefined ? error : undefined
    }
    {...input}
    {...custom}
  />
);

export const renderCheckbox = ({ input, label }) => (
  <div>
    <FormControlLabel
      control={
        <Checkbox
          checked={input.value ? true : false}
          onChange={input.onChange}
        />
      }
      label={label}
    />
  </div>
);

export const radioButton = ({ input, ...rest }) => (
  <FormControl>
    <RadioGroup {...input} {...rest}>
      <FormControlLabel value="female" control={<Radio />} label="Female" />
      <FormControlLabel value="male" control={<Radio />} label="Male" />
      <FormControlLabel value="other" control={<Radio />} label="Other" />
    </RadioGroup>
  </FormControl>
);

export const renderSelectField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => (
  <SelectField
    label={label}
    {...input}
    {...custom}
    fullWidth
    error={touched && error !== undefined}
  />
);
