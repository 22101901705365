import { combineReducers } from 'redux';
import { RECEIVE_SAVE_USER, REQUEST_SAVE_USER, SAVE_USER_ERROR } from './types';

const isFetchingReducer = (state: any = false, action: any) => {
  switch (action.type) {
    case REQUEST_SAVE_USER:
      return true;
    case RECEIVE_SAVE_USER:
      return false;
    default:
      return state;
  }
};

const errorReducer = (state: any = null, action: any) => {
  switch (action.type) {
    case SAVE_USER_ERROR:
      return action.error;
    case REQUEST_SAVE_USER:
      return null;
    default:
      return state;
  }
};

const successReducer = (state: any = false, action: any) => {
  switch (action.type) {
    case RECEIVE_SAVE_USER:
      return true;
    case REQUEST_SAVE_USER:
    case SAVE_USER_ERROR:
      return false;
    default:
      return state;
  }
};

const reducer = combineReducers({
  isFetching: isFetchingReducer,
  error: errorReducer,
  success: successReducer
});

export default reducer;
