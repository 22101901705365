import React, { Fragment } from 'react';
import { TextField as MaterialField } from '@material-ui/core';

export const renderTextField = ({
  label,
  input,
  meta: { touched, invalid, error },
  ...custom
}: any) => (
  <TextField
    label={label}
    placeholder={label}
    error={touched && invalid}
    helperText={touched && error}
    {...input}
    {...custom}
  />
);

function TextField(props: any) {
  const { children, label, value, onChange } = props;
  return (
    <Fragment>
      <MaterialField
        fullWidth
        className="m-2"
        label={label}
        value={value || ''}
        onChange={onChange}
        variant="outlined"
        {...props}>
        {children}
      </MaterialField>
    </Fragment>
  );
}

export default TextField;
