import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import UserRow from './UserRow';
import { fetchUsers } from '../../../reducers/userTable/UserTableActions';

interface IProps {
  dispatch: any;
  isFetching: boolean;
  errorMessage: string;
  success: boolean;
  users: any[];
}

function UserTable(props: IProps) {
  const { dispatch } = props;

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  return (
    <Fragment>
      <table className="table table-hover text-nowrap mb-0 ">
        <thead className="thead-light">
          <tr>
            <th>email</th>
            <th>role</th>
            <th>email confirmed</th>
          </tr>
        </thead>
        <tbody>
          {props.users
            ? props.users.map((user: any, index: any) => (
                <UserRow key={index} user={user} />
              ))
            : null}
        </tbody>
      </table>
    </Fragment>
  );
}

function mapStateToProps(state: any) {
  const { userTable } = state;
  const { isFetching, error, success, users } = userTable || {
    isFetching: false,
    error: null,
    success: false,
    users: []
  };
  return {
    isFetching,
    errorMessage: error,
    success,
    users
  };
}

export default connect(mapStateToProps)(UserTable);
