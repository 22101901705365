import {
  closeModal,
  receiveUploadXml,
  requestUploadXml,
  uploadXmlError
} from './actions';
import { apiPostFile } from '../../../../utils/api';
import text from '../../../../language/text';
import { setSepa } from '../../../../reducers/sepaActions';

const uploadXml = (file: any) => (dispatch: any, getState: any) => {
  dispatch(requestUploadXml());

  apiPostFile('/sepa/xml', file, getState().auth.token)
    .then((r: any) => r.json())
    .then((response: any) => {
      dispatch(receiveUploadXml());
      dispatch(closeModal());
      dispatch(setSepa(response));
    })
    .catch(() => {
      dispatch(uploadXmlError(text.modals.importXml.fileError));
    });
};

export { uploadXml };
