import React, { useEffect, useState } from 'react';
import { Box, Divider, Typography } from '@material-ui/core';
import { apiGet } from '../../../utils/api';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import moment from 'moment';

function UserLogins({ userId, token }: any) {
  const [logins, setLogins] = useState<any>([
    {
      browser: 'Firefox',
      device: 'Computer',
      os: 'Mac',
      loggedIn: '2021-05-13 21:33:57.342000'
    },
    {
      browser: 'Firefox',
      device: 'Computer',
      os: 'Mac',
      loggedIn: '2021-05-13 21:33:57.342000'
    },
    {
      browser: 'Firefox',
      device: 'Computer',
      os: 'Mac',
      loggedIn: '2021-05-13 21:33:57.342000'
    }
  ]);
  const [message, setMessage] = useState('Not logged in yet');

  useEffect(() => {
    apiGet(`/users/${userId}/logins`, token)
      .then(async (response: any) => {
        if (response.ok) {
          const logins = await response.json();
          setLogins(logins);
          setMessage('');
        }
      })
      .catch(() => {
        setMessage('Could not fetch logins');
      });
  }, [userId, token]);

  return (
    <Box>
      <Typography variant="h3" className={'my-2'}>
        Logins
      </Typography>
      <Divider />
      <Typography>{message}</Typography>
      <Box height={'300px'} overflow={'scroll'} marginTop={1}>
        {logins
          .sort((a: any, b: any) =>
            moment(a.loggedIn).isBefore(moment(b.loggedIn))
          )
          .map((login: any) => (
            <Box display={'flex'} paddingTop={2}>
              <Box paddingRight={3}>
                <Moment date={login.loggedIn} format={'HH:mm:ss DD/MM/YYYY'} />
              </Box>
              <Box paddingRight={3}>{login.device}</Box>
              <Box paddingRight={3}>{login.browser}</Box>
              <Box paddingRight={3}>{login.os}</Box>
            </Box>
          ))}
      </Box>
    </Box>
  );
}

function mapStateToProps(state: any) {
  const { token } = state.auth;
  return { token };
}

export default connect(mapStateToProps)(UserLogins);
