import { apiGet } from '../../utils/api';
import text from '../../language/text';

export const REQUEST_USERS = 'REQUEST_USERS';
export const RECEIVE_USERS = 'RECEIVE_USERS';
export const USERS_ERROR = 'USERS_ERROR';

export function requestUsers() {
  return {
    type: REQUEST_USERS
  };
}

export function receiveUsers(users: any[]) {
  return {
    type: RECEIVE_USERS,
    users
  };
}

export function usersError(error: string) {
  return {
    type: USERS_ERROR,
    error
  };
}

export function fetchUsers() {
  return (dispatch: any, getState: any) => {
    dispatch(requestUsers());

    apiGet('/users', getState().auth.token)
      .then((r: any) => r.json())
      .then((response: any) => {
        dispatch(receiveUsers(response));
      })
      .catch((reason: any) => {
        console.error(reason);
        dispatch(usersError(text.forms.forgotPasswordForm.error));
      });
  };
}
