import React from 'react';
import { connect } from 'react-redux';
import { Button, Grid, Typography } from '@material-ui/core';
import { reduxForm } from 'redux-form';
import text from '../../../language/text';
import Loader from '../../utils/Loader';
import DynamicForm from '../../utils/DynamicForm';

interface IProps {
  dispatch: any;
  isFetching: boolean;
  errorMessage: string;
  success: boolean;
  hideSubmit?: boolean;
  initialValues?: any;
}

function ChangeSepaDefaultsForm(props: IProps & any) {
  const { handleSubmit, errorMessage, isFetching, success } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Typography color="error">{errorMessage}</Typography>
      <Typography color="primary">
        {success && text.forms.changeAccount.success}
      </Typography>
      <Loader open={isFetching} inElement />
      <Grid container spacing={4}>
        <div className="m-3" style={{ width: '100%', paddingRight: '1rem' }}>
          <DynamicForm
            handleSubmit={handleSubmit}
            fields={[
              {
                name: 'company',
                label: text.fields.sepa.companyName
              },
              {
                name: 'iban',
                label: text.fields.sepa.iban
              },
              {
                name: 'bic',
                label: text.fields.sepa.bic
              },
              {
                name: 'creditorId',
                label: text.fields.sepa.creditorId
              }
            ]}
            outlined
          />
          {!props.hideSubmit && (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ float: 'right', marginTop: '1rem' }}>
              {text.forms.changeAccount.save}
            </Button>
          )}
        </div>
      </Grid>
    </form>
  );
}

function mapStateToProps(state: any, props: any) {
  const { changeSepaDefaults } = state.components.forms;
  const { isFetching, error, success } = changeSepaDefaults || {
    isFetching: false,
    error: null,
    success: false
  };
  return {
    isFetching,
    errorMessage: error,
    success,
    initialValues: props.initialValues ? props.initialValues : state.auth.user
  };
}

export default connect(mapStateToProps)(
  reduxForm<{}, IProps>({
    form: 'ChangeSepaDefaultsForm'
  })(ChangeSepaDefaultsForm)
);
