import {
  addCreditError,
  receiveAddCredit,
  receiveCanceled,
  receivePaid,
  requestAddCredit
} from './actions';
import text from '../../../../language/text';
import { apiGet, apiPost } from '../../../../utils/api';
import { loadCurrentUser } from '../../../../reducers/authActions';
import { formatMoney } from '../../../../utils/formatters';

const addCredit = (value: any) => (dispatch: any, getState: any) => {
  dispatch(requestAddCredit());

  if (isNaN(Number(value))) {
    dispatch(addCreditError(text.modals.addCredit.errors.valueFormat));
    return;
  } else if (Number(value) < getState().auth.user.minimumPrepaidPrice) {
    dispatch(
      addCreditError(
        text.modals.addCredit.errors.valueToLow.replace(
          '%s',
          formatMoney(getState().auth.user.minimumPrepaidPrice)
        )
      )
    );
    return;
  }

  apiPost(
    '/users/addCredit',
    {
      value
    },
    getState().auth.token
  )
    .then((r: any) => r.json())
    .then((response: any) => {
      console.log('response', response);
      dispatch(receiveAddCredit());
      var win = window.open(response.checkoutUrl, '_blank');
      win?.focus();

      dispatch(checkStatus(response.id));
    })
    .catch(() => {
      dispatch(addCreditError(text.modals.addCredit.errors.unknown));
    });
};

const checkStatus = (id: number) => {
  return (dispatch: any, getState: any) => {
    const interval = setInterval(() => {
      apiGet(`/payments/${id}/status`, getState().auth.token)
        .then((r: any) => r.text())
        .then((response: any) => {
          if (response === 'paid') {
            dispatch(receivePaid());
            dispatch(loadCurrentUser(getState().auth.token));
            clearInterval(interval);
          } else if (response !== 'open') {
            dispatch(receiveCanceled());
            dispatch(
              addCreditError(text.modals.addCredit.errors.paymentFailed)
            );
            clearInterval(interval);
          }
        })
        .catch(() => {
          clearInterval(interval);
          dispatch(receiveCanceled());
        });
    }, 5000);
  };
};

export { addCredit };
