import {
  REQUEST_CHANGE_PASSWORD,
  RECEIVE_CHANGE_PASSWORD,
  CHANGE_PASSWORD_ERROR
} from './settingsActions';

function settings(
  state = {
    isFetching: false,
    success: null
  },
  action: any
) {
  switch (action.type) {
    case REQUEST_CHANGE_PASSWORD:
      return {
        ...state,
        isFetching: true,
        error: null,
        success: null
      };
    case RECEIVE_CHANGE_PASSWORD:
      return {
        ...state,
        isFetching: false,
        error: null,
        success: action.success
      };
    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        success: null
      };

    default:
      return state;
  }
}

export default settings;
