import React, { Fragment, useRef } from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import text from '../../../language/text';
import ModalDialog from '../../utils/ModalDialog';
import Loader from '../../utils/Loader';
import { uploadXml } from './ducks/operations';
import { closeModal, openModal, uploadXmlError } from './ducks/actions';
import BigButton from '../../utils/buttons/BigButton';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';

interface IProps {
  dispatch: any;
  isFetching: boolean;
  error: string;
  open: boolean;
}

function ImportXmlModal(props: IProps) {
  const { dispatch, isFetching, open, error } = props;

  const fileInput = useRef<HTMLInputElement>(null);

  const handleImportXml = () => {
    dispatch(openModal());
  };

  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleUpload = () => {
    if (
      fileInput !== null &&
      fileInput.current !== null &&
      fileInput.current.files !== null &&
      fileInput.current.files.length === 1
    ) {
      dispatch(uploadXml(fileInput.current.files[0]));
    } else {
      dispatch(uploadXmlError(text.modals.importXml.fileError));
    }
  };

  return (
    <Fragment>
      <BigButton
        onClick={handleImportXml}
        text={text.pages.dashboard.components.templateButtons.existingXml}
        icon={<OpenInBrowserIcon style={{ color: '#7a7a7a' }} />}
      />
      <ModalDialog
        open={open}
        onClose={handleClose}
        title={text.pages.dashboard.components.templateButtons.existingXml}
        actions={
          <Button onClick={handleUpload} color="primary">
            {text.pages.dashboard.components.templateButtons.existingXml}
          </Button>
        }>
        <Loader open={isFetching || false} inElement />
        <div>{text.modals.importXml.mainText}</div>
        <div>{error}</div>
        <div>
          <input
            accept=".xml"
            style={{ display: 'none' }}
            id="raised-button-file"
            multiple
            type="file"
            ref={fileInput}
          />
          <label htmlFor="raised-button-file">
            <Button
              component="span"
              variant="outlined"
              color="primary"
              className="mt-3">
              Upload
            </Button>
          </label>
        </div>
      </ModalDialog>
    </Fragment>
  );
}

function mapStateToProps(state: any) {
  const { components } = state || {
    components: {
      modals: {
        importXmlModal: {
          isFetching: false,
          open: false,
          error: null
        }
      }
    }
  };
  const { modals } = components;
  const { importXmlModal } = modals;
  const { isFetching, error, open } = importXmlModal;

  return {
    isFetching,
    error,
    open
  };
}

export default connect(mapStateToProps)(ImportXmlModal);
