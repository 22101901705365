import {
  REQUEST_REGISTER,
  RECEIVE_REGISTER,
  REGISTER_ERROR
} from './RegisterActions';

function register(
  state = {
    isFetching: false,
    error: null,
    success: false
  },
  action: any
) {
  switch (action.type) {
    case REQUEST_REGISTER:
      return {
        ...state,
        isFetching: true,
        error: null,
        success: false
      };
    case RECEIVE_REGISTER:
      return {
        ...state,
        isFetching: false,
        error: null,
        success: true
      };
    case REGISTER_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        success: false
      };

    default:
      return { ...state };
  }
}

export default register;
