import {
  RECEIVE_SAVE_SEPA_DEFAULTS,
  REQUEST_SAVE_SEPA_DEFAULTS,
  SAVE_SEPA_DEFAULTS_ERROR
} from './types';

const requestSaveSepaDefaults = () => ({
  type: REQUEST_SAVE_SEPA_DEFAULTS
});

const receiveSaveSepaDefaults = () => ({
  type: RECEIVE_SAVE_SEPA_DEFAULTS
});

const saveSepaDefaultsError = (error: string) => ({
  type: SAVE_SEPA_DEFAULTS_ERROR,
  error
});

export {
  requestSaveSepaDefaults,
  receiveSaveSepaDefaults,
  saveSepaDefaultsError
};
