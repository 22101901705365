import {
  CLOSE_MODAL,
  OPEN_MODAL,
  RECEIVE_SAVE_USER,
  REQUEST_SAVE_USER,
  SAVE_USER_ERROR
} from './types';
import { combineReducers } from 'redux';

const openReducer = (state: any = false, action: any) => {
  switch (action.type) {
    case OPEN_MODAL:
      return true;
    case CLOSE_MODAL:
      return false;
    default:
      return state;
  }
};

const isFetchingReducer = (state: any = false, action: any) => {
  switch (action.type) {
    case REQUEST_SAVE_USER:
      return true;
    case RECEIVE_SAVE_USER:
    case SAVE_USER_ERROR:
    case CLOSE_MODAL:
      return false;
    default:
      return state;
  }
};

const isCheckingReducer = (state: any = false, action: any) => {
  switch (action.type) {
    case RECEIVE_SAVE_USER:
      return true;
    case CLOSE_MODAL:
      return false;
    default:
      return state;
  }
};

const successReducer = (state: any = false, action: any) => {
  switch (action.type) {
    case RECEIVE_SAVE_USER:
      return true;
    case CLOSE_MODAL:
      return false;
    default:
      return state;
  }
};

const errorReducer = (state: any = null, action: any) => {
  switch (action.type) {
    case SAVE_USER_ERROR:
      return action.error;
    case REQUEST_SAVE_USER:
    case CLOSE_MODAL:
      return null;
    default:
      return state;
  }
};

const reducer = combineReducers({
  open: openReducer,
  isFetching: isFetchingReducer,
  error: errorReducer,
  isChecking: isCheckingReducer,
  success: successReducer
});

export default reducer;
