import {
  RECEIVE_RESET_PASSWORD,
  REQUEST_RESET_PASSWORD,
  RESET_PASSWORD_ERROR
} from './ResetPasswordActions';

function ResetPassword(
  state = {
    isFetching: false,
    error: null,
    success: false
  },
  action: any
) {
  switch (action.type) {
    case REQUEST_RESET_PASSWORD:
      return {
        ...state,
        isFetching: true,
        error: null,
        success: false
      };
    case RECEIVE_RESET_PASSWORD:
      return {
        ...state,
        isFetching: false,
        error: null,
        success: true
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        success: false
      };

    default:
      return { ...state };
  }
}

export default ResetPassword;
