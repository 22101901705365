import { apiPost } from '../../utils/api';
import { reset } from 'redux-form';
import text from '../../language/text';

export const REQUEST_REGISTER = 'REQUEST_REGISTER';
export const RECEIVE_REGISTER = 'RECEIVE_REGISTER';
export const REGISTER_ERROR = 'REGISTER_ERROR';

export function requestRegister() {
  return {
    type: REQUEST_REGISTER
  };
}

export function receiveRegister() {
  return {
    type: RECEIVE_REGISTER
  };
}

export function registerError() {
  return {
    type: REGISTER_ERROR,
    error: text.forms.registerForm.error
  };
}

export function register(
  username: string,
  firstName: string,
  surnamePrefix: string,
  lastName: string,
  companyName: string,
  address: string,
  postalCode: string,
  country: string,
  password: string
) {
  return (dispatch: any) => {
    dispatch(requestRegister());

    apiPost(
      '/users/sign-up',
      {
        username,
        firstName,
        surnamePrefix,
        lastName,
        companyName,
        password,
        address,
        postalCode,
        country
      },
      ''
    )
      .then(() => {
        dispatch(receiveRegister());
        dispatch(reset('RegisterForm'));
      })
      .catch((reason: any) => {
        console.error(reason);
        dispatch(registerError());
      });
  };
}
