import React, { Fragment } from 'react';
import { PageTitle } from '../template/layout-components';
import text from '../language/text';
import { Grid } from '@material-ui/core';
import UserDetails from '../components/admin/users/UserDetails';

interface IProps {
  match?: any;
}

function UserDetailPage(props: IProps) {
  const { match } = props;
  return (
    <Fragment>
      <PageTitle titleHeading={text.pages.admin.title} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <UserDetails userId={match.params.userId} />
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default UserDetailPage;
