import userDetails from './ducks';
import UserPayments from './UserPayments';
import { combineReducers } from 'redux';

const reducers = combineReducers({
  details: userDetails,
  UserPayments
});

export default reducers;
