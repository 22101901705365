import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import ForgotPasswordValidator from './ForgotPasswordValidator';
import text from '../../../language/text';
import { Link, useParams } from 'react-router-dom';
import { Typography } from '@material-ui/core';

import Loader from '../../utils/Loader';
import styles from '../../../assets/cssModules/login.module.scss';
import ConfirmButton from '../../utils/buttons/ConfirmButton';
import DynamicForm from '../../utils/DynamicForm';

interface IProps {
  dispatch: any;
  isFetching: boolean;
  errorMessage: string;
  success: string;
}

function ForgotPasswordForm(props: IProps & any) {
  const { handleSubmit, errorMessage, isFetching, success } = props;

  const { locale } = useParams<any>();

  const PressedEnter = (event: any) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };

  return (
    <Fragment>
      <div className={styles.CenterLoginContainer}>
        <div className={styles.LoginContainer + ' text-center'}>
          <h1 className={styles.LoginHeader}>
            {text.forms.forgotPasswordForm.title}
          </h1>
          <form onSubmit={handleSubmit} onKeyPress={PressedEnter}>
            <Typography id={'ErrorMessage'} color="error">
              {errorMessage}
            </Typography>
            <Typography id={'SuccessMessage'} color="primary">
              {success && text.forms.forgotPasswordForm.success}
            </Typography>
            <Loader open={isFetching} />
            <DynamicForm
              handleSubmit={handleSubmit}
              fields={[
                {
                  id: 'email',
                  name: 'email',
                  label: text.fields.email,
                  required: true
                }
              ]}
              outlined={true}
            />
            <div className="text-center">
              <ConfirmButton
                id={'ForgotPasswordButton'}
                buttonStyle={'SignInBtn'}
                onClick={handleSubmit}
                text={text.forms.forgotPasswordForm.sendMail}
              />
              <Link to={`/${locale}/login`} className={styles.OtherOptionText}>
                {text.forms.registerForm.goLogin}
              </Link>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
}

function mapStateToProps(state: any) {
  const { forgotPassword } = state;
  const { isFetching, error, success } = forgotPassword || {
    isFetching: false,
    error: null,
    success: false
  };
  return {
    isFetching,
    errorMessage: error,
    success
  };
}

export default connect(mapStateToProps)(
  reduxForm<{}, IProps>({
    form: 'ForgotPasswordForm',
    validate: ForgotPasswordValidator
  })(ForgotPasswordForm)
);
