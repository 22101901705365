import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, Grid } from '@material-ui/core';

import ForgotPasswordForm from '../components/forms/forgotPassword/ForgotPasswordForm';
import { requestNewPassword } from '../reducers/forgotPassword/ForgotPasswordActions';

import styles from '../assets/cssModules/login.module.scss';

interface IProps {
  dispatch: any;
  isFetching: boolean;
  error: string;
}

function ForgotPasswordPage(props: IProps) {
  const { dispatch } = props;

  const handleSubmit = (values: any) => {
    console.log('values', values);
    dispatch(requestNewPassword(values.email));
  };

  return (
    <Fragment>
      <div
        className={
          styles.Background +
          ' ' +
          'flex-grow-1 w-100 h-100 d-flex align-items-center'
        }>
        <div className="bg-composed-wrapper--content pt-5 pb-2 py-lg-5">
          <Container maxWidth="md" className="pd-5">
            <Grid container spacing={4} style={{ justifyContent: 'center' }}>
              <Grid item lg={6}>
                <ForgotPasswordForm onSubmit={handleSubmit} />
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </Fragment>
  );
}

function mapStateToProps(state: any) {
  const { CHANGE_TO_REDUCER_NAME } = state;
  const { isFetching, error } = CHANGE_TO_REDUCER_NAME || {
    isFetching: false,
    error: null
  };
  return {
    isFetching,
    error
  };
}

export default connect(mapStateToProps)(ForgotPasswordPage);
