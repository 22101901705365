import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface IButtonProps {
  id?: any;
  color?: 'warning' | 'none' | 'primary';
  fullWidth?: boolean;
  onClick: any;
  text: string;
  type?: string;
}

const useStyles = makeStyles((theme: any) => ({
  basic: {
    borderRadius: '35px',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    boxShadow: 'none',
    fontWeight: 500,
    minWidth: theme.spacing(15),
    '&:hover': {
      boxShadow: 'none'
    }
  },
  none: {
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.main,
    border: 'solid 1px ' + theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.light
    }
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    }
  },
  warning: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.light
    }
  }
}));

function BasicButton(props: IButtonProps) {
  const { onClick, text } = props;

  const classes = useStyles();

  let color = null;
  switch (props.color) {
    case 'warning':
      color = classes.warning;
      break;
    case 'primary':
      color = classes.primary;
      break;
    case 'none':
    case undefined:
      color = classes.none;
      break;
  }

  return (
    <Button
      id={props.id}
      variant={'contained'}
      onClick={onClick}
      fullWidth={props.fullWidth !== false}
      className={`${classes.basic} ${color}`}
      type={props.type === 'submit' ? 'submit' : 'button'}>
      {text}
    </Button>
  );
}

export default BasicButton;
