import React from 'react';
import BasicButton from './BasicButton';

interface IProps {
  onClick?: any;
  text: string;
  buttonStyle?: string;
  fullWidth?: boolean;
  type?: string;
}

const SubmitButton = (props: IProps) => {
  return (
    <BasicButton
      onClick={props.onClick}
      text={props.text}
      color={'primary'}
      fullWidth={props.fullWidth}
    />
  );
};

export default SubmitButton;
