import React from 'react';
import ModalDialog from '../../utils/ModalDialog';
import { Typography, LinearProgress } from '@material-ui/core';
import text from '../../../language/text';
interface IProps {
  title: string;
}

function ProgressModal(props: IProps) {
  return (
    <ModalDialog
      open={true}
      onClose={() => {}}
      title={props.title}
      actions={[]}>
      <Typography>{text.modals.addCredit.checkStatus}</Typography>
      <LinearProgress value={25} className="mb-3" />
    </ModalDialog>
  );
}

export default ProgressModal;
