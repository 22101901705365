import React, { useState } from 'react';
import { apiPost } from '../../../utils/api';
import { fetchUser } from './ducks/operations';
import { Box, Typography } from '@material-ui/core';
import ConfirmButton from '../../utils/buttons/ConfirmButton';
import EditUserModal from '../../modals/EditUserModal/EditUserModal';
import { connect } from 'react-redux';
import text from '../../../language/text';
import DeleteUserModal from '../../modals/EditUserModal/DeleteUserModal';

function UserActions(props: any) {
  const { dispatch, user, token } = props;
  const [savedMessage, setSavedMessage] = useState<any>('');

  const changeSubscription = (role: 'FREE' | 'NORMAL') => {
    apiPost(
      `/users/${user.id}`,
      {
        ...user,
        role
      },
      token
    )
      .then((response: any) => {
        if (response.ok) {
          setSavedMessage(
            role === 'FREE'
              ? 'User subscription removed'
              : 'User set to subscription!'
          );
          dispatch(fetchUser(user.id));
        } else {
          setSavedMessage('Something went wrong');
        }
      })
      .catch(() => {
        setSavedMessage('Something went wrong');
      });
  };

  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'space-between'}>
      <Box display={'flex'} justifyContent={'center'}>
        <h4 className="font-size-lg font-weight-bold">
          {text.pages.admin.components.userDetails.title}
        </h4>
        <Typography>{savedMessage}</Typography>
      </Box>
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}>
        <Box marginRight={2}>
          {user.role === 'FREE' ? (
            <ConfirmButton
              text={'Set to subscription'}
              fullWidth={false}
              onClick={() => {
                changeSubscription('NORMAL');
              }}
            />
          ) : (
            <ConfirmButton
              text={'Cancel subscription'}
              fullWidth={false}
              onClick={() => {
                changeSubscription('FREE');
              }}
            />
          )}
        </Box>
        <Box marginRight={2}>
          <EditUserModal user={user} />
        </Box>
        <Box>
          <DeleteUserModal user={user} />
        </Box>
      </Box>
    </Box>
  );
}

function mapStateToProps(state: any) {
  const { token } = state.auth;
  return {
    token
  };
}

export default connect(mapStateToProps)(UserActions);
