import { Box } from '@material-ui/core';
import React, { Fragment } from 'react';
import styles from '../../../assets/cssModules/dashboardPage.module.css';
import BigBtnStyles from './bigButton.module.css';
import inviteFriendStyles from '../../../assets/cssModules/changePages.module.scss';

interface IProps {
  onClick: () => void;
  text?: String;
  icon?: any;
  alternativeText?: string;
  inviteOpenedCount?: number;
  inviteAccountsCreatedCount?: number;
  invitesSentCount?: number;
}

function BigButton(props: IProps) {
  const { onClick } = props;

  return (
    <Fragment>
      <Box
        border={1}
        borderRadius={5}
        borderColor="grey.400"
        className={BigBtnStyles.BigButtons}
        onClick={onClick}>
        <div>
          <div>{props.icon}</div>

          <Box className={inviteFriendStyles.InviteFriendBtnCount}>
            <div>{props.inviteOpenedCount}</div>
            <div>{props.inviteAccountsCreatedCount}</div>
            <div>{props.invitesSentCount}</div>
          </Box>

          <div
            className={styles.SepaComponentBtnsText + ' ' + styles.ImportBtns}>
            {props.text}
          </div>
          <div className={inviteFriendStyles.InviteFriendBtnText}>
            {props.alternativeText}
          </div>
        </div>
      </Box>
    </Fragment>
  );
}

export default BigButton;
