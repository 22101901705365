import text from '../language/text';

export const REQUEST_CHANGE_PASSWORD = 'REQUEST_CHANGE_PASSWORD';
export const RECEIVE_CHANGE_PASSWORD = 'RECEIVE_CHANGE_PASSWORD';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';

export function requestChangePassword() {
  return {
    type: REQUEST_CHANGE_PASSWORD
  };
}

export function receiveChangePassword() {
  return {
    type: RECEIVE_CHANGE_PASSWORD,
    success: text.forms.changePasswordForm.success
  };
}

function changePasswordError(error: string) {
  return {
    type: CHANGE_PASSWORD_ERROR,
    error
  };
}

export function changePassword(
  oldPassword: string,
  newPassword1: string,
  newPassword2: string
) {
  return (dispatch: any, getState: any) => {
    dispatch(requestChangePassword());
    if (newPassword1 !== newPassword2) {
      dispatch(
        changePasswordError(text.forms.changePasswordForm.passwordsNotEqual)
      );
      return;
    }
    return fetch(`${process.env.REACT_APP_API_URL}/users/changepassword`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().auth.token
      },
      credentials: 'omit',
      body: JSON.stringify({
        oldPassword,
        newPassword: newPassword1
      })
    })
      .then((response: Response) => {
        if (!response.ok) {
          throw Error();
        }
        dispatch(receiveChangePassword());
      })
      .catch(() => {
        dispatch(
          changePasswordError(text.forms.changePasswordForm.passwordError)
        );
      });
  };
}
