import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducers';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createLogger } from 'redux-logger';

const loggerMiddleware = createLogger();

const configureStore = (preloadedState: any) => {
  const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['sepa']
  };

  const pReducer = persistReducer(persistConfig, rootReducer);

  let store = createStore(
    pReducer,
    preloadedState,
    applyMiddleware(thunkMiddleware, loggerMiddleware)
  );
  let persistor = persistStore(store);

  return { store, persistor };
};

export default configureStore;
