import { apiPost } from '../../utils/api';
import text from '../../language/text';
import { reset } from 'redux-form';

export const REQUEST_FORGOT_PASSWORD = 'REQUEST_FORGOT_PASSWORD';
export const RECEIVE_FORGOT_PASSWORD = 'RECEIVE_FORGOT_PASSWORD';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';

export function requestForgotPassword() {
  return {
    type: REQUEST_FORGOT_PASSWORD
  };
}

export function receiveForgotPassword() {
  return {
    type: RECEIVE_FORGOT_PASSWORD
  };
}

export function forgotPasswordError(error: string) {
  return {
    type: FORGOT_PASSWORD_ERROR,
    error
  };
}

export function requestNewPassword(email: string) {
  return (dispatch: any) => {
    dispatch(requestForgotPassword());

    apiPost(
      '/users/forgotpassword',
      {
        username: email
      },
      ''
    )
      .then(() => {
        dispatch(receiveForgotPassword());
        dispatch(reset('ForgotPasswordForm'));
      })
      .catch((reason: any) => {
        console.error(reason);
        dispatch(forgotPasswordError(text.forms.forgotPasswordForm.error));
      });
  };
}
