import React, { Fragment } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Drawer, Hidden, Paper } from '@material-ui/core';
import { connect } from 'react-redux';
import SidebarHeader from '../SidebarHeader';
import SidebarMenu from '../SidebarMenu';
import { setSidebarToggleMobile } from '../../../reducers/ThemeOptions';
import { withRouter } from 'react-router';
import { getSystemInfo } from '../../../utils/GetUserSystemInfo';
import Box from '@material-ui/core/Box';

function Sidebar({
  setSidebarToggleMobile,
  sidebarToggleMobile,
  sidebarFixed,
  sidebarShadow
}: any) {
  const closeDrawer = () => setSidebarToggleMobile(!sidebarToggleMobile);

  const sidebarMenuContent = (
    <div>
      <SidebarMenu
        component="div"
        key={0}
        className="app-sidebar-menu"
        pages={[]}
      />
      <div className="systemInfoHolder">
        <Box style={{ color: 'white' }}>
          ©️ {new Date().getFullYear()} YourSEPA || {getSystemInfo()}
        </Box>
      </div>
    </div>
  );

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={sidebarToggleMobile}
          onClose={closeDrawer}
          variant="temporary"
          elevation={4}
          className="app-sidebar-wrapper-lg">
          <SidebarHeader />
          <PerfectScrollbar>{sidebarMenuContent}</PerfectScrollbar>
        </Drawer>
      </Hidden>

      <Hidden mdDown>
        <Paper
          className={clsx('app-sidebar-wrapper', {
            'app-sidebar-wrapper-fixed': sidebarFixed
          })}
          square
          elevation={sidebarShadow ? 11 : 3}>
          <SidebarHeader />
          <div
            className={clsx({
              'app-sidebar-menu': sidebarFixed
            })}>
            <PerfectScrollbar options={{ wheelPropagation: false }}>
              {sidebarMenuContent}
            </PerfectScrollbar>
          </div>
        </Paper>
      </Hidden>
    </Fragment>
  );
}

const mapStateToProps = ({ themeOptions }: any) => ({
  sidebarFixed: themeOptions.sidebarFixed,
  headerFixed: themeOptions.headerFixed,
  sidebarToggleMobile: themeOptions.sidebarToggleMobile
});

const mapDispatchToProps = (dispatch: any) => ({
  setSidebarToggleMobile: (enable: any) =>
    dispatch(setSidebarToggleMobile(enable))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Sidebar));
