import React, { Fragment } from 'react';

import clsx from 'clsx';

import { Box, IconButton, Tooltip } from '@material-ui/core';

import { connect } from 'react-redux';

import { setSidebarToggleMobile } from '../../../reducers/ThemeOptions';

import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';

const SidebarHeader = ({
  sidebarToggleMobile,
  setSidebarToggleMobile
}: any) => {
  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  return (
    <Fragment>
      <div
        className={clsx('app-sidebar-header', {})}
        style={{ backgroundColor: '#0d0d0d' }}>
        <Box
          className="header-logo-wrapper"
          title="Carolina React Admin Dashboard with Material-UI Free"></Box>
        <Box className="app-sidebar-header-btn-mobile">
          <Tooltip title="Toggle Sidebar" placement="right">
            <IconButton
              style={{ backgroundColor: 'white' }}
              color="inherit"
              onClick={toggleSidebarMobile}
              size="medium">
              {sidebarToggleMobile ? (
                <MenuOpenRoundedIcon />
              ) : (
                <MenuRoundedIcon />
              )}
            </IconButton>
          </Tooltip>
        </Box>
      </div>
    </Fragment>
  );
};
const mapStateToProps = ({ themeOptions }: any) => ({
  sidebarToggleMobile: themeOptions.sidebarToggleMobile
});

const mapDispatchToProps = (dispatch: any) => ({
  setSidebarToggleMobile: (enable: any) =>
    dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarHeader);
