import React from 'react';

import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import styles from '../../assets/cssModules/changePages.module.scss';
import { Link, useParams } from 'react-router-dom';
import { Box } from '@material-ui/core';
import ConfirmButton from '../utils/buttons/ConfirmButton';

interface IProps {
  dispatch: any;
  isFetching: boolean;
  error: string;
  success: string;
}

function NewChangeAccountForm(props: IProps & any) {
  const { handleSubmit } = props;
  const { locale } = useParams<any>();

  return (
    <React.Fragment>
      <span>
        <Link to={`/${locale}/Dashboard`} className={styles.ToDashboard}>
          <ChevronLeftIcon style={{ color: '#0055ff' }} fontSize="large" /> Go
          back to dashboard
        </Link>
      </span>
      <div className={styles.CenterContainer}>
        <div className={styles.ChangePwdContainer}>
          <h1 className={styles.Title}>Change account</h1>
          <p className={styles.Text}>Describe columns for SEPA file</p>
          <Box className={styles.FormBtns} style={{ marginTop: '10%' }}>
            <ConfirmButton
              buttonStyle={'changePasswordBtn'}
              onClick={handleSubmit}
              text="Save"
            />
          </Box>
        </div>
      </div>
    </React.Fragment>
  );
}

function mapStateToProps(state: any) {
  const { settings } = state;
  const { isFetching, error, success } = settings || {
    isFetching: false,
    error: null,
    success: null
  };

  return {
    isFetching,
    error,
    success
  };
}

export default connect(mapStateToProps)(
  reduxForm<{}, IProps>({
    form: 'newChangeAccountForm'
  })(NewChangeAccountForm)
);
