import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core';
import styles from '../../assets/cssModules/addCreditModal.module.css';

interface IProps {
  dispatch: any;
  title: string;
  open: boolean;
  isFetching: boolean;
  error: string;
  onClose: () => void;
  actions: React.ReactNode;
  children: any;
}

const ModalDialog = (props: IProps) => {
  const { open, onClose, title } = props;

  return (
    <Fragment>
      <Dialog
        disableBackdropClick
        className={styles.addCreditModalContainer}
        open={open || false}
        onClose={onClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle disableTypography={true} id="form-dialog-title">
          <h1 className={styles.addCreditModalTitle}>{title}</h1>
        </DialogTitle>
        <DialogContent className={styles.addCreditModalText}>
          {props.children}
        </DialogContent>
        <DialogActions>{props.actions}</DialogActions>
      </Dialog>
    </Fragment>
  );
};

function mapStateToProps(state: any) {
  const { CHANGE_TO_REDUCER_NAME } = state;
  const { isFetching, error } = CHANGE_TO_REDUCER_NAME || {
    isFetching: false,
    error: null
  };
  return {
    isFetching,
    error
  };
}

export default connect(mapStateToProps)(ModalDialog);
