import { apiPost } from '../../utils/api';

export const REQUEST_CONFIRM_EMAIL = 'REQUEST_CONFIRM_EMAIL';
export const RECEIVE_CONFIRM_EMAIL = 'RECEIVE_CONFIRM_EMAIL';
export const CONFIRM_EMAIL_ERROR = 'CONFIRM_EMAIL_ERROR';

export function requestConfirmEmail() {
  return {
    type: REQUEST_CONFIRM_EMAIL
  };
}

export function receiveConfirmEmail() {
  return {
    type: RECEIVE_CONFIRM_EMAIL
  };
}

export function confirmEmailError() {
  return {
    type: CONFIRM_EMAIL_ERROR,
    error: 'ERROR'
  };
}

export function confirmEmail(email: string, token: string) {
  return (dispatch: any) => {
    dispatch(requestConfirmEmail());

    apiPost(
      '/users/confirmemail',
      {
        email,
        token
      },
      ''
    )
      .then(() => {
        dispatch(receiveConfirmEmail());
      })
      .catch(() => {
        dispatch(confirmEmailError());
      });
  };
}
