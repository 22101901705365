import { apiGet, apiPost } from '../utils/api';
import text from '../language/text';

export const REQUEST_SEPA_TEMPLATE = 'REQUEST_SEPA_TEMPLATE';
export const RECEIVE_SEPA_TEMPLATE = 'RECEIVE_SEPA_TEMPLATE';
export const SEPA_TEMPLATE_ERROR = 'SEPA_TEMPLATE_ERROR';
export const RECEIVE_PREVIOUS_COLUMNS = 'RECEIVE_PREVIOUS_COLUMNS';
export const CLEAR_MODAL = 'CLEAR_MODAL';
export const DOWNLOAD_CUSTOMER_DATA = 'DOWNLOAD_CUSTOMER_DATA';

export function requestSepaTemplate() {
  return {
    type: REQUEST_SEPA_TEMPLATE
  };
}

export function requestCustomerData() {
  return {
    type: DOWNLOAD_CUSTOMER_DATA
  };
}

function receiveSepaTemplate() {
  return {
    type: RECEIVE_SEPA_TEMPLATE
  };
}

function receivePreviousColumns(columns: any) {
  return {
    type: RECEIVE_PREVIOUS_COLUMNS,
    columns
  };
}

export function clearModal() {
  return {
    type: CLEAR_MODAL
  };
}

export function sepaTemplateError() {
  return {
    type: SEPA_TEMPLATE_ERROR,
    error: text.basic.error
  };
}

export function downloadSepaTemplate() {
  return (dispatch: any, getState: any) => {
    dispatch(requestSepaTemplate());

    return apiPost('/sepa/template', {}, getState().auth.token)
      .then((r: any) => r.blob())
      .then((response: any) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `your_sepa_template.xlsx`); // 3. Append to html page
        document.body.appendChild(link); // 4. Force download
        link.click(); // 5. Clean up and remove the link
        //@ts-ignore
        link.parentNode.removeChild(link);
        dispatch(receiveSepaTemplate());
      })
      .catch((reason: any) => {
        console.log(console.error(reason));
        dispatch(sepaTemplateError());
      });
  };
}

export function downloadCustomerData() {
  return (dispatch: any, getState: any) => {
    dispatch(requestCustomerData());

    return apiPost('/downloads/customerdata', {}, getState().auth.token)
      .then((r: any) => r.blob())
      .then((response: any) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `CustomerData.xlsx`); // 3. Append to html page
        document.body.appendChild(link); // 4. Force download
        link.click(); // 5. Clean up and remove the link
        //@ts-ignore
        link.parentNode.removeChild(link);
        dispatch(receiveSepaTemplate());
      })
      .catch((reason: any) => {
        console.error(reason);
        dispatch(sepaTemplateError());
      });
  };
}

export function fetchPreviousColumns() {
  return (dispatch: any, getState: any) => {
    dispatch(requestSepaTemplate());
    return apiGet('/sepa/templateSettings', getState().auth.token)
      .then((r: any) => r.json())
      .then((response: any) => {
        dispatch(receivePreviousColumns(response));
      })
      .catch((reason: any) => {
        console.error(reason);
        dispatch(sepaTemplateError());
      });
  };
}
