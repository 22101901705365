import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { closeModal, openModal } from './ducks/actions';
import { Button } from '@material-ui/core';
import ModalDialog from '../../utils/ModalDialog';
import ChangeAccountForm from '../../forms/changeAccountForm/ChangeAccountForm';
import Loader from '../../utils/Loader';
import { getFormValues, submit } from 'redux-form';
import { saveUser } from '../../forms/changeAccountForm/ducks';
import { fetchUser } from '../../admin/users/ducks';
import ChangeSepaDefaultsForm from '../../forms/changeSepaDefaults/ChangeSepaDefaultsForm';
import ConfirmButton from '../../utils/buttons/ConfirmButton';

interface IProps {
  dispatch: any;
  isFetching: boolean;
  errorMessage: string;
  open: boolean;
  success: boolean;
  user: any;
  extraValues: any;
}

function EditUserModal(props: IProps) {
  const { dispatch, open, isFetching } = props;

  const handleOpen = () => {
    dispatch(openModal());
  };

  const handleClose = () => {
    dispatch(closeModal());
    dispatch(fetchUser(props.user.id));
  };

  const handleSubmit = (values: any) => {
    console.log('extraValues', props.extraValues);
    dispatch(
      saveUser({
        ...values,
        iban: props.extraValues.iban,
        bic: props.extraValues.bic,
        company: props.extraValues.company,
        creditorId: props.extraValues.creditorId
      })
    );
  };

  return (
    <Fragment>
      <ConfirmButton
        text={'Edit user'}
        onClick={handleOpen}
        fullWidth={false}
      />
      <ModalDialog
        open={open}
        onClose={handleClose}
        title={'Edit user'}
        actions={[
          <Button onClick={handleClose} color="secondary" key={1}>
            Cancel
          </Button>,
          <Button
            onClick={() => {
              dispatch(submit('ChangeAccountForm'));
            }}
            color="primary"
            variant="contained"
            key={2}>
            Save
          </Button>
        ]}>
        <Loader open={isFetching} inElement />
        <ChangeAccountForm
          onSubmit={handleSubmit}
          hideSubmit
          initialValues={props.user}
        />
        <ChangeSepaDefaultsForm
          onSubmit={handleSubmit}
          hideSubmit
          initialValues={props.user}
        />
        <div className="mb-3"></div>
      </ModalDialog>
    </Fragment>
  );
}

function mapStateToProps(state: any) {
  const { components } = state || {
    components: {
      modals: {
        editUserModal: {
          isFetching: false,
          open: false,
          error: null
        }
      }
    }
  };
  const { modals } = components;
  const { editUserModal } = modals;
  const { isFetching, error, open, success } = editUserModal;
  const v = getFormValues('ChangeSepaDefaultsForm')(state);
  return {
    isFetching,
    errorMessage: error,
    success,
    open,
    extraValues: v
  };
}

export default connect(mapStateToProps)(EditUserModal);
