import { downloadCustomerData } from '../../../reducers/templateActions';
import { Card, Divider } from '@material-ui/core';
import ConfirmButton from '../../utils/buttons/ConfirmButton';
import React from 'react';
import { connect } from 'react-redux';

function DownloadCustomerData(props: any) {
  const { dispatch } = props;

  const handleDownload = () => {
    dispatch(downloadCustomerData());
  };

  return (
    <Card className="p-4 mb-4">
      <div className="font-size-lg font-weight-bold">
        Download Customer data excel
      </div>
      <Divider className="my-4" />
      <ConfirmButton
        onClick={handleDownload}
        text={'Download'}
        buttonStyle={'addCreditBtn'}
      />
    </Card>
  );
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps)(DownloadCustomerData);
