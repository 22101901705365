import {
  receiveCanceled,
  receivePaid,
  receiveStartSubscription,
  requestStartSubscription,
  startSubscriptionError
} from './actions';
import { apiGet, apiPost } from '../../../../utils/api';
import text from '../../../../language/text';
import { loadCurrentUser } from '../../../../reducers/authActions';

const startSubscription = () => (dispatch: any, getState: any) => {
  dispatch(requestStartSubscription());

  apiPost('/payments/startSubscription', {}, getState().auth.token)
    .then((r: any) => r.json())
    .then((response: any) => {
      dispatch(receiveStartSubscription());
      var win = window.open(response.checkoutUrl, '_blank');
      win?.focus();

      dispatch(checkStatus(response.id));
    })
    .catch(() => {
      dispatch(startSubscriptionError(text.modals.addCredit.errors.unknown));
    });
};

const checkStatus = (id: number) => {
  return (dispatch: any, getState: any) => {
    const interval = setInterval(() => {
      apiGet(`/payments/subscriptions/${id}/status`, getState().auth.token)
        .then((r: any) => r.text())
        .then((response: any) => {
          if (response === 'paid') {
            dispatch(receivePaid());
            dispatch(loadCurrentUser(getState().auth.token));
            clearInterval(interval);
          } else if (response !== 'open') {
            dispatch(receiveCanceled());
            dispatch(
              startSubscriptionError(text.modals.addCredit.errors.paymentFailed)
            );
            clearInterval(interval);
          }
        })
        .catch(() => {
          clearInterval(interval);
          dispatch(receiveCanceled());
        });
    }, 5000);
  };
};

export { startSubscription, checkStatus };
