import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { connect } from 'react-redux';

import { Sidebar, Footer, Header } from '../../layout-components';
import { Redirect, useParams } from 'react-router';

const LeftSidebar = ({
  children,
  sidebarToggle,
  sidebarFixed,
  footerFixed,
  contentBackground,
  token,
  refreshFailed,
  headerOnly
}) => {
  let redirect = null;
  // if (
  //   refreshFailed !== false &&
  //   (token === undefined || token === null || token.length < 5)
  // ) {
  //   props.dispatch(fetchWithRefreshToken());
  // }
  const { locale } = useParams();
  if (refreshFailed) {
    redirect = <Redirect to={`/${locale}/login`} />;
  }

  return (
    <Fragment>
      {redirect}
      <div className={clsx('app-wrapper', contentBackground)}>
        <Header />
        <div
          className={clsx('app-main', {
            'app-main-sidebar-static': !sidebarFixed
          })}>
          {headerOnly ? (
            <>
              <div className="app-content--inner">
                <div className="app-content--inner__wrapper">
                  {token === undefined || token === null || token.length < 5
                    ? null
                    : children}
                </div>
              </div>
              <Footer />
            </>
          ) : (
            <>
              <Sidebar />
              <div
                className={clsx('app-content', {
                  'app-content-sidebar-collapsed': sidebarToggle,
                  'app-content-sidebar-fixed': sidebarFixed,
                  'app-content-footer-fixed': footerFixed
                })}>
                <div className="app-content--inner">
                  <div className="app-content--inner__wrapper">
                    {token === undefined || token === null || token.length < 5
                      ? null
                      : children}
                  </div>
                </div>
                <Footer />
              </div>
            </>
          )}
        </div>
      </div>
    </Fragment>
  );
};

LeftSidebar.propTypes = {
  children: PropTypes.node
};

const mapStateToProps = ({ themeOptions, auth }) => {
  return {
    token: auth.token,
    refreshFailed: auth.refreshFailed,
    sidebarToggle: themeOptions.sidebarToggle,
    sidebarToggleMobile: themeOptions.sidebarToggleMobile,
    sidebarFixed: themeOptions.sidebarFixed,

    headerFixed: themeOptions.headerFixed,
    headerSearchHover: themeOptions.headerSearchHover,
    headerDrawerToggle: themeOptions.headerDrawerToggle,

    footerFixed: themeOptions.footerFixed,

    contentBackground: themeOptions.contentBackground
  };
};

export default connect(mapStateToProps)(LeftSidebar);
