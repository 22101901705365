import React from 'react';

import text from '../../language/text';

import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import DynamicForm from '../utils/DynamicForm';
import FormLayout from '../utils/FormLayout';

interface IProps {
  dispatch: any;
  isFetching: boolean;
  error: string;
  success: string;
  token: any;
}

function ChangePasswordForm(props: any) {
  const { handleSubmit } = props;

  return (
    <FormLayout
      handleSubmit={handleSubmit}
      title={text.forms.changePasswordForm.title}
      subTitle={text.forms.changePasswordForm.defineNewPwdText}
      errorMessage={''}
      successMessage={''}
      dynamicForm={
        <DynamicForm
          handleSubmit={handleSubmit}
          fields={[
            {
              name: 'oldPassword',
              label: text.fields.oldPassword,
              type: 'password'
            },
            {
              name: 'newPassword1',
              label: text.fields.newPassword,
              type: 'password'
            },
            {
              name: 'newPassword2',
              label: text.fields.repeatPassword,
              type: 'password'
            }
          ]}
          outlined
        />
      }
      submitText={text.forms.changePasswordForm.title}
    />
  );
}

function mapStateToProps(state: any) {
  const {
    settings,
    auth: { token }
  } = state;
  const { isFetching, error, success } = settings || {
    isFetching: false,
    error: null,
    success: null
  };

  return {
    isFetching,
    error,
    success,
    token
  };
}

export default connect(mapStateToProps)(
  reduxForm<{}, IProps>({
    form: 'changePasswordForm'
  })(ChangePasswordForm)
);
