import {
  cancelSubscriptionError,
  receiveCancelSubscription,
  requestCancelSubscription
} from './actions';
import { apiDelete } from '../../../../utils/api';
import text from '../../../../language/text';
import { loadCurrentUser } from '../../../../reducers/authActions';

const cancelSubscription = () => (dispatch: any, getState: any) => {
  dispatch(requestCancelSubscription());

  apiDelete('/payments/cancelSubscription', getState().auth.token)
    .then(() => {
      dispatch(receiveCancelSubscription());
      dispatch(loadCurrentUser(getState().auth.token));
    })
    .catch(() => {
      dispatch(cancelSubscriptionError(text.modals.addCredit.errors.unknown));
    });
};

export { cancelSubscription };
