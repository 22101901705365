import {
  ADD_CREDIT_ERROR,
  CLOSE_MODAL,
  OPEN_MODAL,
  RECEIVE_ADD_CREDIT,
  RECEIVE_CANCELED,
  RECEIVE_PAID,
  REQUEST_ADD_CREDIT
} from './types';
import { combineReducers } from 'redux';

const openReducer = (state: any = false, action: any) => {
  switch (action.type) {
    case OPEN_MODAL:
      return true;
    case CLOSE_MODAL:
      return false;
    default:
      return state;
  }
};

const isFetchingReducer = (state: any = false, action: any) => {
  switch (action.type) {
    case REQUEST_ADD_CREDIT:
      return true;
    case RECEIVE_ADD_CREDIT:
    case ADD_CREDIT_ERROR:
    case CLOSE_MODAL:
      return false;
    default:
      return state;
  }
};

const isCheckingReducer = (state: any = false, action: any) => {
  switch (action.type) {
    case RECEIVE_ADD_CREDIT:
      return true;
    case RECEIVE_PAID:
    case RECEIVE_CANCELED:
    case CLOSE_MODAL:
      return false;
    default:
      return state;
  }
};

const successReducer = (state: any = false, action: any) => {
  switch (action.type) {
    case RECEIVE_PAID:
      return true;
    case RECEIVE_CANCELED:
    case CLOSE_MODAL:
      return false;
    default:
      return state;
  }
};

const errorReducer = (state: any = null, action: any) => {
  switch (action.type) {
    case ADD_CREDIT_ERROR:
      return action.error;
    case REQUEST_ADD_CREDIT:
    case CLOSE_MODAL:
      return null;
    default:
      return state;
  }
};

const reducer = combineReducers({
  open: openReducer,
  isFetching: isFetchingReducer,
  error: errorReducer,
  isChecking: isCheckingReducer,
  success: successReducer
});

export default reducer;
