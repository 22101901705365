import React, { Fragment, useState } from 'react';
import { Redirect, useParams } from 'react-router';

interface IProps {
  user: any;
}

function UserRow(props: IProps) {
  const { user } = props;

  const [redirect, setRedirect] = useState<any>(null);

  const { locale } = useParams<any>();

  const handleClick = (id: number) => {
    setRedirect(<Redirect to={`/${locale}/Admin/users/${id}`} />);
  };

  return (
    <Fragment>
      {redirect && redirect}
      <tr
        onClick={() => {
          handleClick(user.id);
        }}>
        <td>{user.username}</td>
        <td>{user.role}</td>
        <td>{user.confirmedEmail ? 'Yes' : 'No'}</td>
      </tr>
    </Fragment>
  );
}

export default UserRow;
