import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid, Typography, Container, Button } from '@material-ui/core';
import text from '../language/text';
import Loader from '../components/utils/Loader';
import { confirmEmail } from '../reducers/confirmemail/ConfirmEmailActions';
import { Link, useParams } from 'react-router-dom';
import projectLogo from '../template/assets/images/YourSEPA_Logo_Wit_RGB_WT_150dpi_V2.png';

interface IProps {
  dispatch: any;
  isFetching: boolean;
  errorMessage: string;
  success: boolean;
  match?: any;
}

function ConfirmEmailPage(props: IProps) {
  const { dispatch, isFetching, success, match, errorMessage } = props;

  useEffect(() => {
    if (match && match.params) {
      dispatch(confirmEmail(match.params.email, match.params.token));
    }
  }, [dispatch, match]);

  const { locale } = useParams();

  return (
    <Fragment>
      <div
        className="flex-grow-1 w-100 h-100 d-flex align-items-center"
        style={{ backgroundColor: '#0079f4' }}>
        <div className="bg-composed-wrapper--content pt-5 pb-2 py-lg-5">
          <Container maxWidth="md" className="pd-5">
            <Grid container spacing={4} style={{ justifyContent: 'center' }}>
              <Grid
                item
                lg={6}
                className="px-0 mx-auto d-flex align-items-center"
                style={{ justifyContent: 'center' }}>
                <div className="text-center">
                  <div className="px-4 px-sm-0 text-white mt-4">
                    <img
                      className="app-logo-img"
                      alt="YourSepa"
                      src={projectLogo}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item lg={6}>
                <Loader open={isFetching} />
                <div className="bg-secondary border-0">
                  <div className="card-body px-lg-5 py-lg-5 center">
                    {success && (
                      <Typography color="primary" className="my-2">
                        {text.forms.loginForm.emailConfirmed}
                      </Typography>
                    )}
                    {errorMessage && (
                      <Typography color="error" className="my-2">
                        {text.forms.loginForm.confirmEmailError}
                      </Typography>
                    )}
                    <Link to={`/${locale}/login`}>
                      <Button variant="contained" color="primary" fullWidth>
                        {text.forms.loginForm.login}
                      </Button>
                    </Link>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </Fragment>
  );
}

function mapStateToProps(state: any) {
  const { confirmEmail } = state;
  const { isFetching, error, success } = confirmEmail || {
    isFetching: false,
    error: null,
    success: false
  };
  return {
    isFetching,
    errorMessage: error,
    success
  };
}

export default connect(mapStateToProps)(ConfirmEmailPage);
