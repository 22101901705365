import React, { Fragment, useState } from 'react';
import { TextField, Typography } from '@material-ui/core';
import text from '../../language/text';
import ConfirmButton from '../utils/buttons/ConfirmButton';

import styles from '../../assets/cssModules/login.module.scss';
import { connect } from 'react-redux';
import { fetchToken } from '../../reducers/authActions';

interface IProps {
  dispatch: any;
  username: string;
  password: string;
  error: boolean;
}

const TwoFactorAuthForm = (props: IProps) => {
  const [code, setCode] = useState('');

  const handleSubmit = () => {
    props.dispatch(fetchToken(props.username, props.password, code));
  };

  const PressedEnter = (event: any) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <Fragment>
      <div className={styles.CenterLoginContainer}>
        <div className={styles.LoginContainer + ' text-center'}>
          <h1 className={styles.LoginHeader}>
            {text.forms.twoFactorAuthForm.title}
          </h1>
          <p className={styles.TwoFactorAuthText}>
            {' '}
            {text.forms.twoFactorAuthForm.sentCode}
          </p>
          <Typography color={'error'}>
            {props.error && text.forms.twoFactorAuthForm.error}
          </Typography>
          <form onSubmit={handleSubmit} onKeyPress={PressedEnter}>
            <TextField
              style={{
                marginTop: '20px'
              }}
              className={styles.UsernameInput}
              name="code"
              fullWidth
              label={text.forms.twoFactorAuthForm.code}
              type="email"
              variant="outlined"
              required={true}
              onSubmit={handleSubmit}
              onChange={(event: any) => setCode(event.target.value)}
            />
            <div className="text-center">
              <ConfirmButton
                buttonStyle={'SignInBtn'}
                onClick={handleSubmit}
                text={text.forms.twoFactorAuthForm.verifyBtn}
              />
            </div>
            <p className={styles.DidntReceiveCode}>
              {text.forms.twoFactorAuthForm.didntReceiveCode}{' '}
              <button
                onClick={handleSubmit}
                style={{
                  textDecoration: 'none',
                  border: 'none',
                  background: 'transparent'
                }}>
                <span className={styles.SendCodeAgain}>
                  {text.forms.twoFactorAuthForm.sendAgain}
                </span>
              </button>
            </p>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default connect(() => {})(TwoFactorAuthForm);
