import React, { Fragment } from 'react';
import clsx from 'clsx';
import { AppBar, Box, IconButton, Tooltip } from '@material-ui/core';
import { connect } from 'react-redux';
import { setSidebarToggleMobile } from '../../../reducers/ThemeOptions';
import HeaderUserbox from '../HeaderUserbox';
import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import projectLogo from '../../assets/images/YourSEPA_Logo_Wit_RGB_WT_150dpi_V2.png';

const Header = ({
  headerShadow,
  headerFixed,
  sidebarToggleMobile,
  setSidebarToggleMobile
}: any) => {
  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  return (
    <Fragment>
      <AppBar
        color="secondary"
        className={clsx('app-header', {})}
        position={headerFixed ? 'fixed' : 'absolute'}
        elevation={headerShadow ? 11 : 3}>
        <Box className="app-header-toolbar">
          <Box className="d-flex align-items-center">
            <Box
              className="toggle-sidebar-btn-mobile"
              style={{ color: 'white', textAlign: 'center' }}>
              <Tooltip title="Toggle Sidebar" placement="bottom">
                <IconButton
                  color="inherit"
                  onClick={toggleSidebarMobile}
                  size="medium">
                  {sidebarToggleMobile ? (
                    <MenuOpenRoundedIcon />
                  ) : (
                    <MenuRoundedIcon />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
            <Box className="app-header-logo">
              <img className="app-header-logo-img" alt="YourSepa" src={projectLogo} />
            </Box>
          </Box>
          <Box className="d-flex align-items-center">
            <HeaderUserbox />
          </Box>
        </Box>
      </AppBar>
    </Fragment>
  );
};

const mapStateToProps = ({ themeOptions }: any) => ({
  headerShadow: themeOptions.headerShadow,
  headerFixed: themeOptions.headerFixed,
  sidebarToggleMobile: themeOptions.sidebarToggleMobile
});

const mapDispatchToProps = (dispatch: any) => ({
  setSidebarToggleMobile: (enable: any) =>
    dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
