import React, { useState } from 'react';
import WarningButton from '../../utils/buttons/WarningButton';
import ModalDialog from '../../utils/ModalDialog';
import BasicButton from '../../utils/buttons/BasicButton';
import { Box, Typography } from '@material-ui/core';
import { apiDelete } from '../../../utils/api';
import { connect } from 'react-redux';
import SuccessModal from '../basicModals/SuccessModal';
import { CheckCircle } from '@material-ui/icons';
import { useParams } from 'react-router-dom';
import history from '../../../utils/history';

function DeleteUserModal(props: any) {
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);

  const { locale } = useParams<any>();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete = async () => {
    await apiDelete(`/users/${props.user.username}`, props.token);
    setOpen(false);
    setSuccess(true);
  };

  const email = (
    <Typography variant={'inherit'} color={'primary'}>
      {props.user.username}
    </Typography>
  );

  if (success) {
    return (
      <SuccessModal
        title={'User deleted'}
        success={
          <Box color={'green'} paddingTop={2}>
            <CheckCircle fontSize={'large'} />
          </Box>
        }
        handleClose={() => {
          setSuccess(false);
          history.push(`/${locale}/Admin`);
        }}
      />
    );
  }

  return (
    <>
      <WarningButton onClick={handleOpen} text={'Delete'} fullWidth={false} />
      <ModalDialog
        open={open}
        onClose={handleClose}
        title={'Delete user'}
        actions={[
          <BasicButton onClick={handleClose} text={'Cancel'} />,
          <WarningButton onClick={handleDelete} text={'Delete'} />
        ]}>
        <Typography className="mt-2 mb-3">
          Are you sure you want to delete {email} from YourSepa? This action can
          not be undone.
        </Typography>
      </ModalDialog>
    </>
  );
}

function mapStateToProps(state: any) {
  const { token } = state.auth;

  return {
    token
  };
}

export default connect(mapStateToProps)(DeleteUserModal);
