import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, LinearProgress, Typography } from '@material-ui/core';
import text from '../../../language/text';
import ModalDialog from '../../utils/ModalDialog';
import Loader from '../../utils/Loader';
import { startSubscription } from './ducks';
import { closeModal, openModal } from './ducks/actions';
import { formatMoney } from '../../../utils/formatters';

interface IProps {
  dispatch: any;
  isFetching: boolean;
  errorMessage: string;
  success: boolean;
  open: boolean;
  isChecking: boolean;
  subscriptionPrice: number;
}

function StartSubscriptionModal(props: IProps) {
  const {
    dispatch,
    open,
    isFetching,
    isChecking,
    success,
    subscriptionPrice,
    errorMessage
  } = props;

  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleOpen = () => {
    dispatch(openModal());
  };

  const handleSubmit = () => {
    dispatch(startSubscription());
  };

  return (
    <Fragment>
      <Button
        color="primary"
        variant="contained"
        style={{ float: 'right' }}
        onClick={handleOpen}>
        {text.modals.startSubscription.openButton}
      </Button>

      {isChecking ? (
        <ModalDialog
          open={open}
          onClose={() => {}}
          title={text.modals.startSubscription.title}
          actions={[]}>
          <Typography>{text.modals.addCredit.checkStatus}</Typography>
          <LinearProgress value={25} className="mb-3" />
        </ModalDialog>
      ) : success ? (
        <ModalDialog
          open={open}
          onClose={handleClose}
          title={text.modals.startSubscription.title}
          actions={[
            <Button
              onClick={handleClose}
              color="primary"
              variant="contained"
              key={1}>
              {text.modals.startSubscription.successClose}
            </Button>
          ]}>
          <Typography>{text.modals.startSubscription.success}</Typography>
        </ModalDialog>
      ) : (
        <ModalDialog
          open={open}
          onClose={handleClose}
          title={text.modals.startSubscription.title}
          actions={[
            <Button onClick={handleClose} color="secondary" key={1}>
              {text.modals.startSubscription.cancel}
            </Button>,
            <Button
              onClick={handleSubmit}
              color="primary"
              variant="contained"
              key={2}>
              {text.modals.startSubscription.buySubscription}
            </Button>
          ]}>
          <Typography color="error">{errorMessage}</Typography>
          <Loader open={isFetching} inElement />
          <Typography className="mb-3">
            {text.modals.startSubscription.modalText.replace(
              '%s',
              formatMoney(subscriptionPrice)
            )}
          </Typography>
          <div className="mb-3"></div>
        </ModalDialog>
      )}
    </Fragment>
  );
}

function mapStateToProps(state: any) {
  const { components } = state || {
    components: {
      modals: {
        startSubscriptionModal: {
          isFetching: false,
          open: false,
          error: null,
          isChecking: false,
          success: null
        }
      }
    }
  };
  const { modals } = components;
  const { startSubscriptionModal } = modals;
  const { isFetching, error, open, success, isChecking } =
    startSubscriptionModal;
  return {
    isFetching,
    errorMessage: error,
    success,
    isChecking,
    subscriptionPrice: state.auth.user.subscriptionPrice,
    open
  };
}

export default connect(mapStateToProps)(StartSubscriptionModal);
