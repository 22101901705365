import React, { Fragment } from 'react';
import { FormControl, TextField, Typography } from '@material-ui/core';
import projectLogoBlue from '../../template/assets/images/YourSEPA_Logo_Blauw_RGB_WT_150dpi_V2.png';

import text from '../../language/text';
import { connect } from 'react-redux';
import { fetchToken } from '../../reducers/authActions';
import Loader from '../utils/Loader';
import { Link, Redirect, useParams } from 'react-router-dom';
import styles from '../../assets/cssModules/login.module.scss';
import ConfirmButton from '../utils/buttons/ConfirmButton';
import TwoFactorAuthForm from './TwoFactorAuthForm';

interface IProps {
  dispatch: any;
  isFetching: boolean;
  error: string;
  token: string;
  refreshFailed: boolean;
  isTwoFa: boolean;
}

function LoginForm(props: IProps) {
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');

  const handleUsernameChange = (event: any) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event: any) => {
    setPassword(event.target.value);
  };

  const handleSignIn = (event: any) => {
    event.preventDefault();
    props.dispatch(fetchToken(username, password));
  };

  const { locale } = useParams<any>();

  let redirect = null;
  if (
    props.refreshFailed === undefined &&
    props.token !== null &&
    props.token.length > 1
  ) {
    redirect = <Redirect to={`/${locale}/Dashboard`} />;
  }

  const PressedEnter = (event: any) => {
    if (event.key === 'Enter') {
      handleSignIn(event);
    }
  };

  if (props.isTwoFa) {
    return (
      <>
        {redirect}
        <TwoFactorAuthForm
          error={props.error != null}
          username={username}
          password={password}
        />
      </>
    );
  }

  return (
    <Fragment>
      {redirect}
      <Loader open={props.isFetching} />
      <div className={styles.CenterLoginContainer}>
        <div className={styles.LoginContainer + ' text-center'}>
          <h1 className="display-2 mb-5 font-weight-bold">
            <img className="app-logo-img" alt="YourSepa" src={projectLogoBlue} />
          </h1>
          <form onSubmit={handleSignIn} onKeyPress={PressedEnter}>
            <Typography id={'LoginMessage'} color="error" variant="body2">
              {props.error}
            </Typography>
            <div className="form-group mb-3">
              <div>
                <TextField
                  id={'email'}
                  name="email"
                  fullWidth
                  label={text.fields.email}
                  type="email"
                  onChange={handleUsernameChange}
                  variant="outlined"
                  onSubmit={handleSignIn}
                />
              </div>
            </div>
            <FormControl className="w-100">
              <div className="input-group input-group-alternative">
                <TextField
                  id={'password'}
                  fullWidth
                  label={text.fields.password}
                  type="password"
                  onChange={handlePasswordChange}
                  onSubmit={handleSignIn}
                  variant="outlined"
                />
              </div>
            </FormControl>
            <ConfirmButton
              id={'SignInButton'}
              buttonStyle={'SignInBtn'}
              onClick={handleSignIn}
              text={text.forms.loginForm.login}
            />
            <Link
              className={styles.OtherOptionText}
              to={`/${locale}/forgotpassword`}>
              {text.forms.loginForm.goForgotPassword}
            </Link>
            <Link
              className={
                styles.OtherOptionText + ' ' + styles.OtherOptionTextBottom
              }
              to={`/${locale}/register`}>
              {text.forms.loginForm.goRegister}
            </Link>
          </form>
        </div>
      </div>
    </Fragment>
  );
}

function mapStateToProps(state: any) {
  const { auth } = state;
  const { isFetching, error, token, refreshFailed, isTwoFa } = auth || {
    token: null,
    isFetching: false,
    error: null,
    isTwoFa: false
  };

  return {
    isFetching,
    error,
    token,
    refreshFailed,
    isTwoFa
  };
}

export default connect(mapStateToProps)(LoginForm);
