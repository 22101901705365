import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';

function PaymentSuccessPage() {
  useEffect(() => {
    window.close();
  });

  return (
    <Fragment>
      <Typography>Payment done, you can close this page</Typography>
    </Fragment>
  );
}

function mapStateToProps(state: any) {
  const { CHANGE_TO_REDUCER_NAME } = state;
  const { isFetching, error, success } = CHANGE_TO_REDUCER_NAME || {
    isFetching: false,
    error: null,
    success: false
  };
  return {
    isFetching,
    errorMessage: error,
    success
  };
}

export default connect(mapStateToProps)(PaymentSuccessPage);
