import {
  receiveUserDetails,
  requestUserDetails,
  userDetailsError
} from './actions';
import { apiGet } from '../../../../utils/api';
import text from '../../../../language/text';

const fetchUser = (userId: number) => {
  return (dispatch: any, getState: any) => {
    dispatch(requestUserDetails());

    apiGet('/users/' + userId, getState().auth.token)
      .then((r: any) => r.json())
      .then((response: any) => {
        dispatch(receiveUserDetails(response));
      })
      .catch(() => {
        dispatch(
          userDetailsError(text.pages.admin.components.userDetails.error)
        );
      });
  };
};

export { fetchUser };
