import { combineReducers } from 'redux';
import auth from './authReducer';
import themeOptions from './ThemeOptions';
import { SIGN_OUT } from './authActions';
import settings from './settingsReducer';
import dashboard from './dashboardReducer';
import sepa from './sepaReducer';
import template from './templateReducers';
import register from './register/RegisterReducer';
import forgotPassword from './forgotPassword/ForgotPasswordReducer';
import confirmEmail from './confirmemail/ConfirmEmailReducer';
import resetPassword from './resetPassword/ResetPasswordReducer';
import deleteAccount from './deleteAccount/DeleteAccountReducer';
import userTable from './userTable/UserTableReducer';

import { reducer as formReducer } from 'redux-form';

import components from '../components';

const appReducer = combineReducers({
  auth,
  themeOptions,
  settings,
  dashboard,
  sepa,
  template,
  components,
  register,
  forgotPassword,
  confirmEmail,
  resetPassword,
  deleteAccount,
  userTable,
  form: formReducer
});

const rootReducer = (state: any, action: any) => {
  if (action.type === SIGN_OUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
