import text from '../language/text';
import { store } from '../App';
import { signOut } from '../reducers/authActions';

const baseUrl = process.env.REACT_APP_API_URL;

export function apiPost(endpoint: string, body: any, token?: string) {
  const headers: any = { 'Content-Type': 'application/json' };
  if (token) {
    headers.Authorization = token;
  }

  return new Promise((resolve, reject) => {
    fetch(`${baseUrl}${endpoint}`, {
      method: 'POST',
      headers: headers,
      credentials: 'omit',
      body: JSON.stringify(body)
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 403) {
            store.dispatch(signOut(true));
          } else {
            response.json().then((reason) => reject(reason));
          }
        } else {
          resolve(response);
        }
      })
      .catch((result) => {
        console.log(result);
      });
  });
}

export function apiPostFile(endpoint: string, file: any, token: string) {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('file', file);

    fetch(`${baseUrl}${endpoint}`, {
      method: 'POST',
      headers: { Authorization: token },
      credentials: 'omit',
      body: formData
    }).then((res) => {
      if (res.ok) {
        resolve(res);
      } else {
        res.json().then((reason) => {
          reject(errorHandler(reason));
        });
      }
    });
  });
}

export function apiGet(endpoint: string, token: string) {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
      method: 'GET',
      headers: { Authorization: token },
      credentials: 'omit'
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 403) {
            store.dispatch(signOut(true));
          } else {
            reject(response);
          }
        } else {
          resolve(response);
        }
      })
      .catch((result) => {
        console.log(result);
      });
  });
}

export function apiDelete(endpoint: string, token: string) {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
      method: 'DELETE',
      headers: { Authorization: token },
      credentials: 'omit'
    }).then((response) => {
      console.log('response', response);
      if (!response.ok) {
        if (response.status === 403) {
          store.dispatch(signOut(true));
        } else {
          response.json().then((reason) => reject(reason));
        }
      } else {
        resolve(response);
      }
    });
  });
}

export function apiDownload(
  endpoint: string,
  fileName: string,
  body: any,
  token: string
) {
  return new Promise((resolve, reject) => {
    fetch(`${baseUrl}${endpoint}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: token },
      credentials: 'omit',
      body: JSON.stringify(body)
    })
      .then((r: any) => (r.ok ? r.blob() : null))
      .then((response: any) => {
        if (response === null) {
          reject();
        } else {
          downloadFile(response, fileName);
          resolve('');
        }
      })
      .catch(reject);
  });
}

export function downloadFile(response: any, fileName: string) {
  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName); // 3. Append to html page
  document.body.appendChild(link); // 4. Force download
  link.click(); // 5. Clean up and remove the link
  //@ts-ignore
  link.parentNode.removeChild(link);
}

function errorHandler(reason: any): string {
  console.log('reason', reason);
  const errorCodes = {
    IMPORT_FAILED: text.errors.importFailed
  };
  if (reason.code) {
    switch (reason.code) {
      case 'IMPORT_FAILED':
        return errorCodes.IMPORT_FAILED.replace('#MESSAGE#', reason.message);
      default:
        break;
    }
  }

  return 'Something went wrong';
}
