import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { deleteAccount } from '../../reducers/deleteAccount/DeleteAccountActions';
import ConfirmButton from '../utils/buttons/ConfirmButton';
import text from '../../language/text';

interface IProps {
  dispatch: any;
  isFetching: boolean;
  errorMessage: string;
  success: boolean;
}

function DeleteAccount(props: IProps) {
  const { dispatch } = props;
  return (
    <Fragment>
      <ConfirmButton
        buttonStyle={'deleteAccountBtn'}
        onClick={() => {
          dispatch(deleteAccount());
        }}
        text={text.basic.delete}
      />
    </Fragment>
  );
}

function mapStateToProps(state: any) {
  const { deleteAccount } = state;
  const { isFetching, error, success } = deleteAccount || {
    isFetching: false,
    error: null,
    success: false
  };
  return {
    isFetching,
    errorMessage: error,
    success
  };
}

export default connect(mapStateToProps)(DeleteAccount);
