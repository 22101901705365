import {
  RECEIVE_USER_PAYMENTS,
  REQUEST_USER_PAYMENTS,
  USER_PAYMENTS_ERROR
} from './types';
import { combineReducers } from 'redux';

const isFetchingReducer = (state: any = false, action: any) => {
  switch (action.type) {
    case REQUEST_USER_PAYMENTS:
      return true;
    case RECEIVE_USER_PAYMENTS:
    case USER_PAYMENTS_ERROR:
      return false;
    default:
      return state;
  }
};

const paymentsReducer = (state: any = [], action: any) => {
  switch (action.type) {
    case RECEIVE_USER_PAYMENTS:
      return action.payments;
    default:
      return state;
  }
};

const errorReducer = (state: any = null, action: any) => {
  switch (action.type) {
    case USER_PAYMENTS_ERROR:
      return action.error;
    case REQUEST_USER_PAYMENTS:
      return null;
    default:
      return state;
  }
};

const reducer = combineReducers({
  isFetching: isFetchingReducer,
  payments: paymentsReducer,
  error: errorReducer
});

export default reducer;
