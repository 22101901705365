import modals from './modals';
import admin from './admin';
import forms from './forms';
import { combineReducers } from 'redux';

export default combineReducers({
  modals,
  admin,
  forms
});
