import {
  receiveSaveSepaDefaults,
  requestSaveSepaDefaults,
  saveSepaDefaultsError
} from './actions';
import { apiPost } from '../../../../utils/api';
import { loadCurrentUser } from '../../../../reducers/authActions';
import text from '../../../../language/text';

const saveSepaDefaults = (user: any) => (dispatch: any, getState: any) => {
  dispatch(requestSaveSepaDefaults());
  apiPost(
    `/users/${user.id}`,
    {
      ...getState().auth.user,
      ...user
    },
    getState().auth.token
  )
    .then(() => {
      dispatch(receiveSaveSepaDefaults());
      dispatch(loadCurrentUser(getState().auth.token));
    })
    .catch(() => {
      dispatch(saveSepaDefaultsError(text.forms.changeAccount.error));
    });
};

export { saveSepaDefaults };
