function ForgotPasswordValidator(values: any) {
  const errors: any = {};

  if (!values.email || !values.email.includes('@')) {
    errors.email = true;
  }

  return errors;
}

export default ForgotPasswordValidator;
