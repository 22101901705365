import text from '../../language/text';
import { checkIBAN } from '../../utils/ibanChecker';
import moment from 'moment';

function SepaValidator(values: any) {
  const errors: any = {};
  const transactionsErrors: any[] = [];
  const mandates: string[] = [];

  if (!values.method) {
    errors.method = text.forms.validation.required;
  }

  if (!values.frequency) {
    errors.frequency = text.forms.validation.required;
  }

  if (!values.collectionDate) {
    errors.collectionDate = text.forms.validation.required;
  } else if (moment().subtract(1, 'day').isAfter(values.collectionDate)) {
    errors.collectionDate = text.forms.validation.required;
  }

  if (!values.companyName) {
    errors.companyName = text.forms.validation.required;
  }

  if (!values.paymentType) {
    errors.paymentType = text.forms.validation.required;
  }

  if (!values.iban || checkIBAN(values.iban) !== 1) {
    errors.iban = text.forms.validation.ibanFormat;
  }

  if (!values.bic) {
    errors.bic = text.forms.validation.required;
  }

  if (!values.batch) {
    errors.batch = text.forms.validation.required;
  }

  if (values.transactions !== undefined) {
    values.transactions.forEach((transaction: any, index: number) => {
      const transactionErrors: any = {};

      if (!transaction.name) {
        transactionErrors.name = text.forms.validation.required;
      }
      if (!transaction.iban) {
        transactionErrors.iban = text.forms.validation.required;
      }
      if (!transaction.mandateReference && values.debit) {
        transactionErrors.mandateReference = text.forms.validation.required;
      }

      if (mandates.includes(transaction.mandateReference)) {
        transactionErrors.mandateReference = 'Duplicate';
      } else {
        mandates.push(transaction.mandateReference);
      }

      if (!transaction.mandateReferenceDate && values.debit) {
        transactionErrors.mandateReferenceDate = text.forms.validation.required;
      } else if (
        values.debit &&
        moment().isBefore(transaction.mandateReferenceDate)
      ) {
        transactionErrors.mandateReferenceDate = text.forms.validation.required;
      }

      if (!transaction.value || transaction.value <= 0) {
        transactionErrors.value = text.forms.validation.required;
      }

      transactionsErrors[index] = transactionErrors;
    });

    if (transactionsErrors.length) {
      errors.transactions = transactionsErrors;
    }
  }

  return errors;
}

export default SepaValidator;
