export const sepaCountries = [
  {
    label: 'Austria',
    value: 'AT'
  },
  {
    label: 'Azores',
    value: 'PT'
  },
  {
    label: 'Belgium',
    value: 'BE'
  },
  {
    label: 'Bulgaria',
    value: 'BG'
  },
  {
    label: 'Canary Islands',
    value: 'ES'
  },
  {
    label: 'Croatia',
    value: 'HR'
  },
  {
    label: 'Cyprus',
    value: 'CY'
  },
  {
    label: 'Czech Republic',
    value: 'CZ'
  },
  {
    label: 'Denmark',
    value: 'DK'
  },
  {
    label: 'Estonia',
    value: 'EE'
  },
  {
    label: 'Finland',
    value: 'FI'
  },
  {
    label: 'France',
    value: 'FR'
  },
  {
    label: 'French Guiana',
    value: 'GF'
  },
  {
    label: 'Germany',
    value: 'DE'
  },
  {
    label: 'Gibraltar',
    value: 'GI'
  },
  {
    label: 'Greece',
    value: 'GR'
  },
  {
    label: 'Guadeloupe',
    value: 'GP'
  },
  {
    label: 'Guernsey',
    value: 'GG'
  },
  {
    label: 'Hungary',
    value: 'HU'
  },
  {
    label: 'Iceland',
    value: 'IS'
  },
  {
    label: 'Ireland',
    value: 'IE'
  },
  {
    label: 'Isle of Man',
    value: 'IM'
  },
  {
    label: 'Italy',
    value: 'IT'
  },
  {
    label: 'Jersey',
    value: 'JE'
  },
  {
    label: 'Latvia',
    value: 'LV'
  },
  {
    label: 'Liechtenstein',
    value: 'LI'
  },
  {
    label: 'Lithuania',
    value: 'LT'
  },
  {
    label: 'Luxembourg',
    value: 'LU'
  },
  {
    label: 'Madeira',
    value: 'PT'
  },
  {
    label: 'Malta',
    value: 'MT'
  },
  {
    label: 'Martinique',
    value: 'MQ'
  },
  {
    label: 'Mayotte',
    value: 'YT'
  },
  {
    label: 'Monaco',
    value: 'MC'
  },
  {
    label: 'Netherlands',
    value: 'NL'
  },
  {
    label: 'Norway',
    value: 'NO'
  },
  {
    label: 'Poland',
    value: 'PL'
  },
  {
    label: 'Portugal',
    value: 'PL'
  },
  {
    label: 'Réunion',
    value: 'RE'
  },
  {
    label: 'Romania',
    value: 'RO'
  },
  {
    label: 'Saint Barthélemy',
    value: 'BL'
  },
  {
    label: 'Saint Martin (French part)',
    value: 'MF'
  },
  {
    label: 'Saint Pierre and Miquelon',
    value: 'PM'
  },
  {
    label: 'San Marino',
    value: 'SM'
  },
  {
    label: 'Slovakia',
    value: 'SK'
  },
  {
    label: 'Slovenia',
    value: 'SI'
  },
  {
    label: 'Spain',
    value: 'ES'
  },
  {
    label: 'Sweden',
    value: 'SE'
  },
  {
    label: 'Switzerland',
    value: 'CH'
  },
  {
    label: 'United Kingdom',
    value: 'GB'
  }
];
