import text from '../../../language/text';

function RegisterValidator(values: any) {
  const errors: any = {};

  if (!values.email || (values.email && !values.email.includes('@'))) {
    errors.email = true;
  }

  const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  if (!values.password || values.password.length <= 6) {
    errors.password = text.forms.registerForm.passwordShortError;
  } else if (!passwordRegex.exec(values.password)) {
    errors.password = text.forms.registerForm.passwordFormatError;
  }
  if (
    values.password &&
    values.passwordRepeat &&
    values.password !== values.passwordRepeat
  ) {
    errors.passwordRepeat = text.forms.registerForm.passwordsDontMatchError;
  }

  if (!values.firstName) {
    errors.firstName = true;
  }
  if (!values.lastName) {
    errors.lastName = true;
  }

  return errors;
}

export default RegisterValidator;
