import { apiDelete, apiGet, downloadFile } from '../utils/api';
import { setSepa } from './sepaActions';

export const REQUEST_DOWNLOADS = 'REQUEST_DOWNLOADS';
export const RECEIVE_DOWNLOADS = 'RECEIVE_DOWNLOADS';

export const REQUEST_DOWNLOAD = 'REQUEST_DOWNLOAD';
export const RECEIVE_DOWNLOAD = 'RECEIVE_DOWNLOAD';

export function requestDownloads() {
  return {
    type: REQUEST_DOWNLOADS
  };
}

function requestDownload() {
  return {
    type: REQUEST_DOWNLOAD
  };
}

function receiveDownloads(downloads: any) {
  return {
    type: RECEIVE_DOWNLOADS,
    downloads: downloads
  };
}

function receiveDownload() {
  return {
    type: RECEIVE_DOWNLOAD
  };
}

export function fetchDownloads() {
  return (dispatch: any, getState: any) => {
    dispatch(requestDownloads());
    return fetch(`${process.env.REACT_APP_API_URL}/downloads`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().auth.token
      },
      credentials: 'omit'
    })
      .then((response: Response) => response.json())
      .then((data: any) => {
        if (data.status && data.status !== 200) {
          dispatch(receiveDownloads([]));
        } else {
          dispatch(receiveDownloads(data));
        }
      })
      .catch((reason: any) => {
        console.error(reason);
      });
  };
}

export function downloadXml(id: number) {
  return (dispatch: any, getState: any) => {
    dispatch(requestDownload());
    return apiGet(`/downloads/${id}/xml`, getState().auth.token)
      .then((r: any) => r.blob())
      .then((response: any) => {
        downloadFile(response, `sepa_download.xml`);
        dispatch(receiveDownload());
      })
      .catch((reason: any) => {
        console.error(reason);
      });
  };
}

export function deleteDownload(id: number) {
  return (dispatch: any, getState: any) => {
    dispatch(requestDownload());
    return apiDelete(`/downloads/${id}`, getState().auth.token)
      .then((response: any) => {
        if (response.ok) {
          dispatch(receiveDownload());
          dispatch(fetchDownloads());
        }
      })
      .catch((reason: any) => {
        console.error(reason);
      });
  };
}

export function loadSepaFromDownload(id: number) {
  return (dispatch: any, getState: any) => {
    dispatch(requestDownload());
    return apiGet(`/downloads/${id}/sepa`, getState().auth.token)
      .then((r: any) => r.json())
      .then((response: any) => {
        dispatch(setSepa(response));
      })
      .catch((reason: any) => {
        console.error(reason);
      });
  };
}
