import React, { Fragment } from 'react';
import RecentDownloadList from '../components/dashboard/RecentDownloadList';
import SepaComponent from '../components/dashboard/SepaComponent';
import { Grid } from '@material-ui/core';

function DashboardPage() {
  return (
    <Fragment>
      <Grid container spacing={4}>
        <Grid item xs={12} lg={8}>
          <SepaComponent />
        </Grid>
        <Grid item xs={12} lg={4}>
          <RecentDownloadList />
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default DashboardPage;
