import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, Grid } from '@material-ui/core';

import RegisterForm from '../components/forms/RegisterForm/RegisterForm';
import { register } from '../reducers/register/RegisterActions';

import styles from '../assets/cssModules/login.module.scss';

interface IProps {
  dispatch: any;
}

function RegisterPage(props: IProps) {
  const { dispatch } = props;

  const handleSubmit = (values: any) => {
    dispatch(
      register(
        values.email,
        values.firstName,
        values.lastNamePrefix,
        values.lastName,
        values.company,
        values.address,
        values.postalCode,
        values.country,
        values.password
      )
    );
  };

  return (
    <Fragment>
      <div
        className={
          styles.Background +
          ' ' +
          'flex-grow-1 w-100 h-100 d-flex align-items-center'
        }>
        <div className="bg-composed-wrapper--content pt-5 pb-2 py-lg-5">
          <Container maxWidth="md" className="pd-5">
            <Grid container spacing={4} style={{ justifyContent: 'center' }}>
              <Grid item lg={6}>
                <RegisterForm onSubmit={handleSubmit} />
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </Fragment>
  );
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps)(RegisterPage);
