const OPEN_MODAL = 'cancelSubscriptionModal/ducks/OPEN_MODAL';
const CLOSE_MODAL = 'cancelSubscriptionModal/ducks/CLOSE_MODAL';
const REQUEST_CANCEL_SUBSCRIPTION =
  'cancelSubscriptionModal/ducks/REQUEST_CANCEL_SUBSCRIPTION';
const RECEIVE_CANCEL_SUBSCRIPTION =
  'cancelSubscriptionModal/ducks/RECEIVE_CANCEL_SUBSCRIPTION';
const CANCEL_SUBSCRIPTION_ERROR =
  'cancelSubscriptionModal/ducks/CANCEL_SUBSCRIPTION_ERROR';

export {
  OPEN_MODAL,
  CLOSE_MODAL,
  REQUEST_CANCEL_SUBSCRIPTION,
  RECEIVE_CANCEL_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION_ERROR
};
