import { apiPost } from '../../utils/api';
import text from '../../language/text';
import { reset } from 'redux-form';

export const REQUEST_RESET_PASSWORD = 'REQUEST_RESET_PASSWORD';
export const RECEIVE_RESET_PASSWORD = 'RECEIVE_RESET_PASSWORD';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export function requestResetPassword() {
  return {
    type: REQUEST_RESET_PASSWORD
  };
}

export function receiveResetPassword() {
  return {
    type: RECEIVE_RESET_PASSWORD
  };
}

export function resetPasswordError(error: string) {
  return {
    type: RESET_PASSWORD_ERROR,
    error
  };
}

export function resetPassword(email: string, password: string, token: string) {
  return (dispatch: any) => {
    dispatch(requestResetPassword());

    apiPost(
      '/users/resetpassword',
      {
        email,
        password,
        token
      },
      ''
    )
      .then(() => {
        dispatch(receiveResetPassword());
        dispatch(reset('ResetPasswordForm'));
      })
      .catch((reason: any) => {
        console.error(reason);
        dispatch(resetPasswordError(text.forms.forgotPasswordForm.error));
      });
  };
}
