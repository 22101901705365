import React from 'react';
import { matchPath } from 'react-router-dom';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';

import { List, Typography } from '@material-ui/core';

import SidebarMenuListItem from './SidebarMenuListItem';
import text from '../../../language/text';
import { connect } from 'react-redux';

const SidebarMenuList = (props: any) => {
  const { role, ...rest } = props;

  const menuItems = [
    {
      label: text.pages.dashboard.title,
      icon: DashboardIcon,
      to: 'Dashboard'
    },
    {
      label: text.pages.settings.title,
      icon: SettingsIcon,
      to: 'Settings'
    }
  ];

  if (role === 'ADMIN') {
    menuItems.push({
      label: text.pages.admin.title,
      icon: DashboardIcon,
      to: 'Admin'
    });
  }

  return (
    <List className="p-0">
      {menuItems.reduce(
        (items, page) => reduceChildRoutes({ items, page, role, ...rest }),
        []
      )}
    </List>
  );
};

const reduceChildRoutes = (props: any) => {
  const { router, items, role, page, depth } = props;

  console.log(page, role);

  if (page.content) {
    const open = matchPath(router.location.pathname, {
      path: page.to,
      exact: false
    });

    items.push(
      <SidebarMenuListItem
        depth={depth}
        icon={page.icon}
        key={page.label}
        label={page.badge}
        open={Boolean(open)}
        title={page.label}>
        <div className="sidebar-menu-children py-2">
          <SidebarMenuList role={role} depth={depth + 1} />
        </div>
      </SidebarMenuListItem>
    );
  } else {
    items.push(
      <SidebarMenuListItem
        depth={depth}
        href={page.to}
        icon={page.icon}
        key={page.label}
        label={page.badge}
        title={page.label}
      />
    );
  }

  return items;
};

const SidebarMenu = (props: any) => {
  const { title, className, role, component: Component, ...rest } = props;

  return (
    <Component {...rest} className={className}>
      {title ? (
        <Typography className="app-sidebar-heading">{title}</Typography>
      ) : (
        <div style={{ height: 10 }}></div>
      )}
      <SidebarMenuList role={role} depth={0} />
    </Component>
  );
};

function mapStateToProps(state: any) {
  const { auth } = state;
  let role = 'FREE';
  if (auth && auth.user && auth.user.role) {
    role = auth.user.role;
  }

  return {
    role
  };
}

export default connect(mapStateToProps)(SidebarMenu);
