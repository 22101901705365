import importXmlModal from './ImportXmlModal';
import addCreditModal from './AddCreditModal';
import startSubscriptionModal from './StartSubscriptionModal';
import cancelSubscriptionModal from './CancelSubscriptionModal';
import editUserModal from './EditUserModal';
import { combineReducers } from 'redux';

export default combineReducers({
  importXmlModal,
  addCreditModal,
  startSubscriptionModal,
  cancelSubscriptionModal,
  editUserModal
});
