import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Grid, Card, Divider } from '@material-ui/core';
import Loader from '../../utils/Loader';
import text from '../../../language/text';
import ChangeSepaDefaultsForm from '../../forms/changeSepaDefaults/ChangeSepaDefaultsForm';
import { saveSepaDefaults } from '../../forms/changeSepaDefaults/ducks';

interface IProps {
  dispatch: any;
  isFetching: boolean;
  errorMessage: string;
  success: boolean;
}

function SepaData(props: IProps) {
  const { dispatch, isFetching } = props;

  const handleSubmit = (values: any) => {
    dispatch(saveSepaDefaults(values));
  };

  return (
    <Fragment>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card className="p-4 mb-4">
            <Loader inElement open={isFetching} />
            <div className="font-size-lg font-weight-bold">
              {text.pages.settings.components.sepaData.title}
            </div>
            <Divider className="my-4" />
            <ChangeSepaDefaultsForm onSubmit={handleSubmit} />
          </Card>
        </Grid>
      </Grid>
    </Fragment>
  );
}

function mapStateToProps(state: any) {
  const { components } = state;
  const { isFetching, error, success } = components.settings || {
    isFetching: false,
    error: null,
    success: false
  };
  return {
    isFetching,
    errorMessage: error,
    success
  };
}

export default connect(mapStateToProps)(SepaData);
