import {
  REQUEST_CONFIRM_EMAIL,
  RECEIVE_CONFIRM_EMAIL,
  CONFIRM_EMAIL_ERROR
} from './ConfirmEmailActions';

function ConfirmEmail(
  state = {
    isFetching: false,
    error: null,
    success: false
  },
  action: any
) {
  switch (action.type) {
    case REQUEST_CONFIRM_EMAIL:
      return {
        ...state,
        isFetching: true,
        error: null,
        success: false
      };
    case RECEIVE_CONFIRM_EMAIL:
      return {
        ...state,
        isFetching: false,
        error: null,
        success: true
      };
    case CONFIRM_EMAIL_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        success: false
      };

    default:
      return { ...state };
  }
}

export default ConfirmEmail;
