import {
  CLOSE_MODAL,
  OPEN_MODAL,
  RECEIVE_UPLOAD_XML,
  REQUEST_UPLOAD_XML,
  UPLOAD_XML_ERROR
} from './types';
import { combineReducers } from 'redux';

const openReducer = (state: any = false, action: any) => {
  switch (action.type) {
    case OPEN_MODAL:
      return true;
    case CLOSE_MODAL:
      return false;
    default:
      return state;
  }
};

const isFetchingReducer = (state: any = false, action: any) => {
  switch (action.type) {
    case REQUEST_UPLOAD_XML:
      return true;
    case RECEIVE_UPLOAD_XML:
    case UPLOAD_XML_ERROR:
      return false;
    default:
      return state;
  }
};

const errorReducer = (state: any = null, action: any) => {
  switch (action.type) {
    case UPLOAD_XML_ERROR:
      return action.error;
    default:
      return state;
  }
};

const reducer = combineReducers({
  open: openReducer,
  isFetching: isFetchingReducer,
  error: errorReducer
});

export default reducer;
