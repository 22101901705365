import { REQUEST_USERS, RECEIVE_USERS, USERS_ERROR } from './UserTableActions';

function ResetPassword(
  state = {
    isFetching: false,
    error: null,
    success: false,
    users: []
  },
  action: any
) {
  switch (action.type) {
    case REQUEST_USERS:
      return {
        ...state,
        isFetching: true,
        error: null,
        success: false
      };
    case RECEIVE_USERS:
      return {
        ...state,
        isFetching: false,
        error: null,
        success: true,
        users: action.users
      };
    case USERS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        success: false
      };

    default:
      return { ...state };
  }
}

export default ResetPassword;
