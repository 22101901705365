import {
  CLOSE_MODAL,
  OPEN_MODAL,
  RECEIVE_CANCELED,
  RECEIVE_PAID,
  RECEIVE_START_SUBSCRIPTION,
  REQUEST_START_SUBSCRIPTION,
  START_SUBSCRIPTION_ERROR
} from './types';

const openModal = () => ({
  type: OPEN_MODAL
});

const closeModal = () => ({
  type: CLOSE_MODAL
});

const requestStartSubscription = () => ({
  type: REQUEST_START_SUBSCRIPTION
});

const receiveStartSubscription = () => ({
  type: RECEIVE_START_SUBSCRIPTION
});

const startSubscriptionError = (error: string) => ({
  type: START_SUBSCRIPTION_ERROR,
  error
});

const receivePaid = () => ({
  type: RECEIVE_PAID
});

const receiveCanceled = () => ({
  type: RECEIVE_CANCELED
});

export {
  openModal,
  closeModal,
  requestStartSubscription,
  receiveStartSubscription,
  startSubscriptionError,
  receivePaid,
  receiveCanceled
};
