import React, { Fragment, useState } from 'react';
import { Menu, Button, List, ListItem } from '@material-ui/core';

import DutchFlag from '../../assets/images/nlFlag.png';
import EnglishFlag from '../../assets/images/ukFlag.png';
import { useParams, Redirect } from 'react-router';
import styles from '../../assets/cssModules/dashboardHeader.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function LanguagesMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { locale } = useParams<any>();
  const setFlag = () => {
    if (locale === 'en') {
      return EnglishFlag;
    }
    if (locale === 'nl') {
      return DutchFlag;
    }
  };

  const [redirect, setRedirect] = useState<any>(null);

  const redirectToLanguage = (languageCode: string) => {
    if (locale === languageCode) return;
    setRedirect(<Redirect to={`/${languageCode}/Dashboard`} />);
  };

  const [flag, selectedFlag] = React.useState(setFlag);
  const [languageCode, selectedCode] = React.useState(locale);

  const languages = [
    {
      name: 'English',
      code: 'en',
      flag: EnglishFlag
    },
    {
      name: 'Dutch',
      code: 'nl',
      flag: DutchFlag
    }
  ];

  return (
    <Fragment>
      {redirect}
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}>
        <div className="language-holder p-2">
          <img src={flag} alt="CountryFlag" className="flag" />
        </div>
        <span className="pl-1 pl-xl-3" style={{ color: 'white' }}>
          <span style={{ marginRight: '5px' }}> {languageCode} </span>
          <FontAwesomeIcon icon={['fas', 'angle-down']} />
        </span>
      </Button>
      <Menu
        id="simple-menu"
        MenuListProps={{ disablePadding: true }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleClose}>
        <div className="language-holder p-2">
          <List
            disablePadding={true}
            className={
              styles.SelectLanguageBg +
              '' +
              'text-left bg-transparent d-flex align-items-center flex-column pt-0'
            }>
            {languages.map(
              (language: { name: string; code: string; flag: any }) => (
                <ListItem
                  key={language.name}
                  button
                  onClick={() => {
                    redirectToLanguage(language.code);
                    selectedFlag(language.flag);
                    selectedCode(language.code);
                  }}>
                  <img
                    src={language.flag}
                    alt={language.name}
                    className="flag"
                  />
                  <h1 className={styles.LanguageCodes}>
                    {language.code.toString().toUpperCase()}
                  </h1>
                </ListItem>
              )
            )}
          </List>
        </div>
      </Menu>
    </Fragment>
  );
}

export default LanguagesMenu;
