import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Divider, Grid } from '@material-ui/core';
import text from '../../../language/text';
import CancelSubscriptionModal from '../../modals/CancelSubscriptionModal/CancelSubscriptionModal';
import moment from 'moment';

interface IProps {
  dispatch: any;
  subscriptionStart: any;
}

function CancelSubscription(props: IProps) {
  const { subscriptionStart } = props;
  const strings = text.pages.settings.components.startSubscription;
  return (
    <Fragment>
      <div className="font-size-lg font-weight-bold">
        {strings.subscription}
      </div>
      <Divider className="my-4" />
      <Grid container spacing={4}>
        <Grid item xs={3}>
          {text.pages.settings.components.startSubscription.subscriptionStart}:
        </Grid>
        <Grid item xs={9}>
          {moment(subscriptionStart).format(text.basic.dateFormat)}
        </Grid>
        <Grid item xs={3}>
          {text.pages.settings.components.startSubscription.nextPayment}:
        </Grid>
        <Grid item xs={9}>
          {moment(subscriptionStart)
            .add(1, 'year')
            .format(text.basic.dateFormat)}
        </Grid>
      </Grid>
      <CancelSubscriptionModal />
    </Fragment>
  );
}

function mapStateToProps(state: any) {
  const { user } = state.auth;
  return {
    subscriptionStart: user.subscriptionStart
  };
}

export default connect(mapStateToProps)(CancelSubscription);
