import {
  REQUEST_TOKEN,
  RECEIVE_TOKEN,
  SIGN_OUT,
  TOKEN_ERROR,
  RECEIVE_USER,
  REDIRECT_TWO_FACTOR
} from './authActions';

import text from '../language/text';

function auth(
  state = {
    isFetching: false,
    token: '',
    isTwoFa: false
  },
  action: any
) {
  switch (action.type) {
    case REQUEST_TOKEN:
      return Object.assign({}, state, {
        isFetching: true,
        error: null,
        refreshFailed: undefined
      });
    case RECEIVE_TOKEN:
      return Object.assign({}, state, {
        isFetching: false,
        token: action.token
      });
    case TOKEN_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
        error: text.forms.loginForm.error
      });
    case REDIRECT_TWO_FACTOR:
      return Object.assign({}, state, {
        isTwoFa: true
      });
    case SIGN_OUT:
      return Object.assign({}, state, {
        token: null,
        refreshFailed: action.refreshFailed
      });
    case RECEIVE_USER:
      return {
        ...state,
        user: action.user
      };
    default:
      return state;
  }
}

export default auth;
