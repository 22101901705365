import { apiDelete } from '../../utils/api';
import text from '../../language/text';

export const REQUEST_DELETE_ACCOUNT = 'REQUEST_DELETE_ACCOUNT';
export const RECEIVE_DELETE_ACCOUNT = 'RECEIVE_DELETE_ACCOUNT';
export const DELETE_ACCOUNT_ERROR = 'DELETE_ACCOUNT_ERROR';

export function requestDeleteAccount() {
  return {
    type: REQUEST_DELETE_ACCOUNT
  };
}

export function receiveDeleteAccount() {
  return {
    type: RECEIVE_DELETE_ACCOUNT
  };
}

export function deleteAccountError(error: string) {
  return {
    type: DELETE_ACCOUNT_ERROR,
    error
  };
}

export function deleteAccount() {
  return (dispatch: any, getState: any) => {
    dispatch(requestDeleteAccount());

    apiDelete('/users/' + getState().auth.user.username, getState().auth.token)
      .then(() => {
        dispatch(receiveDeleteAccount());
      })
      .catch((reason: any) => {
        console.error(reason);
        dispatch(deleteAccountError(text.forms.forgotPasswordForm.error));
      });
  };
}
