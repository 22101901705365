import React, { Fragment } from 'react';
import TextField from './TextField';
import { MenuItem } from '@material-ui/core';
import text from '../../language/text';

interface IChoice {
  label: string | number;
  value: string | number;
}

interface IProps {
  label: string;
  value: string | number;
  name: string;
  onChange: (event: any) => void;
  choices: IChoice[];
  error?: boolean;
}

function SelectField(props: IProps) {
  const { choices } = props;
  return (
    <Fragment>
      <TextField select className="mx-0 my-2" {...props}>
        <MenuItem value="">
          <em>{text.fields.selectEmpty}</em>
        </MenuItem>
        {choices.map((choice: IChoice, index: number) => (
          <MenuItem key={index} value={choice.value}>
            {choice.label}
          </MenuItem>
        ))}
      </TextField>
    </Fragment>
  );
}

export default SelectField;
