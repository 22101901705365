import React from 'react';

import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import styles from '../../assets/cssModules/changePages.module.scss';
import { Box, Card, CardContent, Grid } from '@material-ui/core';
import ConfirmButton from '../utils/buttons/ConfirmButton';

import text from '../../language/text';
import TextField from '../utils/TextField';
import BigButton from '../utils/buttons/BigButton';

interface IProps {
  dispatch: any;
  isFetching: boolean;
  error: string;
  success: string;
}

function InviteFriendForm() {
  const handleSubmit = () => {};

  return (
    <React.Fragment>
      <div className={styles.CenterContainer}>
        <div className={styles.ChangePwdContainer}>
          <h1 className={styles.Title}>{text.forms.inviteFriendForm.title}</h1>
          <p className={styles.Text}>
            {text.forms.inviteFriendForm.inviteText}
          </p>

          <Card className={styles.CardGreyBackground}>
            <CardContent>
              <Box style={{ maxWidth: '550px', justifyContent: 'center' }}>
                <span className={styles.SendCodeText}>
                  {text.forms.inviteFriendForm.sendCode}
                </span>
                <TextField
                  style={{ backgroundColor: 'white' }}
                  disabled
                  id="discountCode"
                  value="yoursepa2021"
                  InputProps={{
                    endAdornment: (
                      <ConfirmButton
                        text={text.forms.inviteFriendForm.copyCode}
                        buttonStyle={'CopyDiscount'}
                        onClick={() => {
                          navigator.clipboard.writeText('yoursepa2021');
                        }}
                      />
                    )
                  }}
                />
              </Box>

              <Box className={styles.FormBtns}>
                <ConfirmButton
                  buttonStyle={'changePasswordBtn'}
                  onClick={handleSubmit}
                  text={text.forms.inviteFriendForm.sendCode}
                />
              </Box>
            </CardContent>
          </Card>
        </div>
      </div>

      <Grid container direction={'row'} spacing={2}>
        <Grid item xs={4}>
          <BigButton
            onClick={handleSubmit}
            alternativeText={text.forms.inviteFriendForm.opened}
            inviteOpenedCount={2}
          />
        </Grid>
        <Grid item xs={4}>
          <BigButton
            onClick={handleSubmit}
            alternativeText={text.forms.inviteFriendForm.createdAccount}
            inviteAccountsCreatedCount={1}
          />
        </Grid>
        <Grid item xs={4}>
          <BigButton
            onClick={handleSubmit}
            alternativeText={text.forms.inviteFriendForm.sent}
            invitesSentCount={4}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

function mapStateToProps(state: any) {
  const { settings } = state;
  const { isFetching, error, success } = settings || {
    isFetching: false,
    error: null,
    success: null
  };

  return {
    isFetching,
    error,
    success
  };
}

export default connect(mapStateToProps)(
  reduxForm<{}, IProps>({
    form: 'InviteFriendForm'
  })(InviteFriendForm)
);
