import {
  ADD_TRANSACTION,
  CHANGE_TRANSACTION,
  CLEAR_SEPA,
  RECEIVE_SEPA,
  RECEIVE_UPLOAD_EXCEL,
  REMOVE_TRANSACTION,
  REQUEST_SEPA,
  SEPA_ERROR,
  SET_SEPA,
  SET_TRANSACTIONS,
  SWITCH_FORM
} from './sepaActions';
import moment from 'moment';
import { RECEIVE_USER } from './authActions';

function sepa(
  state = {
    isFetching: false,
    error: null,
    success: false,
    values: {},
    transactions: [{ key: '0' }]
  },
  action: any
) {
  switch (action.type) {
    case REQUEST_SEPA:
      return {
        ...state,
        isFetching: true,
        error: null,
        success: false
      };
    case RECEIVE_SEPA:
      return {
        ...state,
        isFetching: false,
        error: null,
        success: true
      };
    case ADD_TRANSACTION: {
      const transactions = state.transactions;
      transactions.push({
        key: state.transactions.length + new Date().toString()
      });

      return {
        ...state,
        transactions: [...transactions]
      };
    }
    case CHANGE_TRANSACTION: {
      const transactions2 = state.transactions;
      transactions2[action.index] = action.transaction;

      return {
        ...state,
        transactions: [...transactions2]
      };
    }
    case REMOVE_TRANSACTION: {
      let transactions3 = state.transactions;
      let t1 = transactions3.slice(0, action.index);
      let t2 = transactions3.slice(action.index + 1);
      transactions3 = t1.concat(t2);
      if (transactions3.length === 0) {
        transactions3 = [{ key: '0' }];
      }
      return {
        ...state,
        transactions: [...transactions3]
      };
    }
    case SEPA_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };

    case CLEAR_SEPA:
      return {
        ...state,
        error: null,
        success: null,
        values: {},
        transactions: [{ key: '0' + new Date().toString() }]
      };
    case SWITCH_FORM:
      return {
        ...state,
        showForm: action.showForm
      };
    case SET_SEPA:
      return {
        ...state,
        error: null,
        success: null,
        isFetching: false,
        showForm: true,
        values: {
          method: action.sepa.basicInfo?.method,
          frequency: action.sepa.basicInfo?.frequency,
          collectionDate: moment(action.sepa.basicInfo?.collectionDate).format(
            'YYYY-MM-DD'
          ),
          company: action.sepa.receiver?.name,
          iban: action.sepa.receiver?.iban,
          bic: action.sepa.receiver?.bic,
          creditorId: action.sepa.basicInfo?.creditorId,
          batch: action.sepa.basicInfo?.batchBooking ? 'as_batch' : 'non_batch'
        },
        transactions: action.sepa.transactions?.map(
          (transaction: any, index: any) => {
            const mandateReferenceDate = moment(
              transaction.mandateReferenceDate
            ).format('YYYY-MM-DD');
            transaction.mandateReferenceDate = mandateReferenceDate;
            transaction.key = index + new Date().toString();
            transaction.name = transaction.bankAccount.name;
            transaction.iban = transaction.bankAccount.iban;
            transaction.bic = transaction.bankAccount.bic;
            return transaction;
          }
        )
      };
    case RECEIVE_USER: {
      let values = {
        ...state.values,
        iban: action.user.iban,
        bic: action.user.bic,
        creditorId: action.user.creditorId,
        company: action.user.company
      };
      return {
        ...state,
        values
      };
    }
    case SET_TRANSACTIONS:
      return {
        ...state,
        transactions: action.sepa.transactions?.map(
          (transaction: any, index: any) => {
            const mandateReferenceDate = moment(
              transaction.mandateReferenceDate
            ).format('YYYY-MM-DD');
            transaction.mandateReferenceDate = mandateReferenceDate;
            transaction.key = index + new Date().toString();
            transaction.name = transaction.bankAccount.name;
            transaction.iban = transaction.bankAccount.iban;
            transaction.bic = transaction.bankAccount.bic;
            return transaction;
          }
        )
      };
    case RECEIVE_UPLOAD_EXCEL:
      return {
        ...state,
        success: true
      };
    default:
      return state;
  }
}

export default sepa;
