import React, { Fragment } from 'react';
import { PageTitle } from '../template/layout-components';
import { Grid } from '@material-ui/core';
import Subscription from '../components/settings/Subscriptions/Subscription';
import SepaData from '../components/settings/SepaData/SepaData';
import Breadcrumbs from '../utils/Breadcrumbs';

function SettingsPage() {
  return (
    <Fragment>
      <PageTitle titleHeading={<Breadcrumbs />} />
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <SepaData />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Subscription />
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default SettingsPage;
