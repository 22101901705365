import { Box } from '@material-ui/core';
import React from 'react';
import styles from './confirmButton.module.css';
import BasicButton from './BasicButton';

interface IProps {
  id?: any;
  onClick?: any;
  text: string;
  buttonStyle?: string;
  fullWidth?: boolean;
  type?: string;
}

function ConfirmButton(props: IProps) {
  const { onClick, text } = props;

  if (!props.buttonStyle) {
    return (
      <BasicButton
        id={props.id}
        onClick={onClick}
        text={text}
        fullWidth={props.fullWidth}
        type={props.type}
      />
    );
  }

  return (
    <Box id={props.id} className={styles[props.buttonStyle]} onClick={onClick}>
      {text}
    </Box>
  );
}

export default ConfirmButton;
