import React from 'react';
import text from '../../language/text';
import DynamicForm from '../utils/DynamicForm';
import { reduxForm } from 'redux-form';
import FormLayout from '../utils/FormLayout';

interface IProps {
  errorMessage: string;
  successMessage: string;
}

const ChangeAutomatedLogoutForm = (props: any) => {
  const { errorMessage, successMessage, handleSubmit } = props;

  const logoutChoices = [
    {
      label: ' 5 min',
      value: 5 * 60
    },
    {
      label: '10 min',
      value: 10 * 60
    },
    {
      label: '20 min',
      value: 20 * 60
    },
    {
      label: '30 min',
      value: 30 * 60
    }
  ];

  return (
    <FormLayout
      handleSubmit={handleSubmit}
      title={text.forms.changePasswordForm.automaticLogout}
      subTitle={text.forms.changePasswordForm.automaticLogoutSelectText}
      errorMessage={errorMessage}
      successMessage={successMessage}
      dynamicForm={
        <DynamicForm
          handleSubmit={props.handleSubmit}
          fields={[
            {
              name: 'timeout',
              label: text.forms.changePasswordForm.SelectAutomaticLogoutOption,
              defaultValue:
                text.forms.changePasswordForm.SelectAutomaticLogoutOption,
              type: 'select',
              choices: logoutChoices
            }
          ]}
          outlined
        />
      }
      submitText={text.forms.changeAccount.save}
    />
  );
};

export default reduxForm<{}, IProps>({
  form: 'changeAutomatedLogoutForm'
})(ChangeAutomatedLogoutForm);
