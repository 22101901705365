import {
  CANCEL_SUBSCRIPTION_ERROR,
  CLOSE_MODAL,
  OPEN_MODAL,
  RECEIVE_CANCEL_SUBSCRIPTION,
  REQUEST_CANCEL_SUBSCRIPTION
} from './types';

const openModal = () => ({
  type: OPEN_MODAL
});

const closeModal = () => ({
  type: CLOSE_MODAL
});

const requestCancelSubscription = () => ({
  type: REQUEST_CANCEL_SUBSCRIPTION
});

const receiveCancelSubscription = () => ({
  type: RECEIVE_CANCEL_SUBSCRIPTION
});

const cancelSubscriptionError = (error: string) => ({
  type: CANCEL_SUBSCRIPTION_ERROR,
  error
});

export {
  openModal,
  closeModal,
  requestCancelSubscription,
  receiveCancelSubscription,
  cancelSubscriptionError
};
