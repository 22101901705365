const OPEN_MODAL = 'addCreditModal/ducks/OPEN_MODAL';
const CLOSE_MODAL = 'addCreditModal/ducks/CLOSE_MODAL';
const REQUEST_ADD_CREDIT = 'addCreditModal/ducks/REQUEST_ADD_CREDIT';
const RECEIVE_ADD_CREDIT = 'addCreditModal/ducks/RECEIVE_ADD_CREDIT';
const ADD_CREDIT_ERROR = 'addCreditModal/ducks/ADD_CREDIT_ERROR';
const RECEIVE_PAID = 'addCreditModal/ducks/RECEIVE_PAID';
const RECEIVE_CANCELED = 'addCreditModal/ducks/RECEIVE_CANCELED';

export {
  OPEN_MODAL,
  CLOSE_MODAL,
  REQUEST_ADD_CREDIT,
  RECEIVE_ADD_CREDIT,
  ADD_CREDIT_ERROR,
  RECEIVE_PAID,
  RECEIVE_CANCELED
};
