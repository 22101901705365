import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Divider, Grid, Typography } from '@material-ui/core';
import text from '../../../language/text';
import { formatMoney } from '../../../utils/formatters';
import StartSubscriptionModal from '../../modals/StartSubscriptionModal/StartSubscriptionModal';

function StartSubscription({
  subscriptionPrice,
  subscriptionStart,
  role
}: any) {
  const strings = text.pages.settings.components.startSubscription;
  return (
    <Fragment>
      <div className="font-size-lg font-weight-bold">{strings.title}</div>
      <Divider className="my-4" />
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Typography>{strings.upgradeText}</Typography>
        </Grid>
        <Grid item xs={6} className="font-weight-bold">
          <div
            className="font-size-xxl m-auto"
            style={{ color: '#0079f4', textAlign: 'center' }}>
            {formatMoney(subscriptionPrice)}
          </div>
          <div
            className="font-size-l m-auto"
            style={{ color: '#0079f4', textAlign: 'center' }}>
            {strings.amountSubText}
          </div>
        </Grid>
      </Grid>
      <StartSubscriptionModal />
    </Fragment>
  );
}

function mapStateToProps(state: any) {
  const { auth } = state;
  const { user } = auth || {
    user: {
      username: '',
      subscriptionPrice: 0,
      firstName: ''
    }
  };
  const {
    username,
    firstName,
    credit,
    role,
    subscriptionStart,
    subscriptionPrice
  } = user || {
    username: '',
    firstName: '',
    credit: '-',
    role: '',
    subscriptionPrice: 0,
    subscriptionStart: null
  };

  return {
    isFetching: false,
    errorMessage: null,
    success: false,
    subscriptionPrice,
    subscriptionStart,
    role
  };
}

export default connect(mapStateToProps)(StartSubscription);
