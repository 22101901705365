const en = {
  loading: 'Loading...',
  basic: {
    title: 'YourSepa',
    settings: 'Settings',
    dateTimeFormat: 'HH:mm DD/MM/YYYY',
    dateFormat: 'DD/MM/YYYY',
    error: 'Something went wrong...',
    back: 'Back',
    subscription: 'Subscription',
    logout: 'Log out',
    backToDashboard: 'Go back to dashboard',
    delete: 'Delete'
  },
  pages: {
    dashboard: {
      title: 'Dashboard',
      components: {
        recentDownloads: 'Recent Downloads',
        recentDownloadsNotFound: 'No downloads found',
        recentDownloadsButtons: {
          downloadXml: 'Download XML',
          editInForm: 'Edit in form',
          delete: 'Delete'
        },
        makeSepa: 'Create new SEPA',
        makeSepaButton: 'Create SEPA',
        downloadExcel: 'Download Excel',
        clearForm: 'Clear all',
        templates: 'Create SEPA',
        templatesSubtext: 'Upload file or start new document',
        or: 'or',
        templateButtons: {
          existingXml: 'Import SEPA XML',
          importCsv: 'Import Excel',
          downloadTemplate: 'Download excel template',
          startNewDocument: 'Start new document',
          credit: 'Credit (Making payments)',
          debit: 'Debit (Receiving payments)'
        }
      }
    },
    settings: {
      title: 'Settings',
      components: {
        startSubscription: {
          title: 'Upgrade Account',
          upgradeText:
            'Upgrade your account to a yearly subsciption to create an unlimited amount of SEPA documents!',
          button: 'Upgrade',
          amountSubText: 'A year (excl. btw)',
          cancelSubscription: 'Cancel subscription',
          subscription: 'Subscription',
          subscriptionStart: 'Subscription start date',
          nextPayment: 'Next automatic payment'
        },
        changePasswordForm: {
          title: 'Change password'
        },
        accountData: {
          title: 'Change account'
        },
        sepaData: {
          title: 'Sepa defaults'
        }
      }
    },
    admin: {
      title: 'Admin features',
      components: {
        userDetails: {
          title: 'User Details',
          error: 'User not found',
          userPayments: {
            error: 'No payments found'
          }
        }
      }
    }
  },
  modals: {
    importExcel: {
      mainText:
        'Upload the filled excel template. If the excel template is valid the transactions are loaded into the form.'
    },
    importXml: {
      mainText: 'Upload an existing XML SEPA file to edit it with YourSepa!',
      fileError: 'Geen geldig bestand geselecteerd'
    },
    addCredit: {
      openButton: 'Add Credit',
      title: 'Add credits',
      addCredit: 'Checkout',
      cancel: 'Cancel',
      modalText:
        'Add credits to create SEPA documents. You can add as much as you want!',
      value: 'Amount',
      vatText: '21% BTW will be applied at checkout',
      checkStatus: 'Checking payment status',
      success: 'Paid! The credits are added to your account',
      successClose: 'Close',
      errors: {
        valueFormat: 'Enter a valid number',
        valueToLow: 'The minimum amount is %s',
        unknown: 'Something went wrong!',
        paymentFailed: 'The payment failed, please try again later'
      }
    },
    startSubscription: {
      openButton: 'Upgrade',
      title: 'Upgrade Account',
      success:
        'Subscribed! You can now download and create unlimited SEPA documents!',
      successClose: 'Close',
      cancel: 'Cancel',
      modalText:
        'For only %s (excl. btw) a year you can create unlimited SEPA documents',
      buySubscription: 'Buy Subscription'
    },
    cancelSubscriptionModal: {
      openButton: 'Cancel subscription',
      title: 'Cancel subscription',
      success: 'Subscription is canceled.',
      successClose: 'Close',
      modalText:
        'Are you sure? When you cancel the subscription you can no longer download unlimited SEPA documents.',
      cancel: 'Close',
      cancelSubscription: 'Yes, cancel my subscription'
    },
    creditAction: {
      title: 'Warning: This action costs credit',
      subTextPart1: 'This action will deduct €',
      subTextPart2:
        ' from your account. If you want unlimited downloads you can start a subscription!',
      agree: 'Yes, continue',
      disagree: 'No, cancel'
    }
  },
  forms: {
    loginForm: {
      title: 'Login',
      login: 'Sign in',
      remeberMe: 'Remember me',
      error: 'Invalid username and password combination.',
      goRegister: 'Not a member? Register now',
      goForgotPassword: 'Forgot password',
      emailConfirmed: 'E-mail confirmed!',
      confirmEmailError: 'Something went wrong!'
    },
    changeSubscriptionForm: {
      changeSubscriptionText: 'Change your account subscription',
      startDate: 'Subscription start date:',
      nextPaymentDate: 'Next automatic payment:',
      cancelSubscription: 'Cancel subscription'
    },
    twoFactorAuthForm: {
      title: 'Two-factor authentication',
      sentCode: 'We sent a code to your e-mail',
      didntReceiveCode: "Didn't receive the code?",
      sendAgain: 'Resend here',
      verifyBtn: 'Verify',
      code: 'code',
      error: 'Wrong code'
    },
    inviteFriendForm: {
      title: 'Invite a friend',
      inviteText: 'Invite friends to YourSepa and gain',
      sendCode: 'Send code',
      sendMail: 'Send email',
      opened: 'Opened',
      createdAccount: 'Created Account',
      sent: 'Sent',
      copyCode: 'Copy'
    },
    changePasswordForm: {
      savePassword: 'Save password',
      passwordError: 'Incorrect password',
      passwordsNotEqual: 'New passwords are not the same',
      success: 'New password saved!',
      title: 'Change Password',
      error: 'This link is not valid anymore',
      defineNewPwdText: 'Define new password for your account',
      automaticLogout: 'Automated logout',
      automaticLogoutSelectText:
        'Select when you want to log out of the account',
      SelectAutomaticLogoutOption: 'After 5 minutes'
    },
    sepaForm: {
      error: 'Sepa could not be created',
      notComplete: 'Not all required fields are complete',
      addTransaction: 'Add transaction',
      total: 'Total',
      totalTransactions: 'Transactions',
      insufficientFunds: 'Not enough credits!'
    },
    validation: {
      required: 'Required',
      ibanFormat: 'Iban not valid'
    },
    registerForm: {
      title: 'Register',
      goLogin: 'Already have an account? Login!',
      success: 'Registerd! Check your e-mail to confirm your e-mail address',
      error: 'This e-mail address is already used',
      passwordFormatError:
        'Password should contain uppercase, lowercase, alphanumeric and special characters',
      passwordShortError: 'Password is to short (7 character minimum)',
      passwordsDontMatchError: 'Passwords do not match'
    },
    forgotPasswordForm: {
      title: 'Forgot password',
      error: 'Something went wrong',
      sendMail: 'Send e-mail',
      success: 'Reset e-mail sent!'
    },
    changeAccount: {
      error: 'Something went wrong',
      success: 'Account saved!',
      save: 'Save',
      title: 'Change account',
      subtitle: 'Describe columns for SEPA file',
      phoneNumber: 'Phone number',
      city: 'City',
      houseNumber: 'House number',
      deleteAccount: 'Delete account'
    },
    changeAutomatedLogout: {
      saved: 'Settings saved',
      error: 'Something went wrong'
    }
  },
  fields: {
    email: 'Email',
    firstName: 'First name',
    lastName: 'Surname',
    lastNamePrefix: 'Prefix',
    company: 'Company name',
    password: 'Password',
    oldPassword: 'Old password',
    newPassword: 'New password',
    repeatPassword: 'Repeat new password',
    date: 'Date',
    actions: 'Actions',
    selectEmpty: 'Choose one',
    address: 'Address',
    postalCode: 'Postalcode',
    country: 'Country',
    sepa: {
      credit: 'Credit',
      debit: 'Debit',
      method: 'Method',
      methodChoice: {
        normal: 'Normal collection',
        business: 'Business collection'
      },
      frequency: 'Frequency',
      frequencyChoice: {
        once: 'One time',
        continuous: 'Continuous'
      },
      paymentType: 'Purpose',
      paymentTypeChoice: {
        salary: 'Salary payments',
        nonSalary: 'Non salary payments'
      },
      collectionDate: 'Execute on',
      companyName: 'Company',
      iban: 'IBAN',
      bic: 'BIC',
      creditorId: 'Creditor ID',
      batch: 'Process as batch',
      batchChoice: {
        asBatch: 'Process as one batch',
        nonBatch: 'Process individually'
      },
      payments: 'Transactions',
      paymentInfo: {
        mandateId: 'Mandate ID',
        mandateDate: 'Mandate Date',
        amount: 'Amount',
        name: 'Name',
        description: 'Description',
        remove: 'Remove'
      },
      endToEndId: 'End-to-end-ID'
    }
  },
  errors: {
    importFailed: 'The import failed on line #MESSAGE#'
  }
};

export default en;
