import {
  receiveUserPayments,
  requestUserPayments,
  userPaymentsError
} from './actions';
import { apiGet } from '../../../../../utils/api';
import text from '../../../../../language/text';

const fetchPayments = (userId: number) => {
  return (dispatch: any, getState: any) => {
    dispatch(requestUserPayments());

    apiGet(`/payments/byUserId/${userId}`, getState().auth.token)
      .then((r: any) => r.json())
      .then((response: any) => {
        dispatch(receiveUserPayments(response));
      })
      .catch(() => {
        dispatch(
          userPaymentsError(
            text.pages.admin.components.userDetails.userPayments.error
          )
        );
      });
  };
};

export { fetchPayments };
