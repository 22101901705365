import React, { Fragment, useState } from 'react';

import { connect } from 'react-redux';
import { changePassword } from '../reducers/settingsActions';
import { Box, Grid } from '@material-ui/core';
import styles from '../assets/cssModules/changePages.module.scss';
import ChangePasswordForm from '../components/forms/ChangePasswordForm';
import { Link, useParams } from 'react-router-dom';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import text from '../language/text';
import { apiPost } from '../utils/api';
import ChangeAutomatedLogoutForm from '../components/forms/ChangeAutomatedLogoutForm';

interface IProps {
  dispatch: any;
  token: string;
}

function ChangePasswordPage(props: IProps) {
  const { token } = props;
  const { locale } = useParams<any>();

  const [autoLogoutMessage, setAutoLogoutMessage] = useState('');
  const [autoLogoutError, setAutoLogoutError] = useState('');

  const handleChangePassword = (values: any) => {
    console.log('values', values);
    props.dispatch(
      changePassword(
        values.oldPassword,
        values.newPassword1,
        values.newPassword2
      )
    );
  };

  const handleAutomatedLogout = (values: any) => {
    setAutoLogoutError('');
    setAutoLogoutMessage('');
    apiPost(
      `/users/logoutTimeout`,
      {
        timeout: Number.parseInt(values.timeout)
      },
      token
    )
      .then(() => {
        setAutoLogoutMessage(text.forms.changeAutomatedLogout.saved);
      })
      .catch(() => {
        setAutoLogoutError(text.forms.changeAutomatedLogout.error);
      });
  };

  return (
    <Fragment>
      <Grid
        container
        spacing={2}
        justify={'center'}
        className={styles.WhiteBoxContainer}>
        <Grid item xs={11} className={styles.WhiteBox}>
          <Box display="flex" flexDirection={'column'}>
            <Box>
              <div>
                <Link
                  to={`/${locale}/Dashboard`}
                  className={styles.ToDashboard}>
                  <ChevronLeftIcon
                    style={{ color: '#0055ff' }}
                    fontSize="large"
                  />
                  {text.basic.backToDashboard}
                </Link>
              </div>
            </Box>
            <Box display="flex" justifyContent="center">
              <ChangePasswordForm onSubmit={handleChangePassword} />
            </Box>
            <Box display="flex" justifyContent="center">
              <ChangeAutomatedLogoutForm
                onSubmit={handleAutomatedLogout}
                successMessage={autoLogoutMessage}
                errorMessage={autoLogoutError}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
}

function mapStateToProps(state: any) {
  return {
    token: state.auth.token
  };
}

export default connect(mapStateToProps)(ChangePasswordPage);
