import React, { Suspense } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import { ThemeProvider } from '@material-ui/styles';

import MuiTheme from './template/theme';

// Layout Blueprints

import { LeftSidebar, PresentationLayout } from './template/layout-blueprints';

import LoginPage from './pages/LoginPage';
import text from './language/text';
import DashboardPage from './pages/DashboardPage';
import SettingsPage from './pages/SettingsPage';
import LocaleSet from './utils/LocaleSet';
import RegisterPage from './pages/RegisterPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ConfirmEmailPage from './pages/ConfirmEmailPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import AdminPage from './pages/AdminPage';
import UserDetailPage from './pages/UserDetailPage';
import PaymentSuccessPage from './pages/PaymentSuccessPage';
import ChangePasswordPage from './pages/ChangePasswordPage';
import ChangeSubscriptionPage from './pages/ChangeSubscriptionPage';
import ChangeAccountPage from './pages/ChangeAccountPage';
import InviteFriendPage from './pages/InviteFriendPage';

const Routes = () => {
  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
  };

  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense
          fallback={
            <div className="d-flex align-items-center vh-100 justify-content-center text-center font-weight-bold font-size-lg py-3">
              <div className="w-50 mx-auto">{text.loading}</div>
            </div>
          }
        />
        <Switch key="mainSwitch">
          <Redirect exact from="/" to={'/en/Dashboard'} />
          <Route exact path="/paymentSuccess" component={PaymentSuccessPage} />
          <Route path={['/:locale']}>
            <LocaleSet />
            <Switch>
              <Redirect exact from="/" to={'/:locale/login'} />
              <Route path="/:locale/login">
                <PresentationLayout>
                  <Switch location={location} key={location.pathname}>
                    <Route path="/:locale/login" component={LoginPage} />
                  </Switch>
                </PresentationLayout>
              </Route>
              <Route path="/:locale/register">
                <PresentationLayout>
                  <Switch location={location} key={location.pathname}>
                    <Route path="/:locale/register" component={RegisterPage} />
                  </Switch>
                </PresentationLayout>
              </Route>
              <Route path="/:locale/forgotpassword">
                <PresentationLayout>
                  <Switch location={location} key={location.pathname}>
                    <Route
                      path="/:locale/forgotpassword"
                      component={ForgotPasswordPage}
                    />
                  </Switch>
                </PresentationLayout>
              </Route>
              <Route path="/:locale/confirmemail">
                <PresentationLayout>
                  <Switch location={location} key={location.pathname}>
                    <Route
                      path="/:locale/confirmemail/:email/:token"
                      component={ConfirmEmailPage}
                    />
                  </Switch>
                </PresentationLayout>
              </Route>
              <Route path="/:locale/resetpassword">
                <PresentationLayout>
                  <Switch location={location} key={location.pathname}>
                    <Route
                      path="/:locale/resetpassword/:email/:token"
                      component={ResetPasswordPage}
                    />
                  </Switch>
                </PresentationLayout>
              </Route>
              <Route path="/:locale/changepassword">
                <LeftSidebar headerOnly>
                  <PresentationLayout>
                    <Route
                      path="/:locale/changepassword"
                      component={ChangePasswordPage}
                    />
                  </PresentationLayout>
                </LeftSidebar>
              </Route>
              <Route path="/:locale/changesubscription">
                <LeftSidebar headerOnly>
                  <PresentationLayout>
                    <Route
                      path="/:locale/changesubscription"
                      component={ChangeSubscriptionPage}
                    />
                  </PresentationLayout>
                </LeftSidebar>
              </Route>
              <Route path="/:locale/accountchange">
                <LeftSidebar headerOnly>
                  <PresentationLayout>
                    <Route
                      path="/:locale/accountchange"
                      component={ChangeAccountPage}
                    />
                  </PresentationLayout>
                </LeftSidebar>
              </Route>
              <Route path="/:locale/invitefriend">
                <LeftSidebar headerOnly>
                  <PresentationLayout>
                    <Route
                      path="/:locale/invitefriend"
                      component={InviteFriendPage}
                    />
                  </PresentationLayout>
                </LeftSidebar>
              </Route>
              <Route
                path={[
                  '/:locale/Dashboard',
                  '/:locale/Settings',
                  '/:locale/Admin',
                  '/:locale/changepassword',
                  '/:locale/changesubscription',
                  '/:locale/accountchange',
                  '/:locale/invitefriend'
                ]}>
                <PresentationLayout>
                  <Route
                    path="/:locale/changepassword"
                    component={ChangePasswordPage}
                  />
                  <Route
                    path="/:locale/changesubscription"
                    component={ChangeSubscriptionPage}
                  />
                  <Route
                    path="/:locale/accountchange"
                    component={ChangeAccountPage}
                  />
                  <Route
                    path="/:locale/invitefriend"
                    component={InviteFriendPage}
                  />
                </PresentationLayout>
                <LeftSidebar>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <Route
                        path="/:locale/Dashboard"
                        component={DashboardPage}
                      />
                      <Route
                        path="/:locale/Settings"
                        component={SettingsPage}
                      />
                      <Route
                        path="/:locale/Admin/users/:userId"
                        component={UserDetailPage}
                      />
                      <Route
                        path="/:locale/Admin"
                        exact
                        component={AdminPage}
                      />
                    </motion.div>
                  </Switch>
                </LeftSidebar>
              </Route>
            </Switch>
          </Route>
        </Switch>
      </AnimatePresence>
    </ThemeProvider>
  );
};

export default Routes;
