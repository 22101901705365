import React from 'react';
import BasicButton from './BasicButton';

interface IProps {
  onClick?: any;
  text: string;
  fullWidth?: boolean;
}

function WarningButton(props: IProps) {
  const { onClick, text } = props;

  return (
    <BasicButton
      onClick={onClick}
      fullWidth={props.fullWidth}
      color={'warning'}
      text={text}
    />
  );
}

export default WarningButton;
