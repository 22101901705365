import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Grid, Container, Typography } from '@material-ui/core';
import text from '../language/text';
import Loader from '../components/utils/Loader';
import ResetPasswordForm from '../components/forms/resetPasswordForm/ResetPasswordForm';
import { resetPassword } from '../reducers/resetPassword/ResetPasswordActions';
import { Link, useParams } from 'react-router-dom';
import projectLogo from '../template/assets/images/YourSEPA_Logo_Wit_RGB_WT_150dpi_V2.png';

interface IProps {
  dispatch: any;
  isFetching: boolean;
  errorMessage: string;
  success: boolean;
  match?: any;
}

function ResetPasswordPage(props: IProps) {
  const { dispatch, isFetching, match } = props;

  const handleSubmit = (values: any) => {
    const token = match.params.token;
    const email = match.params.email;
    dispatch(resetPassword(email, values.password1, token));
  };

  const { locale } = useParams();

  return (
    <Fragment>
      <div
        className="flex-grow-1 w-100 h-100 d-flex align-items-center"
        style={{ backgroundColor: '#0079f4' }}>
        <div className="bg-composed-wrapper--content pt-5 pb-2 py-lg-5">
          <Container maxWidth="md" className="pd-5">
            <Grid container spacing={4} style={{ justifyContent: 'center' }}>
              <Grid
                item
                lg={6}
                className="px-0 mx-auto d-flex align-items-center"
                style={{ justifyContent: 'center' }}>
                <div className="text-center">
                  <div className="px-4 px-sm-0 text-white mt-4">
                    <h1 className="display-2 mb-5 font-weight-bold">
                      <img
                        className="app-logo-img"
                        alt="YourSepa"
                        src={projectLogo}
                      />
                    </h1>
                  </div>
                </div>
              </Grid>
              <Grid item lg={6}>
                <Loader open={isFetching} />
                <div className="bg-secondary border-0">
                  <div className="card-body px-lg-5 py-lg-5 center">
                    <Typography variant="h4" className="mt-2 mb-4">
                      {text.forms.changePasswordForm.title}
                    </Typography>
                    <ResetPasswordForm onSubmit={handleSubmit} />

                    <Link
                      to={`/${locale}/Dashboard`}
                      style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                      {text.forms.registerForm.goLogin}
                    </Link>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </Fragment>
  );
}

function mapStateToProps(state: any) {
  const { CHANGE_TO_REDUCER_NAME } = state;
  const { isFetching, error, success } = CHANGE_TO_REDUCER_NAME || {
    isFetching: false,
    error: null,
    success: false
  };
  return {
    isFetching,
    errorMessage: error,
    success
  };
}

export default connect(mapStateToProps)(ResetPasswordPage);
