import { REQUEST_DOWNLOADS, RECEIVE_DOWNLOADS } from './dashboardActions';

function dashboard(
  state = {
    recentDownloads: {
      isFetching: false,
      error: '',
      success: '',
      downloads: []
    }
  },
  action: any
) {
  switch (action.type) {
    case REQUEST_DOWNLOADS:
      return Object.assign({}, state, {
        recentDownloads: {
          isFetching: true,
          error: null
        }
      });
    case RECEIVE_DOWNLOADS:
      return Object.assign({}, state, {
        recentDownloads: {
          isFetching: false,
          downloads: action.downloads
        }
      });
    default:
      return state;
  }
}

export default dashboard;
